import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Links } from 'src/class/links';
import { objUsuario } from 'src/class/usuario';
import { StatusAgendamento } from 'src/enum/statusAgendamento.enum';
import { AuthService } from 'src/services/auth.service';
import { ContaService } from 'src/services/component/conta.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';
import { DiasNaoAgendamentoService } from 'src/services/component/dias-nao-agendamento.service';
import { DataService } from 'src/services/data.service';
import { LinksService } from 'src/services/links.service';
import { LocalhostService } from 'src/services/localhost.service';
import { ModalConfirmacaoService } from 'src/services/modal-confirmacao.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ThemeService } from 'src/services/theme.service';
import { ToastService } from 'src/services/toast.service';
import { DiasNaoAgendamento } from 'src/class/dias-nao-agendamento';
import { SearchDiasNaoAgendamentoPipe } from 'src/pipes/search-dias-nao-agendamento.pipe';

@Component({
  selector: 'app-dias-nao-agendamento',
  templateUrl: './dias-nao-agendamento.component.html',
  styleUrls: ['./dias-nao-agendamento.component.css']
})
export class DiasNaoAgendamentoComponent {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj = new objUsuario;

  agenda_arr: any [] = [];

  pesquisa="";
  pag = 1;
  contador = 5;
  pageSize = 5;
  pageIndex = 0;
  totalizador = 0;
  maxSize = 5;
  visualizando = this.contador;
  lightMode: any;
  modoView = false;
  modoViewNum = 12;

  private subscription: Subscription;

  linksAgenda: Links[] = [];

  statusAgendamento = StatusAgendamento;

  dias: DiasNaoAgendamento[] = [];
  displayedDias: DiasNaoAgendamento[] = [];
  loading: boolean = true;
  sortedData: DiasNaoAgendamento[] = [];

  loader = this.loadingBar.useRef('http');
  empresaSigla: any | undefined;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private themeService: ThemeService,
    private linksService: LinksService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private paginatorIntl: MatPaginatorIntl,
    private searchDiasNaoAgendamentoPipe: SearchDiasNaoAgendamentoPipe,
    private loadingBar: LoadingBarService,
    private modalConfirmacaoService: ModalConfirmacaoService,
    private diaService: DiasNaoAgendamentoService,
    private localhostService: LocalhostService,
    private siglaService: SiglaService,
    private conta: ContaService,
    private userService: AuthService,
    private notificacaoService: NotificacaoSistemaService
  ){
    this.subscription = this.themeService.lightMode$.subscribe(lightMode => {
      this.lightMode = lightMode;
    });

    this.paginatorIntl.itemsPerPageLabel = 'Itens por página:';
    this.paginatorIntl.nextPageLabel = 'Próxima página';
    this.paginatorIntl.previousPageLabel = 'Página anterior';
    this.paginatorIntl.firstPageLabel = 'Primeira página';
    this.paginatorIntl.lastPageLabel = 'Última página';

    // Personalize a indexação para começar em 1
    this.paginatorIntl.getRangeLabel = this.getPortugueseRangeLabel;

    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.diaService.getDia(this.empresaSigla);
        });
      // }
    // });
  }


  ngOnInit() {

    this.getCache();

    // console.log(this.statusAgendamento)

    this.linksAgenda = this.linksService.getLinksAgenda();

    this.changeDetectorRef.detectChanges();

  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getCache(){

    let modoView = localStorage.getItem("modoView");

    let modoViewNum = localStorage.getItem("modoViewNum");

    if(modoView){
      this.modoView = modoView == 'true' ? true : false
    }

    if(modoViewNum){
      this.modoViewNum = parseInt(modoViewNum);
    }

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }

    this.changeDetectorRef.detectChanges();

    this.diaService.getAllDia()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (dias: DiasNaoAgendamento[]) => {
        // console.log(dias)
        // Faça algo com as notificações atualizadas
        this.dias = dias;

        this.loading = false;

        this.displayedDias = this.dias.slice(
          this.pageIndex * this.pageSize,
          (this.pageIndex + 1) * this.pageSize
        );

        this.route.queryParams.subscribe((params: any) => {
          // O parâmetro de consulta 'search' estará em params.search
          this.pesquisa = params.search ? params.search : '';
          // console.log(params)
          // Agora você pode usar o valor da pesquisa em sua função searchAgenda
          if(this.pesquisa != ''){
            this.searchDia();
          }
        });

      },
      error => {
        // console.error('Erro ao listar os Dias:', error);
        this.router.navigate(['/home']);
      }
    );

  }

  searchDia(){
    const retorno = this.searchDiasNaoAgendamentoPipe.transform(this.dias, this.pesquisa);

    if (this.paginator) {
      this.paginator.firstPage();
    }

    this.displayedDias = retorno;

    if(this.pesquisa == ""){
      this.displayedDias = this.dias.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      this.paginator.length = this.dias.length;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { search: null },
        queryParamsHandling: 'merge',
      });

    }else{

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { ['search']: this.pesquisa },
        queryParamsHandling: 'merge',
      });

      this.displayedDias = retorno.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      if (this.paginator) {
        this.paginator.length = retorno.length;
      }
    }
  }

  handlePage(event: any) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    // Calcule o índice de início e fim dos itens a serem exibidos na página atual
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;

    // Atualize os itens a serem exibidos na tabela
    this.displayedDias = this.dias.slice(startIndex, endIndex);

    // console.log(this.displayedDias)
  }

  sortData(sort: Sort) {
    const data = this.dias.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      this.dias = this.sortedData
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';

      switch (sort.active) {
        case 'id':
          return this.compare(a.id!, b.id!, isAsc);
        case 'status':
          return this.compare(a.status.valor, b.status.valor, isAsc);
        case 'nome':
          return this.compare(a.nome.valor, b.nome.valor, isAsc);
        case 'descricao':
          return this.compare(a.descricao.valor, b.descricao.valor, isAsc);
        case 'criado':
          return this.compare(a.criado.toString(), b.criado.toString(), isAsc);
        case 'atualizado':
          return this.compare(a.atualizado.toString(), b.atualizado.toString(), isAsc);
        default:
          return 0;
      }
    });

    // console.log(this.sortedData)
    this.dias = this.sortedData

    this.displayedDias = this.dias.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // Personalize a função getRangeLabel para começar em 1
  getPortugueseRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }

  reload(){
    try {
      this.dias = [];
      this.loading = true;
      // this.getTotalDiasUsuarios();
      // this.getUsuario();


    this.loader.start()

      setTimeout(async () => {
        let dia = await this.diaService.getDia(this.empresaSigla);
        // console.log(dia)

        if(dia != undefined){
          this.dias = dia;
        }else{
          setTimeout(() => {
            this.loading = false;
            this.loader.complete()

          }, 500);
        }

        this.showInfo("Dias atualizados com sucesso!!")
        this.loading = false;
        this.loader.complete()


      }, 1000);


    } catch (error) {
      this.showError("Ocorreu um erro na atualização da agenda")
      this.loader.complete()
    }

  }

  resetSearch(){
    this.pesquisa = "";
    this.displayedDias = this.dias.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
    this.paginator.length = this.dias.length;

    // Use o serviço Router para navegar para a mesma rota sem o parâmetro de consulta
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { search: null },
      queryParamsHandling: 'merge',
    });
  }

  pags(pags: any) {
    const target = pags as HTMLTextAreaElement;
    this.contador = parseInt(target.value)
    const total = this.contador * this.pag
    this.visualizando = total;
    if(this.visualizando > this.totalizador && (this.visualizando > 0 && this.totalizador > 0)){
      this.visualizando = this.totalizador;
    }
  }

  modoTabela(){
    this.modoView = true;
    localStorage.setItem("modoView", "true");
  }


  modoCard(){
    this.modoView = false;
    this.modoViewNum = 12;
    localStorage.setItem("modoViewNum", "12");
    localStorage.setItem("modoView", "false");
  }

  deletarDia(id: any){
    const index = this.dias.findIndex(agenda => agenda.id === id);

    if (index !== -1) {

      let titulo = 'Tem certeza que deseja excluir o dia?';
      let mensagem = ``;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Encontrou um item com o ID correspondente, exclua-o

          const docId = this.dias[index].id != null ? this.dias[index].id?.toString() : "";

          const dia = this.dias[index]

          await this.conta.p_getSiglaConta('contas', this.empresaSigla)
          .then((conta) => {
            // Faça algo com os dados obtidos
            // console.log('Dados da conta: deletarDia', conta);
            this.localhostService.deleteDados(`${conta.id}_dias_nao_agendamento`, { id: docId })
            .then(async (response: any) => {
              // console.log('Atualização bem-sucedida:', response);
              this.showSuccess(`Dia deletado com sucesso`);

              const usr = await this.userService.carregarUsuario(this.empresaSigla)

              this.notificacaoService.criarNotificacao(
                this.empresaSigla,            // 1 - empresaSigla: any,
                `${dia.nome.valor}`,          // 2 - titulo: string,
                `O dia foi deletado.`,        // 3 - texto: string,
                'Listar Dias',                // 4 - rotaSemParamNome: string,
                'dias-nao-agendamento',       // 5 - rotaSemParamListar: string,
                'Editar Dia',                 // 6 - rotaComParamNome: string = "",
                `dias-nao-agendamento/grupo`, // 7 - rotaComParamCaminho: string = "",
                dia.id?.toString(),           // 8 - rotaComParamParam: string = "",
                '',                           // 9 - linkExternoNome: string = "",
                '',                           // 10 - linkExternoURL: string = "",
                dia.id!.toString(),           // 11 - idReferencia: string,
                `Dias não agendamento`,       // 12 - tipo: string,
                `${usr.id}`,                  // 13 - idResponsavel: string,
                `${usr.displayName.valor}`    // 14 - nomeResponsavel: string
              )

              this.displayedDias = this.dias.slice(
                this.pageIndex * this.pageSize,
                (this.pageIndex + 1) * this.pageSize
              );
            })
            .catch((error: any) => {
              // console.error('Erro ao deletar dados:', error);
              this.showError("Dia não encontrada para exclusão \n" + error);
            });
          },
          (error: any) => {
            // console.error('Erro ao deletar dados:', error);
            // Trate o erro conforme necessário
          }).catch((error) => {
            // Trate o erro
            // console.log('Erro ao obter dados da conta:', error);
          });

        } else {
          // console.log('NÃO');
        }
      });

    } else {
      // Não encontrou um item com o ID correspondente
      this.showError("Dia não encontrado para exclusão");
    }
  }

  atualizarStatusDia(id: any){

    const index = this.dias.findIndex((item: DiasNaoAgendamento) => item.id === id);

    if (index !== -1) {

      let titulo = 'Tem certeza que deseja alterar o status do dia?';
      let mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
                        <p class="ag-mb-5">
                          <span>${this.dias[index].nome.valor}</span>
                        </p>
                        <p class="ag-mb-5">
                          <span class="text-cor-danger">Status atual: </span>
                          <span>${this.dias[index].status.valor ? "Ativo" : "Desativado"}</span>
                        </p>
                        <p class="ag-mb-5">
                          <span class="text-cor-sucesso">Novo status: </span>
                          <span>${!this.dias[index].status.valor ? "Ativo" : "Desativado"}</span>
                        </p>
                      </div>`;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Atualize o item existente
          this.dias[index].status.valor = !this.dias[index].status.valor;

          const docId = this.dias[index].id != null ? this.dias[index].id?.toString() : "";
          const plainObject = JSON.stringify(this.dias[index])
          const plainString = JSON.parse(plainObject)

          plainString.descricao.status = null;
          plainString.nome.status = null;

          await this.conta.p_getSiglaConta('contas', this.empresaSigla)
          .then((conta) => {
            // Faça algo com os dados obtidos
            // console.log('Dados da conta: atualizarStatusServico', conta);
            this.localhostService.putDados(`${conta.id}_dias_nao_agendamento`, docId!, plainString)
            .then(async (response: any) => {
              // console.log('Atualização bem-sucedida:', response);
              this.showSuccess(`Status do dia ${this.dias[index].nome.valor} alterado para ${this.dias[index].status.valor ? "ativado" : "desativado"}`);

              const usr = await this.userService.carregarUsuario(this.empresaSigla)

              this.notificacaoService.criarNotificacao(
                this.empresaSigla,            // 1 - empresaSigla: any,
                `${plainString.nome.valor}`,  // 2 - titulo: string,
                `O status do dia foi atualizado para ${plainString.status.valor ? "ativado" : "desativado"}.`,  // 3 - texto: string,
                'Listar Dias',                // 4 - rotaSemParamNome: string,
                'dias-nao-agendamento',       // 5 - rotaSemParamListar: string,
                'Editar Dia',                 // 6 - rotaComParamNome: string = "",
                `dias-nao-agendamento/grupo`, // 7 - rotaComParamCaminho: string = "",
                plainString.id?.toString(),   // 8 - rotaComParamParam: string = "",
                '',                           // 9 - linkExternoNome: string = "",
                '',                           // 10 - linkExternoURL: string = "",
                plainString.id!.toString(),   // 11 - idReferencia: string,
                `Dias não agendamento`,       // 12 - tipo: string,
                `${usr.id}`,                  // 13 - idResponsavel: string,
                `${usr.displayName.valor}`    // 14 - nomeResponsavel: string
              )
            })
            .catch((error: any) => {
              // console.error('Erro ao atualizar dados:', error);
              this.showError("Dia não encontrado para atualização \n" + error);
            });

          },
          (error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            // Trate o erro conforme necessário
          }).catch((error) => {
            // Trate o erro
            // console.log('Erro ao obter dados da conta:', error);
          });

          // DataService.setItem("Dia", this.dias);
          // localStorage.setItem("Dia", JSON.stringify(this.dias))
          // this.showSuccess(`Status do dia ${this.dias[index].status.valor ? "ativado" : "desativado"}`);
        } else {
          // console.log('NÃO');
        }
      });


    } else {
      // O item não foi encontrado no array, você pode tratar isso conforme necessário
      this.showError("Dia não encontrada para atualização");
    }
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}

