<div *ngIf="mostrarMenu">
  <div id="appSlidebar" class="sidebar" [ngClass]="{ 'close': isSidebarClosed, 'pinned': isSidebarPinned }" (mouseenter)="sidebarMouseEnter()" (mouseleave)="sidebarMouseLeave()" style=" background: var(--lw-cor-6); ">
    <app-menu></app-menu>
  </div>
  <div class="home-section ag-pt-0">

    <div class="min-width" style="width: 100%; height: 60px; border: 1px solid #00000026; margin-bottom: 20px; position: sticky; top: 0; background: var(--linear-gradient--60deg);z-index: 9;box-shadow: var(--box-shadow);">
      <div class="min-width" style="display: flex; align-items: center; justify-content: space-between; padding: 0px 10px; height: 60px;">

        <div style=" display: flex; align-items: center; justify-content: flex-start; min-width: 260px; ">
          <div style="display: flex; align-items: center;" (click)="toggleSidebar()" *ngIf="!isSidebarPinned">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"   style="cursor: pointer;">
              <path fill="none" d="M0 0h24v24H0V0z"></path>
              <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" fill="var(--lw-cor-4)"></path>
            </svg>
          </div>
          <!-- <p style="color: var(--text-cor-padrao);margin: 10px 0px 8px 8px;font-size: 14px;font-weight: 500;line-height: 43px;">{{ dataHora | date: 'dd/MM/yyyy HH:mm:ss' }}</p> -->
          <div [ngClass]="{ 'ag-ml-10': !isSidebarPinned, 'ag-ml-0': isSidebarPinned }" *ngIf="logoEmpresa != ''" style="background: #fff;padding: 10px 10px;border-radius: 5px;border: var(--border-padrao);display: flex;align-items: center;justify-content: center;">
            <img [src]="logoEmpresa" width="85px" [alt]="infoEmpresa"/>
          </div>
        </div>

        <div style=" display: flex; align-items: center; justify-content: space-between; min-width: 85px; ">
          <div>
            <app-notificacao-badge></app-notificacao-badge>
          </div>
          <div>
            <div style="z-index: 10;font-size: 20px;height: 32px;display: flex;justify-content: center;align-items: center;border-radius: 15px;color: #868789;cursor: pointer;">
              <!-- <span style="font-size: 14px;color: var(--text-cor-padrao);padding-top: 6px;padding-right: 6px;">Felipe</span> -->
              <div class="btn-group">
                <!-- <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Small button
                </button> -->

                <div data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" >

                  <div class="spinner-border" *ngIf="!usr_nome" role="status" style=" border-radius: 50%; height: 35px; min-height: 35px; min-width: 35px; position: relative; width: 35px; color: var(--text-cor-padrao) !important;background: var(--background);">
                    <span class="visually-hidden">Loading...</span>
                  </div>

                  <div *ngIf="usr_nome" style="min-width: 40px;min-height: 40px;width: 40px;height: 40px;border-radius: 50%;background: var(--background);background-image: url('{{usr_photo}}');background-size: cover;background-position: center;background-repeat: no-repeat;position: relative;">
                  </div>

                </div>

                <div class="dropdown-menu text-cor-padrao" style="min-width: 260px; background: var(--linear-gradient-0deg); border: var(--border-padrao); border-radius: 5px; box-shadow: var(--box-shadow_p); padding: 20px !important; position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate(-205px, 47px);">

                  <div *ngIf="!usr_nome" style=" display: flex; justify-content: center; align-items: center; padding: 5px; ">
                    <div class="spinner-border"  role="status" style="border-radius: 50%;height: 65px;min-height: 65px;min-width: 65px;position: relative;width: 65px;color: var(--text-cor-padrao) !important;">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>

                  <div style="display: flex;justify-content: space-between;width: 100%;flex-direction: column;align-items: center;flex-wrap: nowrap;" *ngIf="usr_nome">
                    <div style="min-width: 65px;min-height: 65px;width: 65px;height: 65px;border-radius: 50%;background: var(--background);background-image: url('{{usr_photo}}');background-size: cover;background-position: center;background-repeat: no-repeat;position: relative;">

                    </div>
                    <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;padding: 5px 0px;padding-bottom: 0;width: 100%;">
                      <!-- <span style=" font-size: 13px; background: var(--background); padding: 0px 10px; margin-bottom: 5px; border-radius: 15px; color: var(--text-cor-padrao); ">{{usr_tipo_string}}</span> -->
                      <span style="text-align: center;color: var(--text-cor-padrao);padding: 5px 0px;border-radius: 0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width: 300px;">{{usr_nome}}</span>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div>
                    <div >
                      <span style=" display: flex; justify-content: space-between; " (click)="routerLink('usuario', usr_id)">Editar usuário <i class='bi bi-gear' ></i></span>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div style="display: flex;justify-content: space-between;width: 100%;flex-direction: row;align-items: center;">
                    <!-- <span>Tema</span> -->
                    <div (click)="toggleLightMode()" style="margin-left: 0px;width: 100%;">
                      <span *ngIf="!lightMode" style=" display: flex; justify-content: space-between; ">Tema escuro <i class='bi bi-moon' ></i></span>
                      <span *ngIf="lightMode" style=" display: flex; justify-content: space-between; ">Tema claro <i class='bi bi-sun' ></i></span>
                    </div>
                  </div>
                  <div class="dropdown-divider"></div>
                  <div>
                    <div (click)="fazerLogout()">
                      <span style=" display: flex; justify-content: space-between; ">Sair <i class='bi bi-box-arrow-left menu-i' ></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-ngx-loading-bar">
        <ngx-loading-bar [fixed]="false" ref="router" color="var(--cor-primaria)" style="position: relative; top: -2px;"></ngx-loading-bar>
        <ngx-loading-bar [fixed]="false" ref="http" [color]="(loader.value$ | async) === 100 ? 'var(--cor-sucesso)' : 'var(--cor-primaria)'" class="bg-transition animate-bg" style="position: relative; top: -2px;"></ngx-loading-bar>
      </div>
    </div>

    <router-outlet></router-outlet>

  </div>

</div>

<div *ngIf="!mostrarMenu">
  <!-- <ngx-loading-bar [fixed]="false" ref="router" color="var(--cor-primaria)" style="position: relative; top: 0px;"></ngx-loading-bar>
  <ngx-loading-bar [fixed]="false" ref="http" color="var(--cor-primaria)" style="position: relative; top: 0px;"></ngx-loading-bar> -->
  <router-outlet></router-outlet>
</div>
