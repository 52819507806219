import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { forkJoin, Observable, Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { Agenda, Horario, HorariosDia } from 'src/class/agenda';
// import { Endereco } from 'src/class/endereco';
import { Links } from 'src/class/links';
import { Servico } from 'src/class/servico';
import { Tipo } from 'src/class/tipo';
import { newUsuario } from 'src/class/usuario';
import { CepService } from 'src/services/cep.service';
import { LinksService } from 'src/services/links.service';
import { ToastService } from 'src/services/toast.service';
import { TranslateWordService } from 'src/services/translate-word.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';
import { DataService } from 'src/services/data.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { LocalhostService } from 'src/services/localhost.service';
import { AgendaService } from 'src/services/component/agenda.service';
import { ServicoService } from 'src/services/component/servico.service';
import { SiglaService } from 'src/services/siglaService.service';
import { TipoService } from 'src/services/component/tipo.service';
import { Clipboard } from '@angular/cdk/clipboard';
// import { AngularFireStorage } from '@angular/fire/compat/storage';

declare var bootstrap: any;

@Component({
  selector: 'app-criar-agenda',
  templateUrl: './criar-agenda.component.html',
  styleUrls: ['./criar-agenda.component.css']
})
export class CriarAgendaComponent implements OnInit,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('alertModal', { static: false }) alertModal!: ElementRef;
  // @ViewChild('alertEnderecoModal', { static: false }) alertEnderecoModal!: ElementRef;
  @ViewChild('alertServicoModal', { static: false }) alertServicoModal!: ElementRef;
  @ViewChild('addHorarioModal', { static: false }) addHorarioModal!: ElementRef;
  @ViewChild('agendaGaleriaModal', { static: false }) agendaGaleriaModal!: ElementRef;


  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  agenda: Agenda = new Agenda();

  linksAgenda: Links[] = [];

  cepBusca: string = "";
  // endereco: Endereco = new Endereco();
  // listaEndereco: any[] = [];

  servico: Servico = new Servico();
  listaServico: any[] = [];

  isChecked: boolean[] = this.listaServico.map(() => false);

  diaDaSemana: { [key: string]: boolean } = {
    "Domingo": false,
    "Segunda": false,
    "Terca": false,
    "Quarta": false,
    "Quinta": false,
    "Sexta": false,
    "Sabado": false,
  };

  diaDaSemanaHorario: HorariosDia = new HorariosDia();
  addHorarioUnico: Horario = new Horario();

  diasSemanaArray = Object.keys(this.diaDaSemana).map((dia) => ({
    nome: dia,
    nomeDesc: "",
    valor: this.diaDaSemana[dia],
  }));

  // Variável para armazenar as seleções feitas no ng-select
  diasSelecionados: any[] = [];
  formCamposSelecionados: any[] = [];
  formCamposObrigatoriosSelecionados: any[] = [];

  addHorarios: HorariosDia = new HorariosDia();

  closeOnSelect = false;
  empresaSigla: any | undefined;

  loading: boolean = true;
  tipos: Tipo[] = [];
  tipoSelecionado: number | null = null;

  emails = new FormArray([
    new FormControl<any>('', [Validators.required, Validators.email]), // Exemplo de e-mail inicial
  ]);

  selectedFiles: File[] = [];
  fileNames: string[] = [];
  progress: number = 0;
  downloadURLs: {
    nome: string;
    tamanho: number;
    tipo: string;
    criado: string;
    atualizado: string;
    url: string;
  }[] = [];
  loadingIMG: boolean = true;


  constructor(
    private router: Router,
    private usuarios: UsuariosService,
    private toast: ToastService,
    private linksService: LinksService,
    private cepService: CepService,
    private translateWord: TranslateWordService,
    private validatorService: ValidatorService,
    private contadorIndexService: ContadorIndexService,
    private localhostService: LocalhostService,
    private agendaService: AgendaService,
    private servicoService: ServicoService,
    private route: ActivatedRoute,
    private siglaService: SiglaService,
    private tipoService: TipoService,
    private fb: FormBuilder,
    private clipboard: Clipboard,
    // private storage: AngularFireStorage
  ){
    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.tipoService.getTipo(this.empresaSigla);
          this.servicoService.getServico(this.empresaSigla);
        });
      // }
    // });
    this.agenda.link.valor = `https://logwise-agendamento-dev-9cf97.web.app/${this.empresaSigla}/agendamentos`;

  }


  allCampos: boolean = false;
  allCamposObrigatorios: boolean = false;

  async ngOnInit() {
    // console.log(this.agenda)
    // console.log(this.diaDaSemana)

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }
    // Agora você pode usar o ID como quiser

    this.linksAgenda = this.linksService.getLinksAgenda();

    this.agenda.id = await this.contadorIndexService.contadorIndex();

    this.tipoService.getAllTipo()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (tipos: Tipo[]) => {
        // console.log(agendas)
        // Faça algo com as notificações atualizadas
        if(tipos.length > 0){
          this.tipos = tipos;
          this.loading = false;
        }
      },
      error => {
        // console.error('Erro ao listar as Agenda:', error);
      }
    );

    this.servicoService.getAllServico()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (servicos: Servico[]) => {
        // console.log(servicos)
        // Faça algo com as notificações atualizadas
        this.listaServico = servicos;

      },
      error => {
        // console.error('Erro ao listar os Serviços:', error);
      }
    );

    for (let index = 0; index < this.diasSemanaArray.length; index++) {
      const element = this.diasSemanaArray[index];
      switch (element.nome) {
        case 'Domingo':
          element.nomeDesc = "Domingo"
          break;
        case 'Segunda':
          element.nomeDesc = "Segunda-feira"
          break;
        case 'Terca':
          element.nomeDesc = "Terça-feira"
          break;
        case 'Quarta':
          element.nomeDesc = "Quarta-feira"
          break;
        case 'Quinta':
          element.nomeDesc = "Quinta-feira"
          break;
        case 'Sexta':
          element.nomeDesc = "Sexta-feira"
          break;
        case 'Sabado':
          element.nomeDesc = "Sábado"
          break;
        default:
          element.nomeDesc = ""
          break;
      }

    }

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  updateCampos() {
    this.allCampos = this.agenda.formCampos.campos != null && this.agenda.formCampos.campos.every(t => t.check);
    // console.log(this.agenda.formCampos.campos)
  }

  setAllCampos(check: boolean) {
    this.allCampos = check;
    if (this.agenda.formCampos.campos == null) {
      return;
    }
    this.agenda.formCampos.campos.forEach(t => (t.check = check));
  }

  someCompleteCampos(): boolean {
    if (this.agenda.formCampos.campos == null) {
      return false;
    }
    return this.agenda.formCampos.campos.filter(t => t.check).length > 0 && !this.allCampos;
  }

  getCamposVisiveis(): any[] {
    return this.agenda.formCampos.campos.filter((campo) => campo.visivel);
  }

  updateCamposObrigatorios() {
    this.allCamposObrigatorios = this.agenda.formCamposObrigatorios.campos != null && this.agenda.formCamposObrigatorios.campos.every(t => t.check);
  }

  setAllCamposObrigatorios(check: boolean) {
    this.allCamposObrigatorios = check;
    if (this.agenda.formCamposObrigatorios.campos == null) {
      return;
    }
    this.agenda.formCamposObrigatorios.campos.forEach(t => (t.check = check));
  }

  someCompleteCamposObrigatorios(): boolean {
    if (this.agenda.formCamposObrigatorios.campos == null) {
      return false;
    }
    return this.agenda.formCamposObrigatorios.campos.filter(t => t.check).length > 0 && !this.allCamposObrigatorios;
  }

  getCamposObrigatoriosVisiveis(){
    return this.agenda.formCamposObrigatorios.campos.filter((campo) => campo.visivel);
  }

  async addServico(){

    this.servico.nome.status = this.validatorService.criarValidacao(this.servico.nome.valor, this.servico.nome.validacoesCustomizadas);
    this.servico.valor.status = this.validatorService.criarValidacao(this.servico.valor.valor, this.servico.valor.validacoesCustomizadas);
    this.servico.linkPagamento.status = this.validatorService.criarValidacao(this.servico.linkPagamento.valor, this.servico.linkPagamento.validacoesCustomizadas);
    this.servico.duracaoAtendimento.status = this.validatorService.criarValidacao(this.servico.duracaoAtendimento.valor, this.servico.duracaoAtendimento.validacoesCustomizadas);
    this.servico.numeroPessoasPorHorario.status = this.validatorService.criarValidacao(this.servico.numeroPessoasPorHorario.valor, this.servico.numeroPessoasPorHorario.validacoesCustomizadas);
    this.servico.instrucoesConfirmacao.status = this.validatorService.criarValidacao(this.servico.instrucoesConfirmacao.valor, this.servico.instrucoesConfirmacao.validacoesCustomizadas);

    if(this.servico.nome.status.invalid ||
      this.servico.valor.status.invalid ||
      this.servico.linkPagamento.status.invalid ||
      this.servico.duracaoAtendimento.status.invalid ||
      this.servico.numeroPessoasPorHorario.status.invalid ||
      this.servico.instrucoesConfirmacao.status.invalid
    ){
      this.abrirModal(this.alertServicoModal.nativeElement);
    }else{

      this.servico.id = await this.contadorIndexService.contadorIndex();
      let new_servico  = new Servico();
      new_servico = this.servico;

      new_servico.criado = new Date();
      new_servico.atualizado = new Date();

      // this.agenda.servicos.push(new_servico)
      // let servico = DataService.get("Servico");
      // console.log(servico);

      const servico = JSON.stringify(new_servico);
      const res = await this.servicoService.criarServico(JSON.parse(servico), this.empresaSigla)
      // console.log(res)
      // if(servico == undefined){
      //   let obj = [];
      //   new_servico.criado = new Date();
      //   new_servico.atualizado = new Date();


      //   if(!new_servico.vinculoAgenda.some(subArray => subArray.includes(this.agenda.id))){
      //     new_servico.vinculoAgenda.push(this.agenda.id)
      //   }

      //   obj.push(new_servico);
      //   DataService.setItem("Servico", obj);
      //   this.listaServico = DataService.get("Servico");
      // }else{
      //   new_servico.criado = new Date();
      //   new_servico.atualizado = new Date();

      //   if(!new_servico.vinculoAgenda.some(subArray => subArray.includes(this.agenda.id))){
      //     new_servico.vinculoAgenda.push(this.agenda.id)
      //   }

      //   servico.push(new_servico);
      //   DataService.setItem("Servico", servico);
      //   this.listaServico = DataService.get("Servico");
      // }

      // this.showSuccess("Serviço adicionado com sucesso")

      // \\n quebra linha
      // this.toast.enviarNotificacao("Notificação","Novo serviço disponível", "")


      this.servico = new Servico();

      // console.log(this.agenda)
    }

  }

  vincularServico(event: any, idServ: any){
    // console.log(event.checked)
    if(event.checked){

      for (let i = 0; i < this.listaServico.length; i++) {
        const serv = this.listaServico[i];

        if(serv.id == idServ){
          if(this.agenda.servicos.length > 0){

            let verificacao = false;

            for (let j = 0; j < this.agenda.servicos.length; j++) {
              const servAg = this.agenda.servicos[j];
              if(servAg.id == idServ){
                verificacao = true;
              }
            }

            if(!verificacao){
              let new_servico  = new Servico();
              new_servico = serv;
              this.agenda.servicos.push(new_servico)
            }

          }else{
            let new_servico  = new Servico();
            new_servico = serv;
            this.agenda.servicos.push(new_servico)
          }
        }
      }

      this.showSuccess("Serviço adicionado com sucesso")

    }else{
      // Encontrar o índice do serviço correspondente a idEnd
      const index = this.agenda.servicos.findIndex(servico => servico.id === idServ);

      if (index !== -1) {
        // Excluir o endereço de this.agenda.servicos
        this.agenda.servicos.splice(index, 1);
      }

      // Remover this.agenda.id dos arrays vinculoAgenda em objetos de this.listaServico
      for (let i = 0; i < this.listaServico.length; i++) {
        const servico = this.listaServico[i];
        const vinculoAgenda = servico.vinculoAgenda;

        if (Array.isArray(vinculoAgenda) && servico.id === idServ) {
          const idIndex = vinculoAgenda.indexOf(this.agenda.id);
          if (idIndex !== -1) {
            vinculoAgenda.splice(idIndex, 1);
          }
        }
      }

      this.showSuccess("Serviço deletado");
    }

    this.checkServico()

  }

  checkServico() {

    this.isChecked = [];

    for (let index = 0; index < this.listaServico.length; index++) {
      const vinculoAgenda = this.listaServico[index].vinculoAgenda;
      this.isChecked[index] = (Array.isArray(vinculoAgenda) && vinculoAgenda.includes(this.agenda.id));
    }
    // console.log(this.isChecked)
  }

  deletarServico(idServ: any){
    // Encontrar o índice do endereço correspondente a idEnd
    const index = this.agenda.servicos.findIndex(servico => servico.id === idServ);

    if (index !== -1) {
      // Excluir o endereço de this.agenda.servicos
      this.agenda.servicos.splice(index, 1);
    }

    // Remover this.agenda.id dos arrays vinculoAgenda em objetos de this.listaEndereco
    for (let i = 0; i < this.listaServico.length; i++) {
      const servico = this.listaServico[i];
      const vinculoAgenda = servico.vinculoAgenda;

      if (Array.isArray(vinculoAgenda) && servico.id === idServ) {
        const idIndex = vinculoAgenda.indexOf(this.agenda.id);
        if (idIndex !== -1) {
          vinculoAgenda.splice(idIndex, 1);
        }
      }
    }

    this.checkServico()

  }

  criarAgenda(){

    this.agenda.nome.status = this.validatorService.criarValidacao(this.agenda.nome.valor, this.agenda.nome.validacoesCustomizadas);
    this.agenda.subNome.status = this.validatorService.criarValidacao(this.agenda.subNome.valor, this.agenda.subNome.validacoesCustomizadas);
    this.agenda.link.status = this.validatorService.criarValidacao(this.agenda.link.valor, this.agenda.link.validacoesCustomizadas);
    this.agenda.instrucoes.status = this.validatorService.criarValidacao(this.agenda.instrucoes.valor, this.agenda.instrucoes.validacoesCustomizadas);
    this.agenda.duracaoAtendimento.status = this.validatorService.criarValidacao(this.agenda.duracaoAtendimento.valor, this.agenda.duracaoAtendimento.validacoesCustomizadas);
    this.agenda.antecedenciaMinimaHoras.status = this.validatorService.criarValidacao(this.agenda.antecedenciaMinimaHoras.valor, this.agenda.antecedenciaMinimaHoras.validacoesCustomizadas);
    this.agenda.antecedenciaMaximaDias.status = this.validatorService.criarValidacao(this.agenda.antecedenciaMaximaDias.valor, this.agenda.antecedenciaMaximaDias.validacoesCustomizadas);
    this.agenda.linkImagem.status = this.validatorService.criarValidacao(this.agenda.linkImagem.valor, this.agenda.linkImagem.validacoesCustomizadas);
    this.agenda.agenda_inicio.status = this.validatorService.criarValidacao(this.agenda.agenda_inicio.valor, this.agenda.agenda_inicio.validacoesCustomizadas);
    this.agenda.agenda_fim.status = this.validatorService.criarValidacao(this.agenda.agenda_fim.valor, this.agenda.agenda_fim.validacoesCustomizadas);

    // console.log(this.validateEmails())

    // console.log(this.emails.controls)
    // console.log(this.emailsControlsValueArray())
    if(!this.validateEmails()){
      this.showError("Nos e-mails de notificação da agenda, há campos vazios ou com endereços de e-mail inválidos!");
    } else if(this.agenda.nome.status.invalid ||
      this.agenda.subNome.status.invalid ||
      this.agenda.link.status.invalid ||
      this.agenda.instrucoes.status.invalid ||
      this.agenda.duracaoAtendimento.status.invalid ||
      this.agenda.antecedenciaMinimaHoras.status.invalid ||
      this.agenda.antecedenciaMaximaDias.status.invalid ||
      this.agenda.linkImagem.status.invalid ||
      this.agenda.agenda_inicio.status.invalid ||
      this.agenda.agenda_fim.status.invalid ||
      (this.tipoSelecionado == null)
    ){
      this.abrirModal(this.alertModal.nativeElement);
    }else{
      // console.log(this.agenda)
      this.agenda.tipoId = this.tipoSelecionado;
      this.agenda.notificacoes.emails = this.emailsControlsValueArray();
      const agenda = JSON.stringify(this.agenda);
      this.agendaService.criarAgenda(JSON.parse(agenda), this.empresaSigla)
    }

  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    const modal = new bootstrap.Modal(event);
    modal.show();
  }


  // Horarios
  async adicionarHorarios(){

    // console.log(this.diasSelecionados)

    if(this.diasSelecionados.length == 0){
      this.abrirModal(this.addHorarioModal.nativeElement);
      this.showError("Por favor, selecione pelo menos 1 dia da semana")

    }else{
      for (let index = 0; index < this.agenda.horarios.length; index++) {
        const element = this.agenda.horarios[index];
        // console.log(element);

        // Obtém o nome do dia da semana usando a função obterTituloDoDia
        let diaSemanaDoElemento;

        switch (element.dia.title) {
          case 'Domingo':
            diaSemanaDoElemento = this.diaDaSemana['Domingo'];
            break;
          case 'Segunda-feira':
            diaSemanaDoElemento = this.diaDaSemana['Segunda'];
            break;
          case 'Terça-feira':
            diaSemanaDoElemento = this.diaDaSemana['Terca'];
            break;
          case 'Quarta-feira':
            diaSemanaDoElemento = this.diaDaSemana['Quarta'];
            break;
          case 'Quinta-feira':
            diaSemanaDoElemento = this.diaDaSemana['Quinta'];
            break;
          case 'Sexta-feira':
            diaSemanaDoElemento = this.diaDaSemana['Sexta'];
            break;
          case 'Sábado':
            diaSemanaDoElemento = this.diaDaSemana['Sabado'];
            break;
          default:
            diaSemanaDoElemento = false;
            break;
        }

        // Verifica se o dia da semana correspondente é 'true' no objeto diaDaSemana
        if (diaSemanaDoElemento) {

          const novo_horario = new HorariosDia();
          novo_horario.id = await this.contadorIndexService.contadorIndex();

          // Criar novos objetos para cada propriedade
          novo_horario.hora_inicial = Object.assign({}, this.diaDaSemanaHorario.hora_inicial);
          novo_horario.hora_final = Object.assign({}, this.diaDaSemanaHorario.hora_final);
          novo_horario.num_pes_horario = Object.assign({}, this.diaDaSemanaHorario.num_pes_horario);

          element.horariosDia.push(novo_horario);
        }

      }

      this.showSuccess("Horários adicionado com sucesso!!")
    }


    this.diaDaSemanaHorario = new HorariosDia();

    this.diaDaSemana = {
      "Domingo": false,
      "Segunda": false,
      "Terca": false,
      "Quarta": false,
      "Quinta": false,
      "Sexta": false,
      "Sabado": false,
    };

    this.diasSelecionados = [];

  }

  onDiasSelecionadosChange() {

    let verific = false;

    for (let index = 0; index < this.diasSelecionados.length; index++) {
      const element = this.diasSelecionados[index];
      // console.log(element)
      if(element == "todos"){
        this.selecionarTodos("todos");
        this.closeOnSelect = true;
        verific = true;
      }
      if(element == "segSex"){
        this.selecionarTodos("segSex");
        this.closeOnSelect = true;
        verific = true;
      }
    }

    if(!verific){
      this.closeOnSelect = false;
    }

    // Define os dias como true com base nas seleções feitas
    this.diasSelecionados.forEach((diaSelecionado) => {
      this.diaDaSemana[diaSelecionado.nome] = true;
    });

    // console.log(this.diasSelecionados)
    // console.log(this.diaDaSemana)

  }

  selecionarTodos(select: any) {
    if(select == "todos"){
      this.diaDaSemana["Domingo"] = true;
      this.diaDaSemana["Segunda"] = true;
      this.diaDaSemana["Terca"] = true;
      this.diaDaSemana["Quarta"] = true;
      this.diaDaSemana["Quinta"] = true;
      this.diaDaSemana["Sexta"] = true;
      this.diaDaSemana["Sabado"] = true;
    }

    if(select == "segSex"){
      this.diaDaSemana["Domingo"] = false;
      this.diaDaSemana["Segunda"] = true;
      this.diaDaSemana["Terca"] = true;
      this.diaDaSemana["Quarta"] = true;
      this.diaDaSemana["Quinta"] = true;
      this.diaDaSemana["Sexta"] = true;
      this.diaDaSemana["Sabado"] = false;
    }

    // Atualiza this.diasSelecionados para corresponder ao formato esperado pelo ng-select
    this.diasSelecionados = this.diasSemanaArray.filter((dia) => this.diaDaSemana[dia.nome]);
    this.onDiasSelecionadosChange();
  }

  selectAllCampos(nome: any): void {
    // console.log(nome)

    // Primeiro, desmarque todos os campos
    this.agenda.formCampos.campos.forEach(campo => campo.check = false);

    // Em seguida, marque apenas os campos cujos nomes estão na lista nomesSelecionados
    nome.forEach((nome: any) => {
      const campo = this.agenda.formCampos.campos.find(campo => campo.nome === nome);
      if (campo) {
        campo.check = true;
      }
    });

    this.agenda.formCampos.campos.forEach(campo => {
      if (!campo.visivel) {
        campo.check = true;
      }
    });

    // console.log(this.formCamposSelecionados)
    // console.log(this.agenda.formCampos.campos)
    this.updateCampos();
  }

  selectAllCamposObrigatorios(nome: any): void {
    // console.log(nome)

    // Primeiro, desmarque todos os campos
    this.agenda.formCamposObrigatorios.campos.forEach(campo => campo.check = false);

    // Em seguida, marque apenas os campos cujos nomes estão na lista nomesSelecionados
    nome.forEach((nome: any) => {
      const campo = this.agenda.formCamposObrigatorios.campos.find(campo => campo.nome === nome);
      if (campo) {
        campo.check = true;
      }
    });

    this.agenda.formCamposObrigatorios.campos.forEach(campo => {
      if (!campo.visivel) {
        campo.check = true;
      }
    });

    // console.log(this.formCamposSelecionados)
    // console.log(this.agenda.formCampos.campos)
    this.updateCamposObrigatorios();
  }

  deletarHorario(idHr: any){

    for (let i = 0; i < this.agenda.horarios.length; i++) {
      const horarios = this.agenda.horarios[i].horariosDia;
      const dia = this.agenda.horarios[i].dia;
      const indiceParaExcluir = horarios.findIndex(horario => horario.id === idHr);

      if (indiceParaExcluir !== -1) {
        // console.log(dia)
        // console.log(horarios[indiceParaExcluir])
        this.showError(dia.title + "<br>Horário deletado com sucesso!!<br>" + horarios[indiceParaExcluir].hora_inicial.valor + " - " + horarios[indiceParaExcluir].hora_final.valor)

        horarios.splice(indiceParaExcluir, 1);
        break;
      }

    }
  }

  selectHorario(id: any){
    // console.log(id)
    // console.log(this.agenda.horarios)

    for (let index = 0; index < this.agenda.horarios.length; index++) {
      const hr = this.agenda.horarios[index];
      if(hr.id == id){
        Object.assign(this.addHorarioUnico, hr);
        // console.log(this.addHorarioUnico)
      }
    }

  }

  async adicionarHorario(){
    for (let index = 0; index < this.agenda.horarios.length; index++) {
      const hr = this.agenda.horarios[index];
      if(hr.id == this.addHorarioUnico.id){
        const novo_horario = new HorariosDia();
        novo_horario.id = await this.contadorIndexService.contadorIndex();

        // Criar novos objetos para cada propriedade
        novo_horario.hora_inicial = Object.assign({}, this.addHorarios.hora_inicial);
        novo_horario.hora_final = Object.assign({}, this.addHorarios.hora_final);
        novo_horario.num_pes_horario = Object.assign({}, this.addHorarios.num_pes_horario);

        hr.horariosDia.push(novo_horario);

        this.showSuccess(this.addHorarioUnico.dia.title + "<br>Horário adicionado com sucesso!!")

        this.addHorarios = new HorariosDia();
        break;
      }
    }
  }

  addEmail() {
    this.emails.push(new FormControl('', [Validators.required, Validators.email]));
  }

  removeEmail(index: number) {
    this.emails.removeAt(index);
  }

  onSubmit() {
    if (!this.emails.valid) {
      this.emails.controls.forEach((control) => control.markAsTouched());
      // console.log('Há erros nos e-mails!');
    }
  }

  validateEmails(): boolean {
    let isValid = true;

    if(this.agenda.notificacoes.valor){
      // Itera sobre os controles no FormArray
      this.emails.controls.forEach((control) => {
        if (control.invalid) {
          isValid = false;
          control.markAsTouched(); // Marca o controle como "tocado" para exibir os erros no template
        }
      });
    }

    return isValid;
  }

  onEmailInput(event: Event, emailControl: FormControl<any>) {
    const input = event.target as HTMLInputElement; // Cast para acessar o valor do input
    emailControl.setValue(input.value); // Atualiza o valor no FormControl
  }

  getInvalidEmailsCount(): number {
    return this.emails.controls.filter(control => control.invalid).length;
  }


  private emailsControlsValueArray(): string[] {
    const emailsSet = new Set<string>();

    this.emails.controls.forEach((control) => {
      const email = control.value?.trim().toLowerCase();
      if (email && !control.invalid) {
        emailsSet.add(email);
      }
    });

    return Array.from(emailsSet);
  }

  // Quando arquivos são selecionados manualmente via input
  onFileSelected(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      for (let i = 0; i < inputElement.files.length; i++) {
        this.selectedFiles.push(inputElement.files[i]);
        this.fileNames.push(inputElement.files[i].name); // Armazena o nome do arquivo
      }
    }
  }

  // Quando arquivos são soltos na área de drag & drop
  onFileDropped(files: FileList) {
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        this.selectedFiles.push(files[i]);
        this.fileNames.push(files[i].name); // Armazena o nome do arquivo
      }
    }
  }

  deleteFile(fileName: string) {
    const index = this.fileNames.indexOf(fileName);
    if (index !== -1) {
      // Remove o arquivo dos arrays
      this.fileNames.splice(index, 1);
      this.selectedFiles.splice(index, 1);
    }
  }

  // Faz o upload de todas as imagens selecionadas
  async uploadImages() {
    if (this.selectedFiles.length > 0) {
      const uploadObservables: Observable<string>[] = [];
      let completedUploads = 0; // Para contar quantos uploads foram concluídos

      // Envia cada arquivo para o Firebase Storage
      for (const file of this.selectedFiles) {
        const newFileName = await this.contadorIndexService.contadorIndex();
        const renamedFile = new File([file], newFileName, { type: file.type });

        // Cria um observable para o upload e monitora o progresso
        const uploadObservable = this.agendaService.uploadImage(this.empresaSigla, renamedFile).pipe(
          // Aqui você pode usar uma função para monitorar o progresso, caso sua API de upload ofereça essa funcionalidade
          tap({
            next: () => {
              // Atualiza a barra de progresso conforme o upload de cada arquivo é concluído
              completedUploads++;
              this.progress = Math.round((completedUploads / this.selectedFiles.length) * 100);
            }
          })
        );

        uploadObservables.push(uploadObservable);
      }

      // Aguarda todos os uploads serem concluídos antes de atualizar a lista
      forkJoin(uploadObservables).subscribe(
        (urls: string[]) => {
          this.loadImages();

          // Exclui os arquivos após o upload
          this.selectedFiles.forEach(file => {
            this.deleteFile(file.name);
          });

          this.selectedFiles = []; // Limpa os arquivos após o upload
          this.fileNames = []; // Limpa os nomes dos arquivos após o upload
          this.progress = 0; // Reseta o progresso após o upload ser concluído
        },
        (error) => {
          console.error('Erro ao fazer upload:', error);
          this.progress = 0; // Reseta o progresso em caso de erro
        }
      );
    }
  }


  // Carrega as imagens já armazenadas
  loadImages() {
    this.downloadURLs = [];
    this.loadingIMG = true;
    this.agendaService.listImages(this.empresaSigla).subscribe(fileData => {
      // console.log(fileData); // Agora contém os metadados
      if (fileData.length > 0) {
        this.loadingIMG = false;
        this.downloadURLs = fileData; // Agora é um array de objetos com metadados
      } else {
        setTimeout(() => {
          this.loadingIMG = false;
        }, 6500);
      }
    });
  }

  // Contar quantos arquivos foram selecionados
  getFileCount(): number {
    return this.fileNames.length;
  }

  // Deleta uma imagem específica
  deleteImage(url: string) {
    const fileIndex = this.downloadURLs.findIndex(file => file.url === url);

    if (fileIndex !== -1) {
      const fileName = this.downloadURLs[fileIndex].nome; // Obtém o nome correto do arquivo

      this.agendaService.deleteImage(this.empresaSigla, fileName).subscribe({
        next: (res) => {
          this.downloadURLs.splice(fileIndex, 1); // Remove o objeto corretamente
          this.showSuccess("Imagem deletada com sucesso");
        },
        error: (err) => {
          console.error('Erro ao excluir imagem:', err);
          this.showError("Erro ao deletar imagem. Erro: " + err);
        }
      });
    }
  }

  copyToClipboard(text: string): void {
    try{
      this.clipboard.copy(text);
      this.showSuccess(`Link copiado`)
    }catch(error){
      this.showError(`Erro ao copiar o link`)
    }
  }

  usarImagem(text: string): void {
    try{
      this.agenda.linkImagem.valor = text;
      this.showSuccess(`Imagem utilizada com sucesso.`)
    }catch(error){
      this.showError(`Não foi possível utilizar esta imagem.`)
    }
  }


  // onFileDropped(file: File) {
  //   this.selectedFile = file;
  // }

  // onFileSelected(event: any) {
  //   this.selectedFile = event.target.files[0];
  // }

  // loadImages() {
  //   this.downloadURLs = [];
  //   this.agendaService.listImages(this.empresaSigla).subscribe(urls => {
  //     this.downloadURLs = urls;
  //   });
  // }

  // deleteImage(url: string) {
  //   const fileName = url.split('%2F').pop()?.split('?')[0];

  //   if (fileName) {
  //     this.agendaService.deleteImage(this.empresaSigla, fileName)
  //       .subscribe(() => {
  //         this.downloadURLs = this.downloadURLs.filter(img => img !== url);
  //       });
  //   }
  // }

  trackByFn(index: number, item: { nome: string; tamanho: number; tipo: string; criado: string; atualizado: string; url: string; }) {
    return item.nome; // Usa o nome do arquivo como identificador único
  }

  async galeriaDaAgenda(){

    this.loadImages();

    this.abrirModal(this.agendaGaleriaModal.nativeElement);

  }

  // buscarCep() {
  //   if(this.endereco.cep.valor == undefined || this.endereco.cep.valor.trim() == ""){
  //     this.showError("Por favor, informe um cep para realizar a busca")
  //   }else{
  //     this.cepService.buscarEnderecoPorCEP(this.endereco.cep.valor)
  //     .pipe(
  //       catchError(async error => {
  //         try {
  //           // console.log('Ocorreu um erro ao buscar o endereço:', error);
  //           const trad = await this.translateWord.translateWord(error.message, 'pt');
  //           this.showError(trad)
  //           return throwError(trad)
  //         } catch (error2) {
  //           // console.log('Ocorreu um erro ao buscar o endereço:', error2);
  //           this.showError(error.message)
  //           return throwError(error.message)
  //         }
  //       })
  //     )
  //     .subscribe((resultado: any) => {
  //       if(resultado.erro){
  //         this.showError("O CEP não foi encontrado. Por favor, verifique o número digitado e tente novamente.")
  //       }else{
  //         this.endereco.cep.valor = resultado.cep;
  //         this.endereco.logradouro.valor = resultado.logradouro;
  //         this.endereco.complemento.valor = resultado.complemento;
  //         this.endereco.bairro.valor = resultado.bairro;
  //         this.endereco.localidade.valor = resultado.localidade;
  //         this.endereco.uf.valor = resultado.uf;

  //         this.endereco.cep.status = this.validatorService.criarValidacao(this.endereco.cep.valor, this.endereco.cep.validacoesCustomizadas);
  //         this.endereco.logradouro.status = this.validatorService.criarValidacao(this.endereco.logradouro.valor, this.endereco.logradouro.validacoesCustomizadas);
  //         this.endereco.numero.status = this.validatorService.criarValidacao(this.endereco.numero.valor, this.endereco.numero.validacoesCustomizadas);
  //         this.endereco.complemento.status = this.validatorService.criarValidacao(this.endereco.complemento.valor, this.endereco.complemento.validacoesCustomizadas);
  //         this.endereco.bairro.status = this.validatorService.criarValidacao(this.endereco.bairro.valor, this.endereco.bairro.validacoesCustomizadas);
  //         this.endereco.localidade.status = this.validatorService.criarValidacao(this.endereco.localidade.valor, this.endereco.localidade.validacoesCustomizadas);
  //         this.endereco.uf.status = this.validatorService.criarValidacao(this.endereco.uf.valor, this.endereco.uf.validacoesCustomizadas);
  //       }

  //     });
  //   }
  // }

  // addEndereco(){

  //   this.endereco.cep.status = this.validatorService.criarValidacao(this.endereco.cep.valor, this.endereco.cep.validacoesCustomizadas);
  //   this.endereco.logradouro.status = this.validatorService.criarValidacao(this.endereco.logradouro.valor, this.endereco.logradouro.validacoesCustomizadas);
  //   this.endereco.numero.status = this.validatorService.criarValidacao(this.endereco.numero.valor, this.endereco.numero.validacoesCustomizadas);
  //   this.endereco.complemento.status = this.validatorService.criarValidacao(this.endereco.complemento.valor, this.endereco.complemento.validacoesCustomizadas);
  //   this.endereco.bairro.status = this.validatorService.criarValidacao(this.endereco.bairro.valor, this.endereco.bairro.validacoesCustomizadas);
  //   this.endereco.localidade.status = this.validatorService.criarValidacao(this.endereco.localidade.valor, this.endereco.localidade.validacoesCustomizadas);
  //   this.endereco.uf.status = this.validatorService.criarValidacao(this.endereco.uf.valor, this.endereco.uf.validacoesCustomizadas);


  //   if(this.endereco.cep.status.invalid ||
  //     this.endereco.logradouro.status.invalid ||
  //     this.endereco.numero.status.invalid ||
  //     this.endereco.complemento.status.invalid ||
  //     this.endereco.bairro.status.invalid ||
  //     this.endereco.localidade.status.invalid ||
  //     this.endereco.uf.status.invalid
  //   ){
  //     this.abrirModal(this.alertEnderecoModal.nativeElement);
  //   }else{

  //     this.endereco.id = await this.contadorIndexService.contadorIndex();
  //     let new_endereco  = new Endereco();
  //     new_endereco = this.endereco;
  //     this.agenda.enderecos.push(new_endereco)

  //     let endereco = DataService.get("Endereco");
  //     console.log(endereco);

  //     if(endereco == undefined){
  //       let obj = [];
  //       new_endereco.criado = new Date();
  //       new_endereco.atualizado = new Date();

  //       if(!new_endereco.vinculoAgenda.some(subArray => subArray.includes(this.agenda.id))){
  //         new_endereco.vinculoAgenda.push(this.agenda.id)
  //       }

  //       obj.push(new_endereco);
  //       DataService.setItem("Endereco", obj);
  //       this.listaEndereco = DataService.get("Endereco");
  //     }else{
  //       new_endereco.criado = new Date();
  //       new_endereco.atualizado = new Date();

  //       if(!new_endereco.vinculoAgenda.some(subArray => subArray.includes(this.agenda.id))){
  //         new_endereco.vinculoAgenda.push(this.agenda.id)
  //       }

  //       endereco.push(new_endereco);
  //       DataService.setItem("Endereco", endereco);
  //       this.listaEndereco = DataService.get("Endereco");
  //     }

  //     this.showSuccess("Endereço adicionado com sucesso")

  //     // \\n quebra linha
  //     this.toast.enviarNotificacao("Notificação","Novo endereço disponível", "")


  //     this.endereco = new Endereco();
  //     this.cepBusca = "";

  //     console.log(this.agenda)
  //   }

  // }

  // vincularEndereco(event: any, idEnd: any){
  //   console.log(event.checked)
  //   if(event.checked){

  //     for (let i = 0; i < this.listaEndereco.length; i++) {
  //       const end = this.listaEndereco[i];

  //       if(end.id == idEnd){
  //         if(this.agenda.enderecos.length > 0){

  //           let verificacao = false;

  //           for (let j = 0; j < this.agenda.enderecos.length; j++) {
  //             const endAg = this.agenda.enderecos[j];
  //             if(endAg.id == idEnd){
  //               verificacao = true;
  //             }
  //           }

  //           if(!verificacao){
  //             let new_endereco  = new Endereco();
  //             new_endereco = end;
  //             this.agenda.enderecos.push(new_endereco)
  //           }

  //         }else{
  //           let new_endereco  = new Endereco();
  //           new_endereco = end;
  //           this.agenda.enderecos.push(new_endereco)
  //         }
  //       }
  //     }

  //     this.showSuccess("Endereço adicionado com sucesso")

  //   }else{
  //     // Encontrar o índice do endereço correspondente a idEnd
  //     const index = this.agenda.enderecos.findIndex(endereco => endereco.id === idEnd);

  //     if (index !== -1) {
  //       // Excluir o endereço de this.agenda.enderecos
  //       this.agenda.enderecos.splice(index, 1);
  //     }

  //     // Remover this.agenda.id dos arrays vinculoAgenda em objetos de this.listaEndereco
  //     for (let i = 0; i < this.listaEndereco.length; i++) {
  //       const endereco = this.listaEndereco[i];
  //       const vinculoAgenda = endereco.vinculoAgenda;

  //       if (Array.isArray(vinculoAgenda) && endereco.id === idEnd) {
  //         const idIndex = vinculoAgenda.indexOf(this.agenda.id);
  //         if (idIndex !== -1) {
  //           vinculoAgenda.splice(idIndex, 1);
  //         }
  //       }
  //     }

  //     this.showSuccess("Endereço deletado");
  //   }

  //   this.checkEndereco()

  // }

  // checkEndereco() {

  //   this.isChecked = [];

  //   for (let index = 0; index < this.listaEndereco.length; index++) {
  //     const vinculoAgenda = this.listaEndereco[index].vinculoAgenda;
  //     this.isChecked[index] = (Array.isArray(vinculoAgenda) && vinculoAgenda.includes(this.agenda.id));
  //   }
  //   console.log(this.isChecked)
  // }

  // deletarEndereco(idEnd: any){
  //   // Encontrar o índice do endereço correspondente a idEnd
  //   const index = this.agenda.enderecos.findIndex(endereco => endereco.id === idEnd);

  //   if (index !== -1) {
  //     // Excluir o endereço de this.agenda.enderecos
  //     this.agenda.enderecos.splice(index, 1);
  //   }

  //   // Remover this.agenda.id dos arrays vinculoAgenda em objetos de this.listaEndereco
  //   for (let i = 0; i < this.listaEndereco.length; i++) {
  //     const endereco = this.listaEndereco[i];
  //     const vinculoAgenda = endereco.vinculoAgenda;

  //     if (Array.isArray(vinculoAgenda) && endereco.id === idEnd) {
  //       const idIndex = vinculoAgenda.indexOf(this.agenda.id);
  //       if (idIndex !== -1) {
  //         vinculoAgenda.splice(idIndex, 1);
  //       }
  //     }
  //   }

  //   this.checkEndereco()

  // }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
