import { FormControl } from "@angular/forms";
import { FormularioTipoPergunta } from "src/enum/formularioTipoPergunta.enum";
import { campo } from "src/interface/campo";
import { ValidatorService } from "src/services/validator.service";
import { GruposAgendas } from "./grupo-agenda";

const validatorService: ValidatorService  = new ValidatorService();

export class Formulario {

  id: any | null = null;
  titulo: campo = {
    title: 'Título',
    info: 'Título do formulário',
    valor: '',
    placeholder: 'Título do formulário',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  descricao: campo = {
    title: 'Descrição',
    info: 'Descrição do formulário',
    valor: '',
    placeholder: 'Descrição do formulário',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: [],
    status: new FormControl()
  };

  tipoFormulario: campo = {
    title: 'Tipo de Formulário',
    info: 'Tipo de Formulário',
    valor: '',
    placeholder: 'Tipo de Formulário',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  status: campo = {
    title: 'Status',
    info: 'Status do formulário',
    valor: true
  };

  agendas: GruposAgendas[] = [];

  camposDinamicos: {[key: string]: campo } = {};

  criado: Date = new Date();

  atualizado: Date = new Date();

  constructor() {}

  adicionarCampo(chave: string, campo: campo) {
    // console.log("adicionarCampo")
    // Verifica se o campo já tem uma ordem definida
    if (!campo.ordem) {
      // Define a ordem como a próxima disponível
      campo.ordem = Object.keys(this.camposDinamicos).length + 1;
    } else {
      // Se o campo já tem uma ordem, reorganiza os campos existentes
      Object.keys(this.camposDinamicos).forEach((key) => {
        if (this.camposDinamicos[key].ordem >= campo.ordem) {
          this.camposDinamicos[key].ordem++;
        }
      });
    }

    // Adiciona o novo campo
    this.camposDinamicos[chave] = campo;

    // Reordena os campos dinamicamente após adicionar
    this.reordenarCampos();
  }

  reordenarCampos() {

    // Ordena as chaves pelo campo "ordem"
    const chavesOrdenadas = Object.keys(this.camposDinamicos).sort((keyA, keyB) => {
      const campoA = this.camposDinamicos[keyA];
      const campoB = this.camposDinamicos[keyB];
      if (campoA.ordem < campoB.ordem) {
        return -1;
      }
      if (campoA.ordem > campoB.ordem) {
        return 1;
      }
      return 0;
    });

    // Cria um novo objeto `camposDinamicosOrdenados` com as chaves ordenadas
    let camposDinamicosOrdenados: any = {};
    chavesOrdenadas.forEach(chave => {
      camposDinamicosOrdenados[chave] = this.camposDinamicos[chave];
    });

    // Substitui o objeto original pelos campos ordenados
    this.camposDinamicos = camposDinamicosOrdenados;

    // Reaplica a ordem correta, começando de 1
    chavesOrdenadas.forEach((chave, index) => {
      this.camposDinamicos[chave].ordem = index + 1;
    });
  }

  mascara(mascara: string, chave: string){
    if (this.camposDinamicos[chave]) {
      this.camposDinamicos[chave].valor = validatorService.aplicarMascara(this.camposDinamicos[chave].valor, mascara);
    }
  }

  validarInput(chave: string) {
    // console.log(chave)
    if (this.camposDinamicos[chave]) {
      this.camposDinamicos[chave].valor = this.camposDinamicos[chave].valor.trim();
      this.camposDinamicos[chave].status = validatorService.criarValidacao(this.camposDinamicos[chave].valor, this.camposDinamicos[chave].validacoesCustomizadas);
    }else{
      switch (chave) {
        case "titulo":
          this.titulo.valor = this.titulo.valor.trim();
          this.titulo.status = validatorService.criarValidacao(this.titulo.valor, this.titulo.validacoesCustomizadas);
          break;
        case "descricao":
          this.descricao.valor = this.descricao.valor.trim();
          this.descricao.status = validatorService.criarValidacao(this.descricao.valor, this.descricao.validacoesCustomizadas);
          break;
        case "tipoFormulario":
          if (this.tipoFormulario.valor) {
            this.tipoFormulario.valor = this.tipoFormulario.valor.trim();
          }
          this.tipoFormulario.status = validatorService.criarValidacao(this.tipoFormulario.valor, this.tipoFormulario.validacoesCustomizadas);
          break;
        default:
          break;
      }
    }
  }
}

// export class GruposAgendas{
//   idAgenda?: string;
//   NomeAgenda?: string;
// }

export class FormularioPergutna {
  pergunta: campo = {
    title: 'Pergunta do formulário',
    info: 'Pergunta do formulário',
    valor: '',
    placeholder: 'Pergunta do formulário',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  }

  placeholder: campo = {
    title: 'Texto de ajuda (placeholder)',
    info: 'Texto de ajuda (placeholder)',
    valor: '',
    placeholder: 'Texto de ajuda (placeholder)',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  }

  obrigatorio = {
    title: 'Obrigatório',
    info: 'A pergunta é obrigatória',
    valor: false
  }

  tipoPergunta: campo = {
    title: 'Tipo de resposta',
    info: 'Tipo de resposta',
    valor: '',
    placeholder: 'Tipo de resposta',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  }

  ordem:  campo = {
    title: 'Ordem',
    info: 'Ordem.',
    valor: '',
    placeholder: 'Ordem',
    inputType: 'number',
    inputMode: 'numeric',
    mascara: '#####',
    maxlength: '5',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  validarInput(chave: string) {
    switch (chave) {
      case "pergunta":
        this.pergunta.valor = this.pergunta.valor.trim();
        this.pergunta.status = validatorService.criarValidacao(this.pergunta.valor, this.pergunta.validacoesCustomizadas);
        break;
      case "placeholder":
        this.placeholder.valor = this.placeholder.valor.trim();
        this.placeholder.status = validatorService.criarValidacao(this.placeholder.valor, this.placeholder.validacoesCustomizadas);
        break;
      case "tipoPergunta":
        this.tipoPergunta.valor = this.tipoPergunta.valor.trim();
        this.tipoPergunta.status = validatorService.criarValidacao(this.tipoPergunta.valor, this.tipoPergunta.validacoesCustomizadas);
        break;
      case "ordem":
        this.ordem.valor = this.ordem.valor.trim();
        this.ordem.status = validatorService.criarValidacao(this.ordem.valor, this.ordem.validacoesCustomizadas);
        break;
      default:
        break;
    }
  }

}

export class FormularioConfigInput {
  tamanhoInput: campo | undefined;
  mascaraInput: campo | undefined;
  alternativas: Alternativas[] | undefined;

  constructor(tipoPergunta: FormularioTipoPergunta | null, id: any = null) {
    switch (tipoPergunta) {
      case FormularioTipoPergunta.TextoInput:
      case FormularioTipoPergunta.TextoTextarea:
      case FormularioTipoPergunta.Numero:
        this.tamanhoInput = this.criarTamanhoInput();
        break;

      case FormularioTipoPergunta.TextoInputComMascara:
        this.tamanhoInput = this.criarTamanhoInput(true);
        this.mascaraInput = this.criarMascaraInput();
        break;

      case FormularioTipoPergunta.UmaAlternativaRadio:
      case FormularioTipoPergunta.UmaAlternativaSelect:
      case FormularioTipoPergunta.VariasAlternativasCheckbox:
        this.alternativas = this.criarAlternativas(id);
        break;

      // Casos para Data, CNPJ, CPF podem ser configurados conforme necessário.
      // Adicione lógica extra aqui se precisar.
    }
  }

  private criarTamanhoInput(disabled: boolean = false): campo {
    return {
      title: 'Tamanho do texto',
      info: 'Tamanho do texto',
      valor: '10',
      placeholder: 'Tamanho do texto',
      inputType: 'text',
      inputMode: '[0-9]*',
      mascara: '',
      maxlength: '10',
      tipo: null,
      validacoesCustomizadas: ['required'],
      status: new FormControl({ value: '', disabled: disabled })
    };
  }

  private criarMascaraInput(): campo {
    return {
      title: 'Máscara do texto',
      info: 'A máscara deve ser definida usando o caractere # para representar um dígito numérico, e deve ser separada por um dos seguintes caracteres: . / - : ,',
      valor: '',
      placeholder: 'Exemplo: ###.###.###-## (CPF), ##.###.###/####-## (CNPJ), ##:## (Hora)',
      inputType: 'text',
      inputMode: 'text',
      mascara: '',
      maxlength: '1000',
      tipo: null,
      validacoesCustomizadas: ['required'],
      status: new FormControl()
    };
  }

  private criarAlternativas(id: any): Alternativas[] {
    return [
      new Alternativas(id)
      // Adicione mais seções de alternativas conforme necessário.
    ];
  }

  mascara(mascara: string, tipo: string){
    switch (tipo) {
      case "tamanhoInput":
        this.tamanhoInput!.valor = validatorService.aplicarMascara(this.tamanhoInput!.valor, mascara);
        break;
      case "mascaraInput":
        this.mascaraInput!.valor = validatorService.aplicarMascara(this.mascaraInput!.valor, mascara);
        break;
      default:
        break;
    }
  }

  validarInput(chave: string) {
    // console.log(chave)

    switch (chave) {
      case "tamanhoInput":
        this.tamanhoInput!.valor = this.tamanhoInput!.valor.trim();
        this.tamanhoInput!.status = validatorService.criarValidacao(this.tamanhoInput!.valor, this.tamanhoInput!.validacoesCustomizadas);
        break;
      case "mascaraInput":
        this.mascaraInput!.valor = this.mascaraInput!.valor.trim();
        this.mascaraInput!.status = validatorService.criarValidacao(this.mascaraInput!.valor, this.mascaraInput!.validacoesCustomizadas);
        break;
      default:
        break;
    }
  }

  validateInputPattern(tipo: string, pattern: string) {
    // console.log(pattern)
    switch (tipo) {
      case "mascaraInput":
        this.mascaraInput!.valor = validatorService.validateInputPattern(this.mascaraInput!.valor, pattern);
        this.mascaraInput!.valor = this.mascaraInput!.valor.trim();
        this.mascaraInput!.status = validatorService.criarValidacao(this.mascaraInput!.valor, this.mascaraInput!.validacoesCustomizadas);
        break;
      default:
        break;
    }
  }
}

export class Alternativas {
  id: any | null = null;

  label: campo = {
    title: 'Label',
    info: 'Este é o texto que será exibido para o cliente como a descrição do campo.',
    valor: '',
    placeholder: 'Digite o texto que será exibido ao cliente',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  valor: campo = {
    title: 'Valor',
    info: 'Este é o valor que será salvo quando o cliente preencher o campo. Pode ser o mesmo texto do label ou um valor diferente.',
    valor: '',
    placeholder: 'Digite o valor que será salvo ao enviar o formulário',
    inputType: 'text',
    inputMode: 'text',
    mascara: '',
    maxlength: '1000',
    tipo: null,
    validacoesCustomizadas: ['required'],
    status: new FormControl()
  };

  constructor(id: any){
    this.id = id;
  }

  mascara(mascara: string, tipo: string){
    switch (tipo) {
      case "label":
        this.label!.valor = validatorService.aplicarMascara(this.label!.valor, mascara);
        break;
      case "valor":
        this.valor!.valor = validatorService.aplicarMascara(this.valor!.valor, mascara);
        break;
      default:
        break;
    }
  }

  validarInput(chave: string) {
    // console.log(chave)

    switch (chave) {
      case "label":
        this.label!.valor = this.label!.valor.trim();
        this.label!.status = validatorService.criarValidacao(this.label!.valor, this.label!.validacoesCustomizadas);
        break;
      case "valor":
        this.valor!.valor = this.valor!.valor.trim();
        this.valor!.status = validatorService.criarValidacao(this.valor!.valor, this.valor!.validacoesCustomizadas);
        break;
      default:
        break;
    }
  }
}
