import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Usuario, UsuarioEmailSenha, objUsuario } from 'src/class/usuario';
import { getAuth, signInWithPopup, GoogleAuthProvider, UserCredential, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { LocalhostService } from 'src/services/localhost.service';
import { UsuariosService } from 'src/services/usuarios.services';
//import { ContaService } from 'src/component/conta.service';
import { Conta } from 'src/class/conta';
import { Acesso } from 'src/class/acesso';
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  } from "firebase/auth";
  import { DocumentData, getFirestore, collection, getDocs } from "firebase/firestore";
  import { doc, setDoc } from "firebase/firestore";
  import { initializeApp } from "firebase/app";
  import { getAnalytics } from "firebase/analytics";
  import { environment} from 'src/environment/environment';
  import { ContaService } from './component/conta.service';

const app = initializeApp(environment.firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

const auth = getAuth();
var authCurrentUser = auth.currentUser;
const googleAuthProvider = new GoogleAuthProvider();
const IDConta = LocalhostService.getIDConta();

@Injectable({
  providedIn: 'root'
})
export class ValidarUsuarioService {
  id: any;
  usr_id: string = JSON.parse(localStorage.getItem('user')!).uid;
  usr_tipo: string = "";
  usr_token: string = "";
  usr_obj: any = [];
  empresaSigla = localStorage.getItem('empresaSigla')!;


  constructor(
    private route: ActivatedRoute,
    private localhostService: LocalhostService,
    private conta: ContaService
  ) {

  }


  public async  getUsuario(){
    try {
      const conta = await this.conta.p_getSiglaConta('contas', this.empresaSigla);
      let usuario = await this.localhostService.getDadosId(`${conta.id}_usuarios`, this.usr_id)
        .then((usuario) => {
          this.usr_tipo = usuario.tipo.valor;
          console.log(usuario);
          return usuario
        },
        (error: any) => {
          console.error(error);
          // Trate o erro conforme necessário
          return null;
        })
        this.usr_obj = usuario;
        return usuario;
    } catch (error) {
      console.error(error);
      // Trate o erro conforme necessário
      return [];
    }
  }

  public async getAcesso() {
    try {
      // Obtém a sigla da conta
      const conta = await this.conta.p_getSiglaConta('contas', this.empresaSigla);

      // Consulta o usuário com a chave da conta e ID do usuário
      const usuario = await this.localhostService.getDadosId(`${conta.id}_usuarios`, this.usr_id)
        .then((usuario) => {
          //console.log('Usuário:', usuario);
          return usuario;
        })
        .catch((error) => {
          console.error('Erro ao consultar o usuário:', error);
          return null;
        });

      // Verificação caso o usuário não seja encontrado
      if (!usuario) {
        console.error('Usuário não encontrado');
        return null;
      }

      if (usuario.tipo.valor == "Comum") {
        // IDs dos grupos que o usuário pertence
        const idsGruposUsuario = usuario.grupo.map((grupo: any) => grupo.idGrupo);

        // Consulta todos os grupos do usuário baseado nos IDs
        const gruposPromises = idsGruposUsuario.map((idGrupo: string) =>
          this.localhostService.getDadosId(`${conta.id}_grupos_usuarios`, idGrupo)
        );

        const gruposUsuario = await Promise.all(gruposPromises)
          .then((grupos) => {
            //console.log('Grupos do usuário:', grupos);
            return grupos;
          })
          .catch((error) => {
            console.error('Erro ao consultar grupos de usuários:', error);
            return [];
          });

        // IDs dos grupos de agenda que o usuário pertence
        const idsGruposAgendaUsuario = usuario.grupoAgenda.map((agenda: any) => agenda.idGrupoAgenda);

        // Consulta todos os grupos de agenda do usuário baseado nos IDs
        const gruposAgendaPromises = idsGruposAgendaUsuario.map((idGrupoAgenda: string) =>
          this.localhostService.getDadosId(`${conta.id}_grupos_agendas`, idGrupoAgenda)
        );

        const gruposAgendaUsuario = await Promise.all(gruposAgendaPromises)
          .then((gruposAgenda) => {
            //console.log('Grupos de agenda do usuário:', gruposAgenda);
            return gruposAgenda;
          })
          .catch((error) => {
            console.error('Erro ao consultar grupos de agenda:', error);
            return [];
          });

        // Criando o objeto 'Acesso'
        const acesso = new Acesso();

        // Adiciona todos os idsRecurso dos grupos de usuários no objeto 'Acesso'
        gruposUsuario.forEach(grupo => {
          if (grupo && grupo.recursos) {
            grupo.recursos.forEach((recurso: any) => {
              if (recurso.idRecurso) {
                acesso.adicionarRecurso(recurso.idRecurso);
              }
            });
          }
        });

        // Adiciona todos os idsAgenda dos grupos de agenda no objeto 'Acesso'
        gruposAgendaUsuario.forEach(grupoAgenda => {
          if (grupoAgenda && grupoAgenda.agendas) {
            grupoAgenda.agendas.forEach((agenda: any) => {
              if (agenda.idAgenda) {
                acesso.adicionarAgenda(agenda.idAgenda);
              }
            });
          }
        });



        //console.log('Acesso:', acesso);
        return acesso;
      }else{
        let acesso = this.getTodosRecursosEAgendas();
        //console.log('Acesso:', acesso);
        return acesso;
      }


    } catch (error) {
      console.error('Erro geral:', error);
      return null;
    }
  }

  private async getTodosRecursosEAgendas() {
    try {
      // Obtém a sigla da conta
      const conta = await this.conta.p_getSiglaConta('contas', this.empresaSigla);

      // Consulta todos os recursos
      const todosRecursos = await this.localhostService.getDadosP('recursos')
        .then((recursos) => {
          //console.log('Todos os recursos:', recursos);
          return recursos;
        })
        .catch((error) => {
          console.error('Erro ao consultar os recursos:', error);
          return [];
        });

      // Consulta todas as agendas
      const todasAgendas = await this.localhostService.getDadosP(`${conta.id}_agendas`)
        .then((agendas) => {
          //console.log('Todas as agendas:', agendas);
          return agendas;
        })
        .catch((error) => {
          console.error('Erro ao consultar as agendas:', error);
          return [];
        });

      // Criando o objeto 'Acesso'
      const acesso = new Acesso();

      // Adiciona todos os IDs de recursos no objeto 'Acesso'
      todosRecursos.forEach((recurso: any) => {
        if (recurso.id) {
          acesso.adicionarRecurso(recurso.id);
        }
      });

      // Adiciona todos os IDs de agendas no objeto 'Acesso'
      todasAgendas.forEach((agenda: any) => {
        if (agenda.id) {
          acesso.adicionarAgenda(agenda.id);
        }
      });

      return acesso;  // Retorna o objeto 'Acesso' com os recursos e agendas

    } catch (error) {
      console.error('Erro geral ao consultar recursos e agendas:', error);
      return null;
    }
  }





}
