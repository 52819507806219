
<div class="pb-4 min-width">

  <div class="container text-center p-0">

    <!-- 👇 Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 mb-2 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Checkin</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- Breadcrumb 👆 -->

    <!-- 👇 Novo agendamento -->
    <!-- <div class="row p-0 m-0">
      <div class=" col-lg-4 mb-3" style=" cursor: pointer; ">
        <button (click)="updateCheckins()" type="button" class="btn btn-sm p-0 m-0 btn-principal-primary">
          <span class="btn-principal-primary-nome">
            Atualizar
          </span>
          <span class="btn-principal-primary-icon">
            <i class="bi bi-clock"></i>
          </span>
        </button>
      </div>
    </div> -->
    <!-- Novo agendamento 👆 -->

    <div class="row p-0 m-0">
      <div class="min-width col-12 p-0 m-0">
        <div class="row p-0 m-0">
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
            <div class="box-gen-card">

              <div class="d-flex text-cor-padrao" style=" display: flex; justify-content: space-between; align-items: center; ">
                <div>
                  <i class="bi bi-circle-fill ag-mr-5 text-cor-alerta" style=" color: var(--lw-cor-s); font-size: 12px; "></i>
                  Aguardando
                </div>
                <div>
                  <span>Total: </span>
                  <span class="card-padrao-list-box-bg-value" style="padding: 0px 10px;display: inline-block;">{{checkins_aguardando.length}}</span>
                </div>
              </div>

              <div class="d-flex text-cor-padrao pt-2" style=" display: flex; justify-content: space-between; align-items: center; ">
                <app-ag-tempo-medio [items]="checkins_aguardando"></app-ag-tempo-medio>
                <app-ag-tempo-pico [items]="checkins_aguardando"></app-ag-tempo-pico>
              </div>


              <div style="max-height: 75vh;overflow: overlay;">

                <div class="card card-padrao-list p-0 m-0 mt-3 pulsate" style="min-height: 88.8px;" *ngIf="loading"></div>
                <div class="card card-padrao-list p-0 m-0 mt-3 pulsate" style="min-height: 88.8px;" *ngIf="loading"></div>

                <div class="card card-padrao-list p-0 m-0 mt-3" *ngFor="let aguardando of checkins_aguardando">
                  <div class="card-body">
                    <div class="row card-body-row">
                      <!-- Visivel inicialmente -->
                      <div class="col-lg-12 mb-1">
                        <div class="card-body-row-div">
                          <div class="card-padrao-list-box">

                            <div class="mb-1 d-flex justify-content-start flex-row align-items-center" style="flex-wrap: wrap">
                              <ng-container *ngIf="getAgendaTipoNome(aguardando.atendimentoId) as tipoNome">
                                <span class="m-0 p-0 ag-mr-10 ag-mb-5 text-start" title="Tipo" style="background: var(--background);border-radius: 10px !important;padding-left: 8px !important;padding-right: 8px !important;padding-top: 1px !important;color: var(--text-cor-padrao);font-size: 9px !important;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row;">
                                  <span style="line-height: 17px;">{{ tipoNome }}</span>
                                </span>
                              </ng-container>
                            </div>

                            <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                              <span class="m-0 p-0 ag-mr-10 text-start card-padrao-list-img-title" style=" font-weight: 500; ">
                                NF
                              </span>
                              <span class="card-padrao-list-img-title p-0 m-0" [innerHTML]="aguardando.notaFiscal.valor" style=" font-weight: 500; ">
                              </span>
                            </div>
                            <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                              <span class="m-0 p-0 ag-mr-10 text-start">
                                {{aguardando.numeroPedido.title}}
                              </span>
                              <span class="card-padrao-list-box-nobg-value" [innerHTML]="aguardando.numeroPedido.valor">
                              </span>
                            </div>
                            <div class="d-flex justify-content-start flex-row align-items-center ">
                              <div class="card-body-time p-0 m-0 w-100">
                                <span>
                                  <i class="bi bi-clock ag-pr-5"></i>
                                  <span [innerHTML]="(aguardando.statusAguardando.inicio == '' ? '--/--/----, --:--' : (aguardando.statusAguardando.inicio | date:'dd/MM/yyyy, HH:mm') || '')"></span>
                                  <!-- {{agenda.agenda_inicio.valor}} -->
                                </span>
                                <app-ag-tempo-decorrido [inicio]="aguardando.statusAguardando.inicio"></app-ag-tempo-decorrido>
                                <!-- <span>
                                  <i class="bi bi-clock-history ag-pr-5"></i>
                                  <span [innerHTML]="aguardando.statusAguardando.inicio | tempoDecorrido"></span>
                                </span> -->

                              </div>
                            </div>
                          </div>
                          <div class="card-padrao-footer-div-menu">
                            <i class="bi bi-three-dots" title="menu" mat-icon-button [matMenuTriggerFor]="editarAgendamento_i"></i>
                            <mat-menu #editarAgendamento_i="matMenu">
                              <button mat-menu-item (click)="routerLink('checkin', aguardando.id)">
                                <i class="bi bi-pencil-square ag-pr-10 text-cor-primaria"></i>
                                <span>Editar</span>
                              </button>
                              <button mat-menu-item (click)="atualizarStatusCheckinEmAndamento(aguardando.id, statusCheckin.emAndamento)">
                                <i class="bi bi-circle-fill ag-pr-10 text-cor-info"></i>
                                <span>{{statusCheckin.emAndamento}}</span>
                              </button>
                              <button mat-menu-item (click)="atualizarStatusCheckinNaoRealizado(aguardando.id)">
                                <i class="bi bi-circle-fill ag-pr-10 text-cor-danger"></i>
                                <span>{{statusCheckin.naoRealizado}}</span>
                              </button>
                              <!-- <button mat-menu-item (click)="deletarAgendamento(aguardando.id)">
                                <i class="bi bi-trash3 ag-pr-10 text-cor-danger"></i>
                                <span>Deletar</span>
                              </button> -->
                            </mat-menu>
                            <i class="bi bi-chevron-expand" title="expand" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_' + aguardando.id" aria-expanded="false" [attr.aria-controls]="'collapse_' + aguardando.id"></i>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
            <div class="box-gen-card">

              <div class="d-flex text-cor-padrao" style=" display: flex; justify-content: space-between; align-items: center; ">
                <div>
                  <i class="bi bi-circle-fill ag-mr-5 text-cor-info" style=" color: var(--lw-cor-s); font-size: 12px; "></i>
                  Em Andamento
                </div>
                <div>
                  <span>Total: </span>
                  <span class="card-padrao-list-box-bg-value" style="padding: 0px 10px;display: inline-block;">{{checkins_em_andamento.length}}</span>
                </div>
              </div>

              <div class="d-flex text-cor-padrao pt-2" style=" display: flex; justify-content: space-between; align-items: center; ">
                <app-ag-tempo-medio [items]="checkins_em_andamento"></app-ag-tempo-medio>
                <app-ag-tempo-pico [items]="checkins_em_andamento"></app-ag-tempo-pico>
              </div>

              <div style="max-height: 75vh;overflow: overlay;">

                <div class="card card-padrao-list p-0 m-0 mt-3 pulsate" style="min-height: 88.8px;" *ngIf="loading"></div>
                <div class="card card-padrao-list p-0 m-0 mt-3 pulsate" style="min-height: 88.8px;" *ngIf="loading"></div>

                <div class="card card-padrao-list p-0 m-0 mt-3" *ngFor="let andamento of checkins_em_andamento">
                  <div class="card-body">
                    <div class="row card-body-row">
                      <!-- Visivel inicialmente -->
                      <div class="col-lg-12 mb-1">
                        <div class="card-body-row-div">
                          <div class="card-padrao-list-box">

                            <div class="mb-1 d-flex justify-content-start flex-row align-items-center" style="flex-wrap: wrap">
                              <ng-container *ngIf="andamento.doca?.IdDoca?.trim() != ''">
                                <span class="m-0 p-0 ag-mr-10 ag-mb-5 text-start" title="Tipo" style="background: var(--lw-cor-p);border-radius: 10px !important;padding-left: 8px !important;padding-right: 8px !important;padding-top: 1px !important;color: var(--cor-branco);font-size: 9px !important;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row;">
                                  <span style="line-height: 17px;">{{ andamento.doca!.NomeDoca! }}</span>
                                </span>
                              </ng-container>

                              <ng-container *ngIf="getAgendaTipoNome(andamento.atendimentoId) as tipoNome">
                                <span class="m-0 p-0 ag-mr-10 ag-mb-5 text-start" title="Tipo" style="background: var(--background);border-radius: 10px !important;padding-left: 8px !important;padding-right: 8px !important;padding-top: 1px !important;color: var(--text-cor-padrao);font-size: 9px !important;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row;">
                                  <span style="line-height: 17px;">{{ tipoNome }}</span>
                                </span>
                              </ng-container>
                            </div>

                            <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                              <span class="m-0 p-0 ag-mr-10 text-start card-padrao-list-img-title" style=" font-weight: 500; ">
                                NF
                              </span>
                              <span class="card-padrao-list-img-title p-0 m-0" [innerHTML]="andamento.notaFiscal.valor" style=" font-weight: 500; ">
                              </span>
                            </div>
                            <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                              <span class="m-0 p-0 ag-mr-10 text-start">
                                {{andamento.numeroPedido.title}}
                              </span>
                              <span class="card-padrao-list-box-nobg-value" [innerHTML]="andamento.numeroPedido.valor">
                              </span>
                            </div>
                            <div class="d-flex justify-content-start flex-row align-items-center ">
                              <div class="card-body-time p-0 m-0 w-100">
                                <span>
                                  <i class="bi bi-clock ag-pr-5"></i>
                                  <span [innerHTML]="(andamento.statusEmAndamento.inicio == '' ? '--/--/----, --:--' : (andamento.statusEmAndamento.inicio | date:'dd/MM/yyyy, HH:mm') || '')"></span>
                                  <!-- {{agenda.agenda_inicio.valor}} -->
                                </span>
                                <app-ag-tempo-decorrido [inicio]="andamento.statusEmAndamento.inicio"></app-ag-tempo-decorrido>
                                <!-- <span>
                                  <i class="bi bi-clock-history ag-pr-5"></i>
                                  <span [innerHTML]="andamento.statusEmAndamento.inicio | tempoDecorrido"></span>
                                </span> -->

                              </div>
                            </div>
                          </div>
                          <div class="card-padrao-footer-div-menu">
                            <i class="bi bi-three-dots" title="menu" mat-icon-button [matMenuTriggerFor]="editarAgendamento_i"></i>
                            <mat-menu #editarAgendamento_i="matMenu">
                              <button mat-menu-item (click)="routerLink('checkin', andamento.id)">
                                <i class="bi bi-pencil-square ag-pr-10 text-cor-primaria"></i>
                                <span>Editar</span>
                              </button>
                              <button mat-menu-item (click)="atualizarStatusCheckinRealizado(andamento.id, statusCheckin.realizado)">
                                <i class="bi bi-circle-fill ag-pr-10 text-cor-sucesso"></i>
                                <span>{{statusCheckin.realizado}}</span>
                              </button>
                              <button mat-menu-item (click)="atualizarStatusCheckinNaoRealizado(andamento.id)">
                                <i class="bi bi-circle-fill ag-pr-10 text-cor-danger"></i>
                                <span>{{statusCheckin.naoRealizado}}</span>
                              </button>
                              <!-- <button mat-menu-item (click)="deletarAgendamento(andamento.id)">
                                <i class="bi bi-trash3 ag-pr-10 text-cor-danger"></i>
                                <span>Deletar</span>
                              </button> -->
                            </mat-menu>
                            <i class="bi bi-chevron-expand" title="expand" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_' + andamento.id" aria-expanded="false" [attr.aria-controls]="'collapse_' + andamento.id"></i>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-3">
            <div class="box-gen-card">

              <div class="d-flex text-cor-padrao" style=" display: flex; justify-content: space-between; align-items: center; ">
                <div>
                  <i class="bi bi-circle-fill ag-mr-5 text-cor-sucesso" style=" color: var(--lw-cor-s); font-size: 12px; "></i>
                  Realizado
                </div>
                <div>
                  <span>Total: </span>
                  <span class="card-padrao-list-box-bg-value" style="padding: 0px 10px;display: inline-block;">{{checkins_realizados.length}}</span>
                </div>
              </div>

              <div class="d-flex text-cor-padrao pt-2" style=" display: flex; justify-content: space-between; align-items: center; ">
                <app-ag-tempo-medio [items]="checkins_realizados"></app-ag-tempo-medio>
                <app-ag-tempo-pico [items]="checkins_realizados"></app-ag-tempo-pico>
              </div>

              <div style="max-height: 75vh;overflow: overlay;">

                <div class="card card-padrao-list p-0 m-0 mt-3 pulsate" style="min-height: 88.8px;" *ngIf="loading"></div>
                <div class="card card-padrao-list p-0 m-0 mt-3 pulsate" style="min-height: 88.8px;" *ngIf="loading"></div>

                <div class="card card-padrao-list p-0 m-0 mt-3" *ngFor="let realizado of checkins_realizados">
                  <div class="card-body">
                    <div class="row card-body-row">
                      <!-- Visivel inicialmente -->
                      <div class="col-lg-12 mb-1">
                        <div class="card-body-row-div">
                          <div class="card-padrao-list-box">

                            <div class="mb-1 d-flex justify-content-start flex-row align-items-center" style="flex-wrap: wrap">
                              <ng-container *ngIf="getAgendaTipoNome(realizado.atendimentoId) as tipoNome">
                                <span class="m-0 p-0 ag-mr-10 ag-mb-5 text-start" title="Tipo" style="background: var(--background);border-radius: 10px !important;padding-left: 8px !important;padding-right: 8px !important;padding-top: 1px !important;color: var(--text-cor-padrao);font-size: 9px !important;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row;">
                                  <span style="line-height: 17px;">{{ tipoNome }}</span>
                                </span>
                              </ng-container>
                            </div>

                            <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                              <span class="m-0 p-0 ag-mr-10 text-start card-padrao-list-img-title" style=" font-weight: 500; ">
                                NF
                              </span>
                              <span class="card-padrao-list-img-title p-0 m-0" [innerHTML]="realizado.notaFiscal.valor" style=" font-weight: 500; ">
                              </span>
                            </div>
                            <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                              <span class="m-0 p-0 ag-mr-10 text-start">
                                {{realizado.numeroPedido.title}}
                              </span>
                              <span class="card-padrao-list-box-nobg-value" [innerHTML]="realizado.numeroPedido.valor">
                              </span>
                            </div>
                            <div class="d-flex justify-content-start flex-row align-items-center ">
                              <div class="card-body-time p-0 m-0 w-100">
                                <span>
                                  <i class="bi bi-clock ag-pr-5"></i>
                                  <span [innerHTML]="(realizado.statusRealizado.inicio == '' ? '--/--/----, --:--' : (realizado.statusRealizado.inicio | date:'dd/MM/yyyy, HH:mm') || '')"></span>
                                  <!-- {{agenda.agenda_inicio.valor}} -->
                                </span>
                                <!-- <span>
                                  <i class="bi bi-clock-history ag-pr-5"></i>
                                  <span [innerHTML]="realizado.statusRealizado.inicio | tempoDecorrido"></span>
                                </span> -->

                              </div>
                            </div>
                          </div>
                          <div class="card-padrao-footer-div-menu">
                            <i class="bi bi-three-dots" title="menu" mat-icon-button [matMenuTriggerFor]="editarAgendamento_i"></i>
                            <mat-menu #editarAgendamento_i="matMenu">
                              <button mat-menu-item (click)="routerLink('checkin', realizado.id)">
                                <i class="bi bi-pencil-square ag-pr-10 text-cor-primaria"></i>
                                <span>Editar</span>
                              </button>
                              <button mat-menu-item (click)="atualizarStatusCheckinNaoRealizado(realizado.id)">
                                <i class="bi bi-circle-fill ag-pr-10 text-cor-danger"></i>
                                <span>{{statusCheckin.naoRealizado}}</span>
                              </button>
                              <!-- <button mat-menu-item (click)="deletarAgendamento(realizado.id)">
                                <i class="bi bi-trash3 ag-pr-10 text-cor-danger"></i>
                                <span>Deletar</span>
                              </button> -->
                            </mat-menu>
                            <i class="bi bi-chevron-expand" title="expand" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_' + realizado.id" aria-expanded="false" [attr.aria-controls]="'collapse_' + realizado.id"></i>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- Motivo Não Realizado Modal -->
<div class="modal" id="naoRealizadoModal" #naoRealizadoModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0"><i class="bi bi-calendar-event"></i> Observação</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" (click)="resetarNaoRealizado()" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" style="padding: 5px !important;">

        <div class="w-100 mb-0 mt-2" style="text-align: left; ">

          <div class="row p-0 m-0">
            <!-- servico.nome -->
            <div class="col-lg-12">
              <app-ag-textarea-field
                [(ngModel)]="comentario.observacao.valor"
                [label]="comentario.observacao.title"
                [tooltip]="comentario.observacao.info"
                [value]="comentario.observacao.valor"
                [isInvalid]="comentario.observacao.status?.invalid"
                [AgStatus]="comentario.observacao.status"
                [id]="'nao_realizado_observacao'"
                AgPlaceholder="{{comentario.observacao.placeholder}}"
                AgInputType="{{comentario.observacao.inputType}}"
                AgMaxlength="{{comentario.observacao.maxlength}}"
                AgInputMode="{{comentario.observacao.inputMode}}"
                (ngModelChange)="comentario.mascara(comentario.observacao.mascara, 'observacao')"
                (change)="comentario.validarInput('observacao')"
                >
              </app-ag-textarea-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="resetarNaoRealizado()">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="naoRealizado(naoRealizadoObj.id)">Confirmar</button>
      </div>

    </div>
  </div>
</div>

