
<div class="pb-4 min-width">

  <div class="container text-center p-0">

    <!-- 👇 Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 mb-2 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao">
                <i class="bi bi-grid position-relative"></i> Dashboard
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Agendas
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- Breadcrumb 👆-->

    <!-- 👇 Nova Agenda -->
    <div class="row p-0 m-0 align-items-start">
      <div class=" col-lg-4 mb-3" style=" cursor: pointer; ">
        <button (click)="routerLink('agendas/adicionar')" type="button" class="btn btn-sm p-0 m-0 btn-principal-primary">
          <span class="btn-principal-primary-nome">
            Nova Agenda
          </span>
          <span class="btn-principal-primary-icon">
            <i class="bi bi-journal-plus"></i>
          </span>
        </button>
      </div>
    </div>
    <!-- Nova Agenda 👆-->

    <!-- 👇 Informações / Configurações / Filtro -->
    <div class="row p-0 m-0 mb-3">
      <div class="min-width col-12">
        <div class="p-0 m-0 box-gen-card ">

          <!-- Informações / Configurações -->
          <div class="row p-0 m-0">

            <!-- Informações -->
            <div class="col-lg-6 col-md-6 col-sm-12 p-0 m-0">
              <h3 class="p-0 m-0 text-cor-padrao text-start w-100 box-gen-card-h3">
                <span *ngIf="agendas.length == 0">
                  <span class="box-gen-card-h3-span"></span> Nenhuma agenda disponível
                </span>
                <span *ngIf="agendas.length > 0">
                  <span class="box-gen-card-h3-span">{{agendas.length}}</span> {{agendas.length == 1 ? "Agenda disponível" : "Agendas disponíveis"}}
                </span>
              </h3>
            </div>

            <!-- Configurações -->
            <div class="col-lg-6 col-md-6 col-sm-12 p-0 m-0">
              <div class="d-flex justify-content-between align-items-center flex-nowrap">
                <button type="button" class="btn btn-outline-primary mb-1 mt-1 ag-mr-5" (click)="reload()" style=" background: transparent; ">
                  Atualizar <i class="bi bi-arrow-clockwise"></i>
                </button>

                <button mat-icon-button [matMenuTriggerFor]="modoButton" aria-label="modo" class="btn btn-outline-primary mb-1 mt-1" style=" background: transparent; " [title]="modoView == true ? 'Tabela' : 'Card'">
                  {{modoView == true ? 'Tabela' : 'Card'}}
                  <i *ngIf="modoView" class="bi bi-table ag-pl-10"></i>
                  <i *ngIf="!modoView" class="bi bi-card-text ag-pl-10"></i>
                </button>
                <mat-menu #modoButton="matMenu">
                  <button mat-menu-item (click)="modoTabela()">
                    <i class="bi bi-table ag-pr-10"></i>
                    <span>Tabela</span>
                  </button>
                  <button mat-menu-item (click)="modoCard()">
                    <i class="bi bi-card-text ag-pr-10"></i>
                    <span>Card</span>
                  </button>
                </mat-menu>

                <button [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_'" aria-expanded="false" [attr.aria-controls]="'collapse_'" class="btn btn-outline-primary mb-1 mt-1" style=" background: transparent; ">
                  Filtro <i class="bi bi-search" title="expand" ></i>
                </button>

              </div>
            </div>
          </div>

          <!-- Filtro -->
          <div [id]="'collapse_'" class="accordion-collapse collapse col-lg-12 pt-2">
            <div class="p-0 m-0" >
              <div *ngIf="agendas.length > 0" style="position: relative;">

                <div class="form-floating w-100 ">
                  <input type="text" class="form-control input-text-padrao m-0 search-box-result input-text-padrao" id="filtrarAgendaSearch" [(ngModel)]="pesquisa" (keydown)="searchAgenda()" (keyup)="searchAgenda()" (keypress)="searchAgenda()" placeholder="&nbsp;">
                  <label for="filtrarAgendaSearch" class="label-input-text-padrao ag-pt-18 ag-pb-18 ag-pl-12 ag-pr-12" >Filtrar agenda ...</label>
                </div>

                <i *ngIf="pesquisa != ''" class="bi bi-x-circle-fill text-secondary reset-search-usr" (click)="resetSearch()"></i>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Informações / Configurações / Filtro 👆 -->

    <!-- 👇 Modo Card -->
    <div class="row p-0 m-0" *ngIf="!modoView">
      <div class="min-width col-lg-12 pb-5 pt-1">

        <!-- Loading -->
        <div class="row" *ngIf="agendas.length <= 0 && loading && modoViewNum == 12">
          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" >
            <div class="p-0 m-0 pulsate" >
            </div>
          </div>
        </div>


        <!-- Card -->
        <div class="row" *ngIf="agendas.length > 0 && !loading">

          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" *ngFor="let agenda of displayedAgendas | searchAgenda: pesquisa; let i = index">
            <div class="card card-padrao-list p-0 m-0">
              <div class="card-body">
                <div class="row card-body-row" >

                  <!-- Visivel inicialmente -->
                  <div class="col-lg-4 mb-1 mt-1">
                    <div class="card-body-row-div">
                      <div class="card-padrao-list-img" [style]="{ 'background-image': 'url(' + agenda.linkImagem.valor + ')'}" >
                        <div class="card-padrao-list-img-sombra"></div>
                      </div>
                      <div class="d-flex flex-column justify-content-center">
                        <div class="mb-1 d-flex">
                          <span class="card-padrao-list-img-title" [innerHTML]="agenda.nome.valor | filtroDestaque: pesquisa">
                            <!-- {{agenda.nome.valor}} -->
                          </span>
                        </div>
                        <div class="d-flex justify-content-start flex-row align-items-center ">
                          <div class="card-body-time p-0 m-0 ag-pl-15">
                            <span>
                              <i class="bi bi-clock ag-pr-5"></i>
                              <span [innerHTML]="agenda.agenda_inicio.valor | filtroDestaque: pesquisa"></span>
                              <!-- {{agenda.agenda_inicio.valor}} -->
                            </span>
                            <span>
                              <i class="bi bi-clock-history ag-pr-5"></i>
                              <span [innerHTML]="agenda.agenda_fim.valor | filtroDestaque: pesquisa"></span>
                              <!-- {{agenda.agenda_fim.valor}} -->
                            </span>

                          </div>
                        </div>
                        <div class="d-flex justify-content-start flex-row align-items-center">
                          <div class="card-body-time p-0 m-0 ag-pl-15">
                            <span>
                              <i class="bi bi-circle-fill text-cor-sucesso ag-pr-5" *ngIf="agenda.status.valor"></i>
                              <i class="bi bi-circle-fill text-cor-danger ag-pr-5" *ngIf="!agenda.status.valor"></i>
                              {{agenda.status.valor ? "Ativa" : "Desativada"}}
                            </span>
                            <span [innerHTML]="agenda.agenda_fim.valor | diferencaEntreDatas"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 mb-1 mt-1">
                    <div class="card-padrao-list-box">
                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center w-100">
                        <span class="m-0 p-0 ag-mr-10 text-start text-truncate">
                          {{agenda.duracaoAtendimento.title}}
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="agenda.duracaoAtendimento.valor | filtroDestaque: pesquisa">
                          <!-- {{agenda.duracaoAtendimento.valor}} -->
                        </span>
                      </div>

                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center w-100">
                        <span class="m-0 p-0 ag-mr-10 text-start text-truncate">{{agenda.antecedenciaMaximaDias.title}}</span>
                        <span class="card-padrao-list-box-bg-value" [innerHTML]="agenda.antecedenciaMaximaDias.valor | filtroDestaque: pesquisa"></span>
                      </div>

                      <div class="d-flex justify-content-start flex-row align-items-center w-100">
                        <span class="m-0 p-0 ag-mr-10 text-start text-truncate">{{agenda.antecedenciaMinimaHoras.title}}</span>
                        <span class="card-padrao-list-box-bg-value" [innerHTML]="agenda.antecedenciaMinimaHoras.valor | filtroDestaque: pesquisa"></span>
                      </div>

                    </div>
                  </div>

                  <div class="col-lg-4 mb-1 mt-1">

                      <!-- <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start">{{agenda.bloquearFeriadosNacionais.title}}</span>
                        <span>
                          <i *ngIf="agenda.bloquearFeriadosNacionais.valor" class="bi bi-check-circle-fill card-padrao-list-sucesso"></i>
                          <i *ngIf="!agenda.bloquearFeriadosNacionais.valor" class="bi bi-x-circle-fill card-padrao-list-danger"></i>
                        </span>
                      </div>

                      <div class="mb-2 d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start">{{agenda.bloquearFeriadosEstaduais.title}}</span>
                        <span>
                          <i *ngIf="agenda.bloquearFeriadosEstaduais.valor" class="bi bi-check-circle-fill card-padrao-list-sucesso"></i>
                          <i *ngIf="!agenda.bloquearFeriadosEstaduais.valor" class="bi bi-x-circle-fill card-padrao-list-danger"></i>
                        </span>
                      </div> -->

                      <div class="d-flex justify-content-start flex-row align-items-center">
                        <!-- <span class="m-0 p-0 ag-mr-10 text-start">{{agenda.link.title}}</span> -->
                        <div style=" display: flex; justify-content: space-between; align-items: center; flex-direction: row; width: 100%; ">

                          <button type="button" class="btn btn-outline-secondary m-0 p-0" (click)="openModalLinkID(agenda.nome.valor, agenda.id)" style="display: flex;justify-content: space-around;padding: .25rem .5rem !important;width: 45%;" >
                            Link da Agenda
                          </button>

                          <button type="button" class="btn btn-outline-secondary m-0 p-0" (click)="openModalLink(agenda.nome.valor, agenda.link.valor)" style="display: flex;justify-content: space-around;padding: .25rem .5rem !important;width: 45%;" >
                            Link Agendamentos
                          </button>

                        </div>

                      </div>

                  </div>

                  <!-- Informações ocultas -->
                  <div [id]="'collapse_'+agenda.id" class="accordion-collapse collapse col-12 mt-2">
                    <div class="row card-body-row-oculta">


                      <div class="col-lg-6 d-flex flex-column justify-content-start flex-wrap">

                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{agenda.confirmacaoAdmin.title}}</span>
                          <span>
                            <i *ngIf="agenda.confirmacaoAdmin.valor" class="bi bi-check-circle-fill card-padrao-list-sucesso"></i>
                            <i *ngIf="!agenda.confirmacaoAdmin.valor" class="bi bi-x-circle-fill card-padrao-list-danger"></i>
                          </span>
                        </div>
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{agenda.notificacoes.title}}</span>
                          <span>
                            <i *ngIf="agenda.notificacoes.valor" class="bi bi-check-circle-fill card-padrao-list-sucesso"></i>
                            <i *ngIf="!agenda.notificacoes.valor" class="bi bi-x-circle-fill card-padrao-list-danger"></i>
                          </span>
                        </div>

                      </div>

                      <div class="col-lg-6 d-flex flex-column justify-content-start flex-wrap">

                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{agenda.bloquearFeriadosNacionais.title}}</span>
                          <span>
                            <i *ngIf="agenda.bloquearFeriadosNacionais.valor" class="bi bi-check-circle-fill card-padrao-list-sucesso"></i>
                            <i *ngIf="!agenda.bloquearFeriadosNacionais.valor" class="bi bi-x-circle-fill card-padrao-list-danger"></i>
                          </span>
                        </div>

                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{agenda.bloquearFeriadosEstaduais.title}}</span>
                          <span>
                            <i *ngIf="agenda.bloquearFeriadosEstaduais.valor" class="bi bi-check-circle-fill card-padrao-list-sucesso"></i>
                            <i *ngIf="!agenda.bloquearFeriadosEstaduais.valor" class="bi bi-x-circle-fill card-padrao-list-danger"></i>
                          </span>
                        </div>



                      </div>



                      <div class="col-lg-12 p-0 m-0">
                         <hr class="hr-padrao ag-mt-5 agr-mb-5">
                      </div>


                      <div class="col-lg-12">

                        <mat-tab-group class="vertical-tabs"  mat-stretch-tabs>
                          <ng-container *ngFor="let horario of agenda.horarios">

                            <ng-container *ngIf="horario.horariosDia.length > 0">

                              <mat-tab [label]="horario.dia.title">
                                <div class="card-padrao-list-box-div-table">

                                  <table id="excel-table" class="table table-responsive table-hover table-sm ag-mb-10" [ngClass]="{'table-dark': !lightMode,'table-light': lightMode}">
                                    <!-- <thead style=" height: 30px; vertical-align: middle; " *ngIf="horario.horariosDia.length > 0">
                                        <tr>
                                          <th class="text-cor-padrao" style="font-size: 13px;text-align: left;padding: 0;margin: 0;padding-left: 10px;">Hora Inicial</th>
                                          <th class="text-cor-padrao" style="font-size: 13px;text-align: left;padding: 0;margin: 0;padding-left: 10px;">Hora Final</th>
                                          <th class="text-cor-padrao" style="font-size: 13px;text-align: left;padding: 0;margin: 0;padding-left: 10px;">Atendimentos Simultâneos</th>
                                        </tr>
                                    </thead> -->
                                    <tbody>
                                      <tr class="text-cor-padrao" style="cursor: pointer;height: 35px;vertical-align: middle;" *ngFor="let horarioDia of horario.horariosDia">
                                        <td>
                                          <div class="d-flex justify-content-start flex-row align-items-center">
                                            <span class="m-0 p-0 ag-mr-10 text-start">Hora Inicial</span>
                                            <span class="card-padrao-list-box-bg-value">{{horarioDia.hora_inicial.valor}}</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="d-flex justify-content-start flex-row align-items-center">
                                            <span class="m-0 p-0 ag-mr-10 text-start">Hora Final</span>
                                            <span class="card-padrao-list-box-bg-value">{{horarioDia.hora_final.valor}}</span>
                                          </div>
                                        </td>
                                        <td>
                                          <div class="d-flex justify-content-start flex-row align-items-center">
                                            <span class="m-0 p-0 ag-mr-10 text-start">Atendimentos Simultâneos</span>
                                            <span class="card-padrao-list-box-bg-value">{{horarioDia.num_pes_horario.valor == "" ? 0 : horarioDia.num_pes_horario.valor}}</span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <div style="display: none;">
                                    <button type="button" class="btn btn-outline-primary w-100" (click)="openCalendario(agenda.id)">
                                      Calendário Completo
                                    </button>
                                  </div>

                                </div>
                              </mat-tab>
                            </ng-container>

                            <!-- <ng-container *ngIf="horario.horariosDia.length == 0">
                              <mat-tab [label]="horario.dia.title">
                                <div style=" background: var(--background); color: var(--text-cor-padrao); margin-bottom: 5px; padding: 5px; text-transform: uppercase; font-size: 12px; ">
                                  Sem horários
                                </div>
                              </mat-tab>
                            </ng-container> -->

                          </ng-container>
                        </mat-tab-group>
                      </div>

                    </div>
                  </div>

                </div>

                <div class="card-padrao-footer-div-menu">
                  <i class="bi bi-three-dots" title="menu" mat-icon-button [matMenuTriggerFor]="editarAgenda_i"></i>
                  <mat-menu #editarAgenda_i="matMenu">
                    <button mat-menu-item (click)="routerLink('agendas/agenda', agenda.id)">
                      <i class="bi bi-pencil-square ag-pr-10 text-cor-primaria"></i>
                      <span>Editar</span>
                    </button>
                    <button mat-menu-item (click)="atualizarStatusAgenda(agenda.id)">
                      <i class="bi bi-dash-circle ag-pr-10" [ngClass]="{'text-cor-danger': agenda.status.valor, 'text-cor-sucesso': !agenda.status.valor}"></i>
                      <span>{{agenda.status.valor ? "Desativar" : "Ativar"}}</span>
                    </button>
                    <button mat-menu-item (click)="deletarAgeda(agenda.id)">
                      <i class="bi bi-trash3 ag-pr-10 text-cor-danger"></i>
                      <span>Deletar</span>
                    </button>
                  </mat-menu>
                  <i class="bi bi-chevron-expand" title="expand" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_' + agenda.id" aria-expanded="false" [attr.aria-controls]="'collapse_' + agenda.id"></i>

                </div>
              </div>
            </div>
          </div>

          <!-- Mensagem do filtro caso não encontre -->
          <div *ngIf="displayedAgendas.length == 0 && pesquisa != ''">
            <h3 class="text-cor-padrao mt-3">Nenhum dado encontrado que corresponda ao filtro <span class="card-padrao-list-span-pesquisa">{{pesquisa}}</span></h3>
          </div>

          <!-- Páginação -->
          <div class="col-12 mt-3">
            <div class="box-gen-card" style="padding: 7px 0px !important;">
              <mat-paginator
                [length]="agendas.length"
                [pageSize]="maxSize"
                [pageSizeOptions]="[5, 10, 25, 50]"
                [showFirstLastButtons]="true"
                (page)="handlePage($event)"
              ></mat-paginator>
            </div>
          </div>

        </div>

      </div>
    </div>
    <!-- Modo Card 👆 -->

    <!-- 👇 Modo Tabela -->
    <div class="row p-0 m-0" *ngIf="modoView">

      <!-- Loading -->
      <div class="min-width col-lg-12 pb-5 pt-1" *ngIf="agendas.length <= 0 && loading">
        <div class="p-0 m-0 pulsate box-gen-card">
        </div>
      </div>

      <div class="min-width col-lg-12 pb-5 pt-1" >
        <div class="p-0 m-0 box-gen-card mb-3" *ngIf="agendas.length > 0 && !loading">

          <!-- Tabela -->
          <div class="w-100 overflow-auto">
            <table id="excel-table" class="mb-1 mt-1 table table-responsive table-hover table-sm" [ngClass]="{'table-dark': !lightMode,'table-light': lightMode}" matSort (matSortChange)="sortData($event)">
              <thead style=" height: 40px; vertical-align: middle; " *ngIf="displayedAgendas.length > 0">
                  <tr>
                    <th class="text-cor-padrao" mat-sort-header="id" style="min-width: 55px;padding-left: 15px;border-right: 1px solid var(--border-cor);">ID</th>
                    <th class="text-cor-padrao" mat-sort-header="status" style="min-width: 55px;border-right: 1px solid var(--border-cor);">Status</th>
                    <th class="text-cor-padrao" mat-sort-header="nome" style="min-width: 200px;border-right: 1px solid var(--border-cor);">Nome</th>
                    <th class="text-cor-padrao" mat-sort-header="confirmacaoAdmin" style="min-width: 100px;border-right: 1px solid var(--border-cor);">Confirmação</th>
                    <th class="text-cor-padrao" mat-sort-header="notificacoes" style="min-width: 100px;border-right: 1px solid var(--border-cor);">Notificações</th>
                    <th class="text-cor-padrao" mat-sort-header="agenda_inicio" style="min-width: 165px;border-right: 1px solid var(--border-cor);">Data Inicial da Agenda</th>
                    <th class="text-cor-padrao" mat-sort-header="agenda_fim" style="min-width: 165px;border-right: 1px solid var(--border-cor);">Data Final da Agenda</th>
                    <th class="text-cor-padrao sticky-col" style=" text-align: center;" >&nbsp;</th>
                  </tr>
              </thead>
              <tbody>
                <tr class="text-cor-padrao ag-table-tr" *ngFor="let agenda of displayedAgendas | searchAgenda: pesquisa">
                  <td class="ag-table-td ag-pl-15" style="border-right: 1px solid var(--border-cor);">
                    <div [innerHTML]="agenda.id | filtroDestaque: pesquisa">
                      <!-- {{agenda.id}} -->
                    </div>
                  </td>
                  <td class="ag-table-td ag-pl-15" style="border-right: 1px solid var(--border-cor);">
                    <div >
                      <i class="bi bi-circle-fill text-cor-sucesso" *ngIf="agenda.status.valor"></i>
                      <i class="bi bi-circle-fill text-cor-danger" *ngIf="!agenda.status.valor"></i>
                    </div>
                  </td>
                  <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                    <div [innerHTML]="agenda.nome.valor | filtroDestaque: pesquisa">
                      <!-- {{agenda.nome.valor}} -->
                    </div>
                  </td>
                  <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                    <div [innerHTML]="agenda.confirmacaoAdmin.valor | filtroDestaque: pesquisa">
                      <!-- {{agenda.confirmacaoAdmin.valor}} -->
                    </div>
                  </td>
                  <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                    <div [innerHTML]="agenda.notificacoes.valor | filtroDestaque: pesquisa">
                      <!-- {{agenda.notificacoes.valor}} -->
                    </div>
                  </td>
                  <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                    <div [innerHTML]="agenda.agenda_inicio.valor | filtroDestaque: pesquisa">
                      <!-- {{agenda.agenda_inicio.valor }} -->
                    </div>
                  </td>
                  <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                    <div [innerHTML]="agenda.agenda_fim.valor | filtroDestaque: pesquisa">
                      <!-- {{agenda.agenda_fim.valor }} -->
                    </div>
                  </td>
                  <td class="ag-table-td sticky-col">
                    <button mat-icon-button [matMenuTriggerFor]="editarAgenda" aria-label="modo" class="btn-outline-primary sticky-col-buttom">
                      <i class="bi bi-three-dots"></i>
                    </button>
                    <mat-menu #editarAgenda="matMenu">
                      <button mat-menu-item (click)="routerLink('agendas/agenda', agenda.id)">
                        <i class="bi bi-pencil-square ag-pr-10 text-cor-primaria"></i>
                        <span>Editar</span>
                      </button>
                      <button mat-menu-item (click)="atualizarStatusAgenda(agenda.id)">
                        <i class="bi bi-dash-circle ag-pr-10" [ngClass]="{'text-cor-danger': agenda.status.valor, 'text-cor-sucesso': !agenda.status.valor}"></i>
                        <span>{{agenda.status.valor ? "Desativar" : "Ativar"}}</span>
                      </button>
                      <button mat-menu-item (click)="deletarAgeda(agenda.id)">
                        <i class="bi bi-trash3 ag-pr-10 text-cor-danger"></i>
                        <span>Deletar</span>
                      </button>
                    </mat-menu>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Mensagem do filtro caso não encontre -->
          <div *ngIf="displayedAgendas.length == 0 && pesquisa != ''">
            <h3 class="text-cor-padrao">Nenhum dado encontrado que corresponda ao filtro <span class="card-padrao-list-span-pesquisa">{{pesquisa}}</span></h3>
          </div>

          <!-- Páginação -->
          <div class="text-right row pagination-box-result" *ngIf="agendas.length > 0">
            <div class="col-12 p-0 m-0">
              <mat-paginator
                [length]="agendas.length"
                [pageSize]="maxSize"
                [pageSizeOptions]="[5, 10, 25, 50]"
                [showFirstLastButtons]="true"
                (page)="handlePage($event)"
              ></mat-paginator>
            </div>
          </div>

        </div>
      </div>

    </div>
    <!-- Modo Tabela 👆 -->

    <!-- 👇 Links Relacionados -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-5 pb-3 text-start">
        <hr class="w-100 hr-padrao">
        <p class="p-0 m-0 text-cor-padrao d-flex align-items-center" style="font-weight: 500;letter-spacing: 0.5px;">Links Relacionados <i class="bi bi-link-45deg text-background-clip" style=" padding-left: 5px; "></i></p>
        <hr class="w-100 hr-padrao">
        <mat-chip-listbox aria-label="Geradores">
          <div *ngFor="let card of linksAgenda">
            <mat-chip (click)="routerLink(card.rota!.toString())" *ngIf="card.tipo != 'agenda'" class="mat-chip-val" data-bs-toggle="tooltip" [title]="card.subTitle">
              {{card.title}}
            </mat-chip>
          </div>
        </mat-chip-listbox>
      </div>
    </div>
    <!-- Links Relacionados 👆 -->

  </div>

</div>

<!-- Calendario Modal -->
<div class="modal" id="calendarioModal" #calendarioModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0"><i class="bi bi-calendar-event"></i> Calendário</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body" style=" padding-top: 0; margin-top: 1rem; ">
        <div class="calendar">
          <full-calendar
            #fullcalendar
            [options]="calendarOptions"
            [deepChangeDetection]="true"
          ></full-calendar>
        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
      </div>

    </div>
  </div>
</div>
