import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { Doca } from 'src/class/doca';
import { DataService } from '../data.service';
import { ToastService } from '../toast.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { LocalhostService } from '../localhost.service';
import { ContaService } from './conta.service';
import { AuthService } from '../auth.service';
import { NotificacaoSistemaService } from './notificacao-sistema.service';

@Injectable({
  providedIn: 'root'
})
export class DocaService {

  private docas: Doca[] = [];
  private docasSubject = new BehaviorSubject<Doca[]>(this.docas);

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private notificacaoService: NotificacaoSistemaService,
    private toast: ToastService,
    private conta: ContaService,
    private userService: AuthService
  ) {
    this.docas = [];
    // this.getDoca();
  }



  async criarDoca(doca: Doca, empresaSigla: any) {
    // console.log("criarDoca", doca);
    doca.descricao.status = null;
    doca.nome.status = null;

    try {
      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);

      // console.log('Dados da conta: criarDoca', conta);

      const response = await this.localhostService.postDadosId(`${conta.id}_docas`, doca);
      // console.log('Doca criado com sucesso:', response);

      // Atualize a lista local de agendas
      this.docas.push(doca);
      this.docasSubject.next(this.docas);

      // Notifique o usuário sobre o sucesso
      this.showSuccess('Doca criado com sucesso');

      const usr = await this.userService.carregarUsuario(empresaSigla)

      this.notificacaoService.criarNotificacao(
        empresaSigla,               // 1 - empresaSigla: any,
        `${doca.nome.valor}`,       // 2 - titulo: string,
        `Doca criado.`,             // 3 - texto: string,
        'Listar Docas',             // 4 - rotaSemParamNome: string,
        'docas',                    // 5 - rotaSemParamListar: string,
        'Editar Doca',              // 6 - rotaComParamNome: string = "",
        `docas/doca`,               // 7 - rotaComParamCaminho: string = "",
        doca.id?.toString(),        // 8 - rotaComParamParam: string = "",
        '',                         // 9 - linkExternoNome: string = "",
        '',                         // 10 - linkExternoURL: string = "",
        doca.id!.toString(),        // 11 - idReferencia: string,
        `Doca`,                     // 12 - tipo: string,
        `${usr.id}`,                // 13 - idResponsavel: string,
        `${usr.displayName.valor}`  // 14 - nomeResponsavel: string
      )

      return true;
    } catch (error) {
      // console.error('Erro ao criar doca:', error);
      // Trate o erro conforme necessário
      return false;
    }
  }

  getAllDoca() {
    return this.docasSubject.asObservable().pipe(
      map(docas => docas)
    );
  }

  async getDoca(empresaSigla: any) {

    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: getDoca', conta);

      this.localhostService.getDados(`${conta.id}_docas`).subscribe(
        (docaFromServer: any[]) => {
          if (docaFromServer) {
            // Mapeia os dados para a estrutura desejada
            const docaArray: Doca[] = docaFromServer.map(doca => plainToClass(Doca, doca));

            // console.log(docaArray);

            this.docas = docaArray;
            this.docasSubject.next(this.docas);
            // this.updateLocalStorage();
          } else {
            this.docas = [];
          }
        },
        (error) => {
          // console.error('Erro ao obter docas:', error);
        }
      );
    },
    (error: any) => {
      // console.error('Erro ao obter docas:', error);
      // Trate o erro conforme necessário
    }).catch((error) => {
      // Trate o erro
      // console.log('Erro ao obter dados da conta:', error);
    });


  }

  private updateLocalStorage() {
    localStorage.setItem("Doca", JSON.stringify(this.docas));
    DataService.set("Doca", this.docas);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
