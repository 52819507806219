<div>
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title p-0 m-0">Atenção</h4>
    <span id="btn-close-x-foto" class="text-cor-padrao" (click)="modalRef.hide()" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
      <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
    </span>
  </div>
  <!-- Modal body -->
  <div class="modal-body p-0 m-0">
    <div style="padding: 0px 15px;">
      <h4 class="modal-title pt-3 pb-2 p-0 m-0 text-start">{{titulo}}</h4>
    </div>

    <div class="ag-pl-15 ag-pr-15">

      <!-- <div class="text-cor-padrao ag-pb-15" [innerHtml]="mensagem">
      </div> -->

      <div class="col-lg-12 reset-padding-mobile pb-2">

        <!-- Loading -->
        <div *ngIf="loading || loading_id" title="Loading...">
          <div style=" display: flex; align-items: center; ">
            <label class="form-label label-input-text-padrao" style="display: flex; align-items: center; flex-wrap: nowrap;margin-bottom: 5px;">
              Selecione uma doca disponível
            </label>
          </div>
          <div class="d-flex flex-column align-items-start flex-wrap">
            <div class="p-0 m-0 pulsate-input" >
            </div>
          </div>
        </div>

        <app-ag-ng-select *ngIf="!loading && !loading_id"
             [(ngModel)]="docaSelecionado"
             label="Selecione uma doca disponível"
             [id]="'docaDisponivel'"
             tooltip="Selecione uma doca disponível"
             [AgPlaceholder]="'Selecione um doca...'"
             [AgMultiple]="false"
             [AgCloseOnSelect]="true"
             [AgOptions]="docas"
             AgSelectType="doca"
             [AgAppendTo]="'#honorato'"
             [AgCheckinDoca]="statusByDoca">
        </app-ag-ng-select>

      </div>

    </div>
  </div>
  <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
    <button type="button" class="btn btn-secondary" (click)="confirmAction(false)">Não</button>
    <button type="button" class="btn btn-primary" (click)="docaValor(docaSelecionado)" [disabled]="!docaSelecionado">Sim</button>
  </div>
</div>
