<div class="pb-4">

  <div class="container text-center min-width p-0">

    <!-- Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item">
              <a (click)="routerLink('dias-nao-agendamento')" class="text-padrao">Dias não agendamento</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Adicionar</li>
          </ol>
        </nav>
      </div>

      <div class="col-12 text-start">
        <button type="button" class="btn btn-outline-secondary mb-2" (click)="routerLink('dias-nao-agendamento')" style="display: flex; justify-content: space-around;">
          <i class="bi bi-arrow-left"></i>
          Voltar
        </button>
      </div>
    </div>


    <!-- Dia -->
    <div class="row p-0 m-0">
      <div class="row p-0 m-0">
        <div class="min-width col-lg mb-3">
          <div class="box-gen-card">
            <div class="row p-0 m-0">
              <div class="col-lg-12 reset-padding-mobile">
                <h3 class="p-0 m-0 text-cor-padrao text-start w-100">
                  Novo grupo de dias não agendamento
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Dia Dados -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card">
          <div class="row p-0 m-0">
            <!-- dia.nome -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-input-field class="w-100"
                  [(ngModel)]="dia.nome.valor"
                  [label]="dia.nome.title"
                  [tooltip]="dia.nome.info"
                  [value]="dia.nome.valor"
                  [isInvalid]="dia.nome.status?.invalid"
                  [AgStatus]="dia.nome.status"
                  [id]="'dia_nome'"
                  AgPlaceholder="{{dia.nome.placeholder}}"
                  AgInputType="{{dia.nome.inputType}}"
                  AgMaxlength="{{dia.nome.maxlength}}"
                  AgInputMode="{{dia.nome.inputMode}}"
                  (ngModelChange)="dia.mascara(dia.nome.mascara, 'nome');"
                  (change)="dia.validarInput('nome');"
                  >
                </app-ag-input-field>
              </div>
            </div>

            <!-- dia.descricao -->
            <div class="col-lg-12 reset-padding-mobile">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-textarea-field class="w-100"
                  [(ngModel)]="dia.descricao.valor"
                  [label]="dia.descricao.title"
                  [tooltip]="dia.descricao.info"
                  [value]="dia.descricao.valor"
                  [isInvalid]="dia.descricao.status?.invalid"
                  [AgStatus]="dia.descricao.status"
                  [id]="'dia_descricao'"
                  AgPlaceholder="{{dia.descricao.placeholder}}"
                  AgInputType="{{dia.descricao.inputType}}"
                  AgMaxlength="{{dia.descricao.maxlength}}"
                  AgInputMode="{{dia.descricao.inputMode}}"
                  (ngModelChange)="dia.mascara(dia.descricao.mascara, 'descricao');"
                  (change)="dia.validarInput('descricao');"
                  >
                </app-ag-textarea-field>
              </div>
            </div>

            <!-- dia.status -->
            <div class="col-lg-12 reset-padding-mobile">
              <app-ag-mat-checkbox
                [(ngModel)]="dia.status.valor"
                [label]="dia.status.title"
                [tooltip]="dia.status.info"
                [value]="dia.status.valor"
                [id]="'dia_status'"
                >
              </app-ag-mat-checkbox>
            </div>

          </div>
        </div>
      </div>

      <!-- Dia Dias -->
      <div class="row p-0 m-0">
        <div class="row p-0 m-0">
          <div class="min-width col-lg mb-3">
            <div class="box-gen-card">
              <div class="row p-0 m-0">
                <div class="col-lg-12 reset-padding-mobile">
                  <div class="d-flex align-items-center justify-content-between">
                    <div>
                      <h3 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Dias </h3>
                      <p class="p-0 m-0 ag-pr-3 text-cor-padrao text-start w-100">Defina os dias em que o agendamento estará bloqueado.</p>
                    </div>
                    <div (click)="openNovoDiaStatus()" class="ag-icon-click">
                      <i class="bi bi-plus-circle-fill"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="existeValores()" style="height: 10px;">

              </div>

              <div class="row d-flex flex-wrap flex-column justify-content-center p-0 m-0 ">
                <div *ngFor="let d of dia.dias" class="row col-12 m-0 reset-padding-mobile">
                  <div class="row col-lg-12 p-2 m-0 ag-mt-5 text-cor-padrao" style="border: var(--border-padrao);position: relative;border-radius: 5px;">

                    <div class="col-lg-2 text-left d-flex flex-column flex-wrap justify-content-center align-items-start pb-2 pt-2">

                      <p class="p-0 m-0">
                        Dia:
                      </p>
                      <p class="p-0 m-0">
                        <span style="font-weight: bold;">{{d.dia.valor}}</span>
                      </p>

                    </div>

                    <div class="col-lg-6 text-left d-flex flex-column flex-wrap justify-content-center align-items-start pb-2 pt-2">
                      <p class="p-0 m-0">
                        Descrição:
                      </p>
                      <p class="p-0 m-0" [title]="d.descricao.valor" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 100%;">
                        <span style="font-weight: bold;">{{d.descricao.valor != '' ? d.descricao.valor : '-'}}</span>
                      </p>
                    </div>

                    <div class="col-lg-2 text-left d-flex flex-column flex-wrap justify-content-center align-items-start pb-2 pt-2" style=" text-align: left; ">
                      <p class="p-0 m-0">
                        Status:
                      </p>
                      <p class="p-0 m-0">
                        <span style="font-weight: bold;">{{d.status.valor ? 'Ativado' : 'Desativado'}}</span>
                      </p>
                    </div>

                    <div class="col-lg-2 text-left d-flex flex-row flex-wrap justify-content-between align-items-center pb-2 pt-2">

                      <button type="button" class="btn btn-outline-primary-s" (click)="openEditarDia(d)"  style="display: flex;justify-content: space-around;align-items: center;" >
                        <i class="bi bi-pencil-fill"></i> Editar
                      </button>

                      <button type="button" class="btn btn-outline-danger" (click)="deletarDia(d.id, d.dia.valor)" style="display: flex;justify-content: space-around;align-items: center;" >
                        <i class="bi bi-trash3-fill"></i> Excluir
                      </button>

                    </div>

                  </div>
                </div>
              </div>
              <!--  -->
            </div>
          </div>
        </div>
      </div>

      <!-- GrupoAgenda -->
      <div class="row p-0 m-0">
        <div class="row p-0 m-0">
          <div class="min-width col-lg mb-3">
            <div class="box-gen-card">
              <div class="row p-0 m-0">
                <div class="col-lg-12 reset-padding-mobile">
                  <h3 class="p-0 m-0 text-cor-padrao text-start w-100 mt-2 mb-2" style=" font-weight: 500; ">
                    Agendas Disponíveis
                  </h3>
                  <p class="p-0 m-0 ag-pr-3 text-cor-padrao text-start w-100">Selecione as agendas onde os dias acima estarão bloqueados para agendamento.</p>
                </div>

                <div class="col-lg-12 reset-padding-mobile">

                  <!-- <h3 class="p-0 m-0 text-cor-padrao text-start w-100 ag-pl-10">Recursos Disponíveis</h3> -->
                  <mat-selection-list #agendasSelecionadas>
                    <mat-list-option *ngFor="let agenda of agendas" [value]="agenda" class="p-0 m-0" style="border-top: var(--border-padrao)">
                      {{agenda.nome.valor}} - <span style=" font-size: 13px; font-style: italic; ">{{agenda.subNome.valor}}</span>
                      <!-- <div style="display: flex;flex-diagendation: column;flex-wrap: wrap;align-items: flex-start;justify-content: center;">
                        <span>{{agenda.descricao.valor}}</span>
                      </div> -->
                    </mat-list-option>
                  </mat-selection-list>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Ação Criar / Cancelar -->
      <div class="min-width col-lg-12 pb-3">
        <div class="p-0 m-0 box-gen-card" style=" background: transparent; box-shadow: none; border: none; ">

          <!-- <div class="w-100 mb-3" style="text-align: left; display: flex; align-items: center; ">
            <mat-checkbox [(ngModel)]="agenda.status.valor" [color]="'radial-primary'">
              {{agenda.status.title}}
            </mat-checkbox>
            <span mat-raised-button #status="matTooltip" [matTooltip]="agenda.status.info" matTooltipPosition="right" (click)="status.show()" class="tooltip-input-icon">
              <i class="bi bi-info-circle-fill"></i>
            </span>
          </div> -->

          <div class="mt-2 d-flex justify-content-between align-items-center flex-nowrap">
            <button type="button" class="btn btn-primary w-50" style="color: #fff;margin-right: 5px;padding: 15px !important;" (click)="criarDiasNaoAgendamento()">Criar</button>
            <button type="button" class="btn btn-outline-danger w-50" (click)="routerLink('dias-nao-agendamento')" style="padding: 15px !important;">Cancelar</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>

<!-- alert Dia Modal -->
<div class="modal" id="alertDiaModal" #alertDiaModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Atenção</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >
        <div style="padding: 0px 15px;">
          <h4 class="modal-title pt-3 pb-2 p-0 m-0 text-start">Alguns campos precisam ser preenchidos</h4>
        </div>

        <div style=" padding: 15px; ">

          <div *ngIf="dia.nome.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ dia.nome.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ dia.nome.info }}</p>
          </div>

          <div *ngIf="dia.descricao.status.invalid" class="border-danger gen-card">
            <p class="text-cor-padrao gen-card-title">{{ dia.descricao.title }}</p>
            <p class="text-cor-padrao gen-card-info">{{ dia.descricao.info }}</p>
          </div>

        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
        <!-- <button type="button" class="btn btn-danger"  style="color: #fff;">Deletar</button> -->
      </div>

    </div>
  </div>
</div>

<!-- Adicionar Dia -->
<div class="modal" id="novoDiaStatus" #novoDiaStatus data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Adicionar dia</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" (click)="closeModal(novoDiaStatus)" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >

        <div class="ag-pt-10 ag-pl-5 ag-pr-5 mb-3">
          <div class="row p-0 m-0">

            <div class="col-lg-12">
              <app-ag-input-field-datepicker
                [(ngModel)]="diasInformados.dia.valor"
                [label]="diasInformados.dia.title"
                [tooltip]="diasInformados.dia.info"
                [value]="diasInformados.dia.valor"
                [isInvalid]="diasInformados.dia.status?.invalid"
                [AgStatus]="diasInformados.dia.status"
                [id]="'diasInformados_dia'"
                AgPlaceholder="{{diasInformados.dia.placeholder}}"
                AgInputType="{{diasInformados.dia.inputType}}"
                AgMaxlength="{{diasInformados.dia.maxlength}}"
                AgInputMode="{{diasInformados.dia.inputMode}}"
                (ngModelChange)="diasInformados.mascara(diasInformados.dia.mascara, 'dia');"
                (change)="diasInformados.validarInput('dia');"
                >
              </app-ag-input-field-datepicker>
            </div>

            <!-- dia.descricao -->
            <div class="col-lg-12">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-textarea-field class="w-100"
                  [(ngModel)]="diasInformados.descricao.valor"
                  [label]="diasInformados.descricao.title"
                  [tooltip]="diasInformados.descricao.info"
                  [value]="diasInformados.descricao.valor"
                  [isInvalid]="diasInformados.descricao.status?.invalid"
                  [AgStatus]="diasInformados.descricao.status"
                  [id]="'diasInformados_descricao'"
                  AgPlaceholder="{{diasInformados.descricao.placeholder}}"
                  AgInputType="{{diasInformados.descricao.inputType}}"
                  AgMaxlength="{{diasInformados.descricao.maxlength}}"
                  AgInputMode="{{diasInformados.descricao.inputMode}}"
                  (ngModelChange)="diasInformados.mascara(diasInformados.descricao.mascara, 'descricao');"
                  (change)="diasInformados.validarInput('descricao');"
                  >
                </app-ag-textarea-field>
              </div>
            </div>

            <div class="col-lg-12">
              <app-ag-mat-checkbox
                [(ngModel)]="diasInformados.status.valor"
                [label]="diasInformados.status.title"
                [tooltip]="diasInformados.status.info"
                [value]="diasInformados.status.valor"
                [id]="'diasInformados_status'"
                >
              </app-ag-mat-checkbox>
            </div>

          </div>

        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" (click)="closeModal(novoDiaStatus)">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="adicionarDia()">Adicionar dia</button>
      </div>

    </div>
  </div>
</div>

<!-- Editar Dia -->
<div class="modal" id="editarNovoDiaStatus" #editarNovoDiaStatus data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0">Adicionar dia</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" (click)="closeModal(editarNovoDiaStatus)" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" >

        <div class="ag-pt-10 ag-pl-5 ag-pr-5 mb-3">
          <div class="row p-0 m-0">

            <div class="col-lg-12">
              <app-ag-input-field-datepicker
                [(ngModel)]="diasInformados.dia.valor"
                [label]="diasInformados.dia.title"
                [tooltip]="diasInformados.dia.info"
                [value]="diasInformados.dia.valor"
                [isInvalid]="diasInformados.dia.status?.invalid"
                [AgStatus]="diasInformados.dia.status"
                [id]="'diasInformados_dia'"
                AgPlaceholder="{{diasInformados.dia.placeholder}}"
                AgInputType="{{diasInformados.dia.inputType}}"
                AgMaxlength="{{diasInformados.dia.maxlength}}"
                AgInputMode="{{diasInformados.dia.inputMode}}"
                (ngModelChange)="diasInformados.mascara(diasInformados.dia.mascara, 'dia');"
                (change)="diasInformados.validarInput('dia');"
                >
              </app-ag-input-field-datepicker>
            </div>

            <!-- dia.descricao -->
            <div class="col-lg-12">
              <div style="display: flex;flex-direction: row;align-items: center;flex-wrap: nowrap;justify-content: space-between;margin-top: 5px;">
                <app-ag-textarea-field class="w-100"
                  [(ngModel)]="diasInformados.descricao.valor"
                  [label]="diasInformados.descricao.title"
                  [tooltip]="diasInformados.descricao.info"
                  [value]="diasInformados.descricao.valor"
                  [isInvalid]="diasInformados.descricao.status?.invalid"
                  [AgStatus]="diasInformados.descricao.status"
                  [id]="'diasInformados_descricao'"
                  AgPlaceholder="{{diasInformados.descricao.placeholder}}"
                  AgInputType="{{diasInformados.descricao.inputType}}"
                  AgMaxlength="{{diasInformados.descricao.maxlength}}"
                  AgInputMode="{{diasInformados.descricao.inputMode}}"
                  (ngModelChange)="diasInformados.mascara(diasInformados.descricao.mascara, 'descricao');"
                  (change)="diasInformados.validarInput('descricao');"
                  >
                </app-ag-textarea-field>
              </div>
            </div>

            <div class="col-lg-12">
              <app-ag-mat-checkbox
                [(ngModel)]="diasInformados.status.valor"
                [label]="diasInformados.status.title"
                [tooltip]="diasInformados.status.info"
                [value]="diasInformados.status.valor"
                [id]="'diasInformados_status'"
                >
              </app-ag-mat-checkbox>
            </div>

          </div>

        </div>

      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" (click)="closeModal(editarNovoDiaStatus)">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="salvarDia()">Salvar dia</button>
      </div>

    </div>
  </div>
</div>
