import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Doca } from 'src/class/doca';

@Pipe({
  name: 'statusDoca',
  pure: false,
})
export class StatusDocaPipe implements PipeTransform {
  constructor(private cdr: ChangeDetectorRef) {}

  transform(doca: Doca, statusByDoca: any) {
    // console.log(statusByDoca)
    // console.log(statusByDoca && statusByDoca[doca.id] !== undefined ? statusByDoca[doca.id] : true);
    return statusByDoca && statusByDoca[doca.id] !== undefined ? statusByDoca[doca.id] : true;
  }
}
