export class Acesso {
  recursos: string[];
  agendas: string[];

  constructor() {
    this.recursos = [];
    this.agendas = [];
  }

  // Métodos para manipular as listas de recursos e agenda
  adicionarRecurso(recurso: string) {
    this.recursos.push(recurso);
  }

  adicionarAgenda(agenda: string) {
    this.agendas.push(agenda);
  }

  getRecurso(index: number) {
    return this.recursos[index];
  }

  getAgenda(index: number) {
    return this.agendas[index];
  }

  lengthRecursos() {
    return this.recursos.length;
  }

  lengthAgenda() {
    return this.agendas.length;
  }

  // Função para verificar se um recurso existe
  recursoExiste(nome: string): boolean {
    return this.recursos.includes(nome);
  }

  // Função para verificar se uma agenda existe
  public agendaExiste(nome: string): boolean {
    return this.agendas.includes(nome);
  }
}





// // Criando uma instância de Acesso
// const acesso = new Acesso();

// // Adicionando dados manualmente
// acesso.adicionarRecurso("Nome do recurso 1");
// acesso.adicionarRecurso("Nome do recurso 2");
// acesso.adicionarRecurso("Nome do recurso 3");

// acesso.adicionarAgenda("Nome da agenda 1");
// acesso.adicionarAgenda("Nome da agenda 2");
// acesso.adicionarAgenda("Nome da agenda 3");

// // Verificando se um nome existe na lista de recursos
// console.log(acesso.recursoExiste("Nome do recurso 1")); // Saída: true
// console.log(acesso.recursoExiste("Nome do recurso 4")); // Saída: false

// // Verificando se um nome existe na lista de agendas
// console.log(acesso.agendaExiste("Nome da agenda 2")); // Saída: true
// console.log(acesso.agendaExiste("Nome da agenda 4")); // Saída: false
