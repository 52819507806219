import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Doca } from 'src/class/doca';
import { newUsuario } from 'src/class/usuario';
import { AuthService } from 'src/services/auth.service';
import { CepService } from 'src/services/cep.service';
import { ContaService } from 'src/services/component/conta.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';
import { DocaService } from 'src/services/component/doca.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { DataService } from 'src/services/data.service';
import { LocalhostService } from 'src/services/localhost.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { TranslateWordService } from 'src/services/translate-word.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { ValidatorService } from 'src/services/validator.service';

declare var bootstrap: any;

@Component({
  selector: 'app-editar-doca',
  templateUrl: './editar-doca.component.html',
  styleUrls: ['./editar-doca.component.css']
})
export class EditarDocaComponent {
  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('alertDocaModal', { static: false }) alertDocaModal!: ElementRef;

  id: any;
  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj: any = [];

  usr_new = new newUsuario;

  doca: Doca = new Doca();
  docas: Doca[] = [];
  empresaSigla: any | undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private usuarios: UsuariosService,
    private toast: ToastService,
    private validatorService: ValidatorService,
    private cepService: CepService,
    private translateWord: TranslateWordService,
    private contadorIndexService: ContadorIndexService,
    private docaService: DocaService,
    private localhostService: LocalhostService,
    private siglaService: SiglaService,
    private conta: ContaService,
    private userService: AuthService,
    private notificacaoService: NotificacaoSistemaService
  ){
    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
        });
      // }
    // });
  }

  async ngOnInit() {
    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id)
    // Agora você pode usar o ID como quiser

    this.docaService.getAllDoca()
    .pipe(takeUntil(this.destroy$))
    .subscribe(doca => {
      this.docas = doca;

      for (let index = 0; index < this.docas.length; index++) {
        const tpd = this.docas[index];
        if(tpd.id == this.id){
          Object.assign(this.doca, tpd);
        }
      }

      if(this.doca.id == null){
        this.router.navigate([`${this.empresaSigla}/docas`]);
      }
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  async salvarDoca(){
    this.doca.nome.status = this.validatorService.criarValidacao(this.doca.nome.valor, this.doca.nome.validacoesCustomizadas);
    this.doca.descricao.status = this.validatorService.criarValidacao(this.doca.descricao.valor, this.doca.descricao.validacoesCustomizadas);

    if(this.doca.nome.status.invalid ||
      this.doca.descricao.status.invalid
    ){
      this.abrirModal(this.alertDocaModal.nativeElement);
    }else{

      // console.log(this.doca)

      // Obtenha o array existente do DataService
      let doca = this.docas;

      // Encontre o índice do item correspondente em doca com base no ID
      const index = doca.findIndex((item: Doca) => item.id === this.doca.id);

      if (index !== -1) {
        this.doca.atualizado = new Date();
        // Atualize o item existente com as alterações de this.doca
        doca[index] = this.doca;

        const docId = doca[index].id != null ? doca[index].id?.toString() : "";

        const plainObject = JSON.stringify(doca[index])
        const plainString = JSON.parse(plainObject)

        plainString.descricao.status = null;
        plainString.nome.status = null;

        await this.conta.p_getSiglaConta('contas', this.empresaSigla)
        .then((conta) => {
          // Faça algo com os dados obtidos
          // console.log('Dados da conta: atualizarStatusServico', conta);
          this.localhostService.putDados(`${conta.id}_docas`, docId!, plainString)
          .then(async (response: any) => {
            // console.log('Atualização bem-sucedida:', response);
            this.showSuccess("Doca atualizado com sucesso");

            const usr = await this.userService.carregarUsuario(this.empresaSigla)

            this.notificacaoService.criarNotificacao(
              this.empresaSigla,            // 1 - empresaSigla: any,
              `${plainString.nome.valor}`,  // 2 - titulo: string,
              `O doca foi atualizado.`,     // 3 - texto: string,
              'Listar Docas',               // 4 - rotaSemParamNome: string,
              'docas',                      // 5 - rotaSemParamListar: string,
              'Editar Doca',                // 6 - rotaComParamNome: string = "",
              `docas/doca`,                 // 7 - rotaComParamCaminho: string = "",
              plainString.id?.toString(),   // 8 - rotaComParamParam: string = "",
              '',                           // 9 - linkExternoNome: string = "",
              '',                           // 10 - linkExternoURL: string = "",
              plainString.id!.toString(),   // 11 - idReferencia: string,
              `Doca`,                       // 12 - tipo: string,
              `${usr.id}`,                  // 13 - idResponsavel: string,
              `${usr.displayName.valor}`    // 14 - nomeResponsavel: string
            )

            this.router.navigate([`${this.empresaSigla}/docas`]);
          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            this.showError("Serviço não encontrado para atualização \n" + error);
          });

        },
        (error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          // Trate o erro conforme necessário
        }).catch((error) => {
          // Trate o erro
          // console.log('Erro ao obter dados da conta:', error);
        });

        // Salve o array de volta no DataService
        // DataService.setItem("Doca", doca);
        // localStorage.setItem("Doca", JSON.stringify(doca))
        // this.showSuccess("Doca atualizado com sucesso");
        // this.router.navigate([`${this.empresaSigla}/docas`]);

      } else {
        // O item não foi encontrado no array, você pode tratar isso conforme necessário
        this.showError("Doca não encontrado para atualização");
      }
    }
  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    const modal = new bootstrap.Modal(event);
    modal.show();
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
