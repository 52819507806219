
<div class="pb-4 min-width">

  <div class="container text-center p-0">

    <!-- 👇 Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 mb-2 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Checkin realizado</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- Breadcrumb 👆 -->

    <!-- 👇 Informações / Configurações / Filtro -->
    <div class="row p-0 m-0 mb-3">
      <div class="min-width col-12">
        <div class="p-0 m-0 box-gen-card">

          <!-- Informações / Configurações -->
          <div class="row p-0 m-0">

            <!-- Informações -->
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 p-0 m-0">

              <div class="row p-0 m-0">
                <div class="custom-button-div col-lg-6 col-md-8 col-sm-8 col-8">
                  <h3 class="p-0 m-0 text-cor-padrao text-start w-100 box-gen-card-h3">
                    <span style="height: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: block;min-height: 40px;line-height: 40px;">
                      <span *ngIf="checkins.length == 0" title="Nenhum checkin">
                        Nenhum checkin
                      </span>
                      <span *ngIf="checkins.length > 0" [title]="checkins.length + ' ' + (checkins.length === 1 ? 'Checkin' : 'Checkins')">
                        <span class="box-gen-card-h3-span">{{checkins.length}}</span> {{checkins.length == 1 ? "Checkin" : "Checkins"}}
                      </span>
                    </span>
                  </h3>
                </div>
                <!-- <div class="custom-button-div col-lg-6 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" (click)="reload()" title="Atualizar">
                    Atualizar <i class="bi bi-arrow-clockwise ag-pl-10"></i>
                  </button>
                </div> -->
              </div>

            </div>

            <!-- Configurações -->
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 p-0 m-0">

              <div class="custom-button-group row">

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" (click)="reload()" title="Atualizar">
                    Atualizar <i class="bi bi-arrow-clockwise ag-pl-10"></i>
                  </button>
                </div>

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" mat-icon-button [matMenuTriggerFor]="dataCheckinButton" aria-label="order" [title]="dataCheckin">
                    {{dataCheckin}}
                    <i class="bi bi-calendar-date ag-pl-10" *ngIf="dataCheckin == 'Hoje'"></i>
                    <!-- <i class="bi bi-calendar-event ag-pl-10" *ngIf="dataCheckin == 'Futuros'"></i> -->
                    <i class="bi bi-calendar-event inverter ag-pl-10" *ngIf="dataCheckin == 'Passados'"></i>
                    <i class="bi bi-calendar-week ag-pl-10" *ngIf="dataCheckin == 'Todos'"></i>
                  </button>
                  <mat-menu #dataCheckinButton="matMenu">
                    <button mat-menu-item (click)="statusPor('Hoje')">
                      <i class="bi bi-calendar-date ag-pr-10"></i>
                      <span>Hoje</span>
                    </button>
                    <!-- <button mat-menu-item (click)="statusPor('Futuros')">
                      <i class="bi bi-calendar-event ag-pr-10"></i>
                      <span>Futuros</span>
                    </button> -->
                    <button mat-menu-item (click)="statusPor('Passados')">
                      <i class="bi bi-calendar-event inverter ag-pr-10"></i>
                      <span>Passados</span>
                    </button>
                    <button mat-menu-item (click)="statusPor('Todos')">
                      <i class="bi bi-calendar-week ag-pr-10"></i>
                      <span>Todos</span>
                    </button>
                  </mat-menu>
                </div>

                <!-- <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" mat-icon-button [matMenuTriggerFor]="statusButton" aria-label="order" [title]='status == "" ? "Todos os Status" : status'>
                    <i class="bi bi-circle-fill ag-mr-5" [ngClass]="{
                      'text-cor-alerta': status === 'Aguardando',
                      'text-cor-info': status === 'Em andamento',
                      'text-cor-sucesso': status === 'Realizado'
                      }" style=" color: var(--lw-cor-s); font-size: 12px; "></i> {{status == "" ? "Todos os Status" : status}}
                  </button>
                  <mat-menu #statusButton="matMenu">
                    <button mat-menu-item (click)="statusPor('')">
                      <i class="bi bi-circle-fill" style=" color: var(--lw-cor-s); font-size: 13px; "></i>
                      <span class="ag-ml-10">Todos os Status</span>
                    </button>
                    <button mat-menu-item (click)="statusPor(statusCheckin.aguardando)">
                      <i class="bi bi-circle-fill ag-pr-10 text-cor-alerta" style="font-size: 13px; "></i>
                      <span>{{statusCheckin.aguardando}}</span>
                    </button>
                    <button mat-menu-item (click)="statusPor(statusCheckin.emAndamento)">
                      <i class="bi bi-circle-fill ag-pr-10 text-cor-info" style="font-size: 13px; "></i>
                      <span>{{statusCheckin.emAndamento}}</span>
                    </button>
                    <button mat-menu-item (click)="statusPor(statusCheckin.realizado)">
                      <i class="bi bi-circle-fill ag-pr-10 text-cor-sucesso" style="font-size: 13px; "></i>
                      <span>{{statusCheckin.realizado}}</span>
                    </button>
                  </mat-menu>
                </div> -->

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" mat-icon-button [matMenuTriggerFor]="orderButton" aria-label="order" [title]="classificado">
                    {{classificado}} <i class="bi bi-filter ag-pl-10"></i>
                  </button>
                  <mat-menu #orderButton="matMenu">
                    <button mat-menu-item (click)="ordenadoPor('Maior Data/Horário')">
                      <i class="bi bi-sort-up ag-pr-10"></i>
                      <span>Maior Data/Horário</span>
                    </button>
                    <button mat-menu-item (click)="ordenadoPor('Menor Data/Horário')">
                      <i class="bi bi-sort-down ag-pr-10"></i>
                      <span>Menor Data/Horário</span>
                    </button>
                  </mat-menu>
                </div>

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" mat-icon-button [matMenuTriggerFor]="modoButton" aria-label="modo" [title]="modoView == true ? 'Tabela' : 'Card'">
                    {{modoView == true ? 'Tabela' : 'Card'}}
                    <i *ngIf="modoView" class="bi bi-table ag-pl-10"></i>
                    <i *ngIf="!modoView" class="bi bi-card-text ag-pl-10"></i>
                  </button>
                  <mat-menu #modoButton="matMenu">
                    <button mat-menu-item (click)="modoTabela()">
                      <i class="bi bi-table ag-pr-10"></i>
                      <span>Tabela</span>
                    </button>
                    <button mat-menu-item (click)="modoCard()">
                      <i class="bi bi-card-text ag-pr-10"></i>
                      <span>Card</span>
                    </button>
                  </mat-menu>
                </div>

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" #filtro [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_'" aria-expanded="false" [attr.aria-controls]="'collapse_'" title="Filtro">
                    Filtro <i class="bi bi-search ag-pl-10" title="expand" ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Filtro -->
          <div [id]="'collapse_'" class="accordion-collapse collapse col-lg-12 mt-1">
            <div class="p-0 m-0" >
              <div *ngIf="checkins.length > 0" style="position: relative;">
                <div class="form-floating w-100 ">
                  <input type="text" class="form-control input-text-padrao m-0 search-box-result input-text-padrao" id="filtrarCheckinSearch" [(ngModel)]="pesquisa" (keydown)="searchCheckin()" (keyup)="searchCheckin()" (keypress)="searchCheckin()" placeholder="&nbsp;">
                  <label for="filtrarCheckinSearch" class="label-input-text-padrao ag-pt-18 ag-pb-18 ag-pl-12 ag-pr-12">Filtrar checkin ...</label>
                </div>
                <i *ngIf="pesquisa != ''" class="bi bi-x-circle-fill text-secondary reset-search-usr" (click)="resetSearch()"></i>
              </div>
              <div *ngIf="pesquisa != ''" style=" display: flex; margin-top: 10px; padding-left: 10px; ">
                <span>Filtando {{filtrado}} de {{checkins.length}}</span>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <!-- Informações / Configurações / Filtro 👆 -->

    <!-- 👇 Modo Card -->
    <div class="row p-0 m-0" *ngIf="!modoView">
      <div class="min-width col-lg-12 pb-5 pt-1">

        <!-- Loading -->
        <div class="row" *ngIf="checkins.length == 0 && loading && modoViewNum == 12">
          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" >
            <div class="p-0 m-0 pulsate" >
            </div>
          </div>
        </div>

        <!-- Card -->
        <div class="row" *ngIf="checkins.length > 0 && !loading">

          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" *ngFor="let cck of displayedCheckins | SearchCheckin: pesquisa">

            <div class="card card-padrao-list p-0 m-0">

              <div class="card-body">
                <div class="row card-body-row">

                  <!-- Visivel inicialmente -->
                  <div class="col-lg-4 mb-1">
                    <div class="card-body-row-div">
                      <div class="card-padrao-list-box">

                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center" style="flex-wrap: wrap">
                          <span class="m-0 p-0 ag-mr-10 ag-mb-5 text-start" style="border-radius: 10px !important;padding-left: 8px !important;padding-right: 8px !important;padding-top: 1px !important;color: var(--cor-branco);font-size: 9px !important;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row;"
                                  [ngClass]="{
                                    'background-cor-alerta': cck.status.valor === 'Aguardando',
                                    'background-cor-info': cck.status.valor === 'Em Andamento',
                                    'background-cor-sucesso': cck.status.valor === 'Realizado'
                                    }"
                              >
                              <span [innerHTML]="cck.status.valor | filtroDestaque: pesquisa" style="line-height: 17px;"></span>
                          </span>

                          <ng-container *ngIf="getAgendaTipoNome(cck.atendimentoId) as tipoNome">
                            <span class="m-0 p-0 ag-mr-10 ag-mb-5 text-start" title="Tipo" style="background: var(--background);border-radius: 10px !important;padding-left: 8px !important;padding-right: 8px !important;padding-top: 1px !important;color: var(--text-cor-padrao);font-size: 9px !important;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row;">
                              <span style="line-height: 17px;">{{ tipoNome }}</span>
                            </span>
                          </ng-container>

                          <!-- <span class="m-0 p-0 ag-mr-10 ag-mb-5 text-start" *ngIf="cck.status.valor != ''" style="background: var(--background);border-radius: 10px !important;padding-left: 8px !important;padding-right: 8px !important;padding-top: 1px !important;color: var(--text-cor-padrao);font-size: 9px !important;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row;">
                              <span [innerHTML]="cck.status.valor | filtroDestaque: pesquisa" style="line-height: 17px;"></span>
                          </span> -->
                          <!-- <div class="progress-container" [innerHTML]="cck.status.valor | stepProgress" style="margin-top: 35px;padding-top: 35px;box-shadow: rgba(27, 31, 35, 0.04) 0px -1px 0px, rgba(255, 255, 255, 0.25) 0px -10px 0px inset;"></div> -->
                        </div>

                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center ">
                          <div class="card-body-time p-0 m-0 justify-content-start" style="font-size: 13px;">
                            <span class="ag-pr-5">
                              Início em
                            </span>
                            <span>
                              <i class="bi bi-calendar-event ag-pr-5 d-none"></i>
                              <span [innerHTML]="(cck.statusRealizado.inicio == '' ? '--/--/----, --:--' : (cck.statusRealizado.inicio | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa" style=" font-weight: 600; "></span>
                            </span>
                          </div>
                        </div>

                        <div class="d-flex justify-content-start flex-row align-items-center" style=" text-align: left; display: block !important; width: 100%; ">
                          <span class="m-0 p-0 ag-mr-10 text-start card-padrao-list-img-title" style=" font-weight: 500; ">
                            NF
                          </span>
                          <span class="card-padrao-list-img-title p-0 m-0" [innerHTML]="cck.notaFiscal.valor | filtroDestaque: pesquisa" style=" font-weight: 500; ">
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 mb-1">
                    <div class="card-padrao-list-box">

                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          {{cck.numeroPedido.title}}
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="cck.numeroPedido.valor | filtroDestaque: pesquisa">
                        </span>
                      </div>

                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center ">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          {{cck.telefoneMotorista.title}}
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="cck.telefoneMotorista.valor | filtroDestaque: pesquisa">
                        </span>
                      </div>

                      <div class="d-flex justify-content-start flex-row align-items-center ">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          {{cck.emailMotorista.title}}
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="cck.emailMotorista.valor | filtroDestaque: pesquisa">
                        </span>
                      </div>

                    </div>
                  </div>

                  <div class="col-lg-4 mb-1">
                    <div class="card-padrao-list-box">

                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          {{cck.placaVeiculo.title}}
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="cck.placaVeiculo.valor | filtroDestaque: pesquisa">
                        </span>
                      </div>

                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          {{cck.fornecedor.title}}
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="cck.fornecedor.valor | filtroDestaque: pesquisa">
                        </span>
                      </div>

                    </div>
                  </div>

                  <!-- Informações ocultas -->
                  <div [id]="'collapse_'+cck.id" class="accordion-collapse collapse col-12 mt-2">
                    <div class="row card-body-row-oculta">
                      <div class="col-lg-4 d-flex flex-column justify-content-start flex-wrap">
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{cck.nomeMotorista.title}}</span>
                          <span class="card-padrao-list-box-nobg-value">{{cck.nomeMotorista.valor}}</span>
                        </div>
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{cck.cpfMotorista.title}}</span>
                          <span class="card-padrao-list-box-nobg-value">{{cck.cpfMotorista.valor}}</span>
                        </div>

                        <!-- <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{cck.telefoneMotorista.title}}</span>
                          <span class="card-padrao-list-box-nobg-value">{{cck.telefoneMotorista.valor}}</span>
                        </div>
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{cck.emailMotorista.title}}</span>
                          <span class="card-padrao-list-box-nobg-value">{{cck.emailMotorista.valor}}</span>
                        </div> -->

                      </div>

                      <div class="col-lg-8 d-flex flex-column justify-content-start flex-wrap">
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">Criado </span>
                          <span class="card-padrao-list-box-nobg-value" [innerHTML]="(cck.criado == '' ? '--/--/----, --:--' : (cck.criado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa"></span>
                        </div>
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">Atualizado </span>
                          <span class="card-padrao-list-box-nobg-value" [innerHTML]="(cck.atualizado == '' ? '--/--/----, --:--' : (cck.atualizado | date:'dd/MM/yyyy, HH:mm') || '') | filtroDestaque: pesquisa"></span>
                        </div>
                      </div>


                      <!-- <div class="col-lg-12 d-flex flex-column justify-content-start flex-wrap card-body-row-oculta mt-2" *ngIf="cck.observacaoCliente && cck.observacaoCliente.valor != ''">
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center" style="flex-direction: column !important; align-items: flex-start !important;">
                          <span class="m-0 p-0 ag-ml-1 ag-mb-5 text-start">{{cck.observacaoCliente.title}}</span>
                          <span class="card-padrao-list-box-nobg-value ag-pl-1">{{cck.observacaoCliente.valor}}</span>
                        </div>
                      </div> -->

                      <!-- <div class="col-lg-12 d-flex flex-column justify-content-start flex-wrap card-body-row-oculta mt-2" *ngIf="cck.observacao && cck.observacao.valor != ''">
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center" style="flex-direction: column !important; align-items: flex-start !important;">
                          <span class="m-0 p-0 ag-ml-1 ag-mb-5 text-start">{{cck.observacao.title}}</span>
                          <span class="card-padrao-list-box-nobg-value ag-pl-1">{{cck.observacao.valor}}</span>
                        </div>
                      </div> -->
                      <!-- <div class="col-lg-7 d-flex flex-column justify-content-start flex-wrap">
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{cck.duracaoAtendimento.title}}</span>
                          <span class="card-padrao-list-box-nobg-value">{{cck.duracaoAtendimento.valor}}</span>
                        </div>
                      </div> -->
                    </div>
                  </div>



                </div>

                <div class="card-padrao-footer-div-menu">
                  <i class="bi bi-three-dots" title="menu" mat-icon-button [matMenuTriggerFor]="editarAgendamento_i"></i>
                  <mat-menu #editarAgendamento_i="matMenu">
                    <button mat-menu-item (click)="routerLink('checkin', cck.id)">
                      <i class="bi bi-pencil-square ag-pr-10 text-cor-primaria"></i>
                      <span>Editar</span>
                    </button>
                    <!-- <button mat-menu-item (click)="deletarAgendamento(cck.id)">
                      <i class="bi bi-trash3 ag-pr-10 text-cor-danger"></i>
                      <span>Deletar</span>
                    </button> -->
                    <button mat-menu-item (click)="atualizarStatusCheckinNaoRealizado(cck.id)">
                      <i class="bi bi-circle-fill ag-pr-10 text-cor-danger"></i>
                      <span>{{statusCheckin.naoRealizado}}</span>
                    </button>
                  </mat-menu>
                  <i class="bi bi-chevron-expand" title="expand" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_' + cck.id" aria-expanded="false" [attr.aria-controls]="'collapse_' + cck.id"></i>

                </div>
              </div>
            </div>

          </div>

          <!-- Mensagem do filtro caso não encontre -->
          <div *ngIf="displayedCheckins.length == 0 && pesquisa != ''">
            <h3 class="text-cor-padrao mt-3">Nenhum dado encontrado que corresponda ao filtro <span class="card-padrao-list-span-pesquisa">{{pesquisa}}</span></h3>
          </div>

          <!-- Páginação -->
          <div class="col-12 mt-3  ">
            <div class="box-gen-card" style=" padding: 7px 0px !important; ">
              <mat-paginator
              [length]="checkins.length"
              [pageSize]="maxSize"
              [pageSizeOptions]="[5, 10, 25, 50]"
              [showFirstLastButtons]="true"
              (page)="handlePage($event)"
            ></mat-paginator>
            </div>

          </div>

        </div>

      </div>
    </div>
    <!-- Modo Card -->

    <!-- 👇 Modo Tabela -->
    <div class="row p-0 m-0" *ngIf="modoView">

      <!-- Loading -->
      <div class="min-width col-lg-12 pb-5 pt-3" *ngIf="checkins.length == 0 && loading">
        <div class="p-0 m-0 pulsate box-gen-card">
        </div>
      </div>


      <div class="min-width col-lg-12 pb-5 pt-1" >
        <div class="p-0 m-0 box-gen-card mb-3" *ngIf="checkins.length > 0 && !loading">

          <!-- Tabela -->
          <div class="w-100 overflow-auto">
            <table id="excel-table" class="mb-1 mt-1 table table-responsive table-hover table-sm" [ngClass]="{'table-dark': !lightMode,'table-light': lightMode}" matSort (matSortChange)="sortData($event)">
              <thead style=" height: 40px; vertical-align: middle; " *ngIf="displayedCheckins.length > 0">
                  <tr>
                    <th class="text-cor-padrao ag-pl-15" mat-sort-header="id" style="min-width: 55px;border-right: 1px solid var(--border-cor);">ID</th>
                    <th class="text-cor-padrao" mat-sort-header="logradouro" style="text-align: left;min-width: 180px;border-right: 1px solid var(--border-cor);">Nome Motorista</th>
                    <th class="text-cor-padrao" mat-sort-header="cep" style="min-width: 100px;border-right: 1px solid var(--border-cor);">Placa</th>
                    <th class="text-cor-padrao" mat-sort-header="numero" style="min-width: 180px;border-right: 1px solid var(--border-cor);">Fornecedor</th>
                    <th class="text-cor-padrao" mat-sort-header="bairro" style="min-width: 100px;border-right: 1px solid var(--border-cor);">Nota Fiscal</th>
                    <th class="text-cor-padrao" mat-sort-header="localidade" style="min-width: 160px;border-right: 1px solid var(--border-cor);">Número do Pedido</th>
                    <th class="text-cor-padrao" mat-sort-header="uf" style="min-width: 100px;border-right: 1px solid var(--border-cor);">Data e Hora</th>
                    <th class="text-cor-padrao text-align-start" mat-sort-header="uf" style="min-width: 140px;">Status</th>
                    <!-- <th class="text-cor-padrao" mat-sort-header="uf" >Hora Final</th> -->
                    <th class="text-cor-padrao sticky-col" style=" text-align: center;" >&nbsp;</th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="text-cor-padrao ag-table-tr" *ngFor="let cck of displayedCheckins | SearchCheckin: pesquisa">
                    <td class="ag-table-td ag-pl-15" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="cck.id | filtroDestaque: pesquisa">
                        <!-- {{cck.id | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="cck.nomeMotorista.valor | filtroDestaque: pesquisa">
                        <!-- {{cck.nomeMotorista.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="cck.placaVeiculo.valor | filtroDestaque: pesquisa">
                        <!-- {{cck.placaVeiculo.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="cck.fornecedor.valor | filtroDestaque: pesquisa">
                        <!-- {{cck.fornecedor.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="cck.notaFiscal.valor | filtroDestaque: pesquisa">
                        <!-- {{cck.notaFiscal.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="cck.numeroPedido.valor | filtroDestaque: pesquisa">
                        <!-- {{cck.numeroPedido.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div >
                        <span [innerHTML]="cck.dataAtendimento.valor | filtroDestaque: pesquisa" class="ag-pr-5">,</span>
                        <span [innerHTML]="cck.hora_inicial.valor | filtroDestaque: pesquisa" class="ag-pr-5"></span>
                        <span [innerHTML]="cck.hora_final.valor | filtroDestaque: pesquisa" class="ag-pr-5"></span>
                        <!-- {{cck.dataAtendimento.valor | filtroDestaque: pesquisa}} {{cck.hora_inicial.valor | filtroDestaque: pesquisa}} - {{cck.hora_final.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div >
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          <i class="bi bi-circle-fill ag-pr-5" [ngClass]="{
                            'text-cor-alerta': cck.status.valor === 'Confirmado Cliente',
                            'text-cor-info': cck.status.valor === 'Confirmado Administrador',
                            'text-cor-sucesso': cck.status.valor === 'Atendido',
                            'text-cor-danger': cck.status.valor === 'Não Atendido'
                            }"></i>
                          <span [innerHTML]="cck.status.valor | filtroDestaque: pesquisa"></span>
                          <!-- {{cck.status.valor | filtroDestaque: cck.status.valor}} -->
                      </span>
                      </div>
                    </td>
                    <!-- <td class="ag-table-td">
                      <div >
                        {{cck.hora_final.valor}}
                      </div>
                    </td> -->
                    <td class="ag-table-td sticky-col">
                      <button mat-icon-button [matMenuTriggerFor]="editarAgendamento" aria-label="modo" class="btn-outline-primary sticky-col-buttom">
                        <i class="bi bi-three-dots"></i>
                      </button>
                      <mat-menu #editarAgendamento="matMenu">
                        <button mat-menu-item (click)="routerLink('checkin', cck.id)">
                          <i class="bi bi-pencil-square ag-pr-10 text-cor-primaria"></i>
                          <span>Editar</span>
                        </button>
                        <!-- <button mat-menu-item (click)="deletarAgendamento(cck.id)">
                          <i class="bi bi-trash3 ag-pr-10 text-cor-danger"></i>
                          <span>Deletar</span>
                        </button> -->
                        <button mat-menu-item (click)="atualizarStatusCheckinNaoRealizado(cck.id)">
                          <i class="bi bi-circle-fill ag-pr-10 text-cor-danger"></i>
                          <span>{{statusCheckin.naoRealizado}}</span>
                        </button>
                      </mat-menu>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>

          <!-- Mensagem do filtro caso não encontre -->
          <div *ngIf="displayedCheckins.length == 0 && pesquisa != ''">
            <h3 class="text-cor-padrao">Nenhum dado encontrado que corresponda ao filtro <span class="card-padrao-list-span-pesquisa">{{pesquisa}}</span></h3>
          </div>

          <!-- Páginação -->
          <div class="text-right row pagination-box-result" *ngIf="checkins.length > 0">
            <div class="col-12 p-0 m-0">
              <mat-paginator
                [length]="checkins.length"
                [pageSize]="maxSize"
                [pageSizeOptions]="[5, 10, 25, 50]"
                [showFirstLastButtons]="true"
                (page)="handlePage($event)"
              ></mat-paginator>
            </div>
          </div>

        </div>
      </div>

    </div>
    <!-- Modo Tabela 👆 -->

    <!-- 👇 Links Relacionados -->
    <!-- <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-5 pb-3 text-start">
        <hr class="w-100 hr-padrao">
        <p class="p-0 m-0 text-cor-padrao d-flex align-items-center" style="font-weight: 500;letter-spacing: 0.5px;">Links Relacionados <i class="bi bi-link-45deg  text-background-clip" style=" padding-left: 5px; "></i></p>
        <hr class="w-100 hr-padrao">
        <mat-chip-listbox aria-label="Geradores">
          <div *ngFor="let card of linksAgenda">
            <mat-chip (click)="routerLink(card.rota!.toString())" *ngIf="card.tipo != 'agenda-enderecos'" class="mat-chip-val" data-bs-toggle="tooltip" [title]="card.subTitle">
              {{card.title}}
            </mat-chip>
          </div>
        </mat-chip-listbox>
      </div>
    </div> -->
    <!-- Links Relacionados 👆 -->


  </div>
</div>




<!-- Motivo Não Realizado Modal -->
<div class="modal" id="naoRealizadoModal" #naoRealizadoModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0"><i class="bi bi-calendar-event"></i> Observação</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" (click)="resetarNaoRealizado()" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" style="padding: 5px !important;">

        <div class="w-100 mb-0 mt-2" style="text-align: left; ">

          <div class="row p-0 m-0">
            <!-- servico.nome -->
            <div class="col-lg-12">
              <app-ag-textarea-field
                [(ngModel)]="comentario.observacao.valor"
                [label]="comentario.observacao.title"
                [tooltip]="comentario.observacao.info"
                [value]="comentario.observacao.valor"
                [isInvalid]="comentario.observacao.status?.invalid"
                [AgStatus]="comentario.observacao.status"
                [id]="'nao_realizado_observacao'"
                AgPlaceholder="{{comentario.observacao.placeholder}}"
                AgInputType="{{comentario.observacao.inputType}}"
                AgMaxlength="{{comentario.observacao.maxlength}}"
                AgInputMode="{{comentario.observacao.inputMode}}"
                (ngModelChange)="comentario.mascara(comentario.observacao.mascara, 'observacao')"
                (change)="comentario.validarInput('observacao')"
                >
              </app-ag-textarea-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="resetarNaoRealizado()">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="naoRealizado(naoRealizadoObj.id)">Confirmar</button>
      </div>

    </div>
  </div>
</div>

