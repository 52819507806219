import { RouletteService } from './../../../services/roulette.service';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastService } from 'src/services/toast.service';

import { Chart, ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip,
  ApexTitleSubtitle
} from "ng-apexcharts";


import Annotation from 'chartjs-plugin-annotation';
import { Usuario, objUsuario } from 'src/class/usuario';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SearchUsuarioPipe } from 'src/pipes/search-usuario.pipe';
import { AuthService } from 'src/services/auth.service';
import { UsuariosService } from 'src/services/usuarios.services';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ThemeService } from 'src/services/theme.service';

import { CalendarOptions, DatesSetArg, EventContentArg } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import ptbr from '@fullcalendar/core/locales/pt-br';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { FeriadosService } from '../../../services/feriados.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ModalConfirmacaoService } from '../../../services/modal-confirmacao.service';
import { ModalConfirmacaoComponent } from 'src/app/element-library/modal-confirmacao/modal-confirmacao.component';
import { LocalhostService } from 'src/services/localhost.service';
import { DataService } from 'src/services/data.service';
import { AgendaService } from 'src/services/component/agenda.service';
import { Agenda } from 'src/class/agenda';
import { Servico } from 'src/class/servico';
import { ServicoService } from 'src/services/component/servico.service';
import { Tipo } from 'src/class/tipo';
import { TipoService } from 'src/services/component/tipo.service';

// import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import * as ApexCharts from 'apexcharts';
import { AgendamentoService } from 'src/services/component/agendamento.service';
import { Agendamento } from 'src/class/agendamento';
import { SiglaService } from 'src/services/siglaService.service';
import { UsuarioService } from 'src/services/component/usuario.service';
import { CheckinService } from 'src/services/component/checkin.service';
import { Checkin } from 'src/class/checkin';
import { ValidarUsuarioService } from 'src/services/validar-usuario.service';

interface MenuItem {
  label: string;
  iconClass: string; // Classe de ícone do Bootstrap
  route?: string;
  subitems?: MenuItem[];
}

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptionsWithEndingShape;
  yaxis?: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

export type ChartOptionsAgendamento = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis & { panning?: { enabled?: boolean; mode?: string; } };
  markers?: any; //ApexMarkers;
  stroke: any; //ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels?: ApexDataLabels;
  labels?: string[];
  title: ApexTitleSubtitle;
  legend?: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
  plotOptions?: ApexPlotOptionsWithEndingShape;
  grid?: any;
  colors?: any;
  theme?: any;
};

export type ChartOptionsCheckin = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis & { panning?: { enabled?: boolean; mode?: string; } };
  markers?: any; //ApexMarkers;
  stroke: any; //ApexStroke;
  yaxis: ApexYAxis | ApexYAxis[];
  dataLabels?: ApexDataLabels;
  labels?: string[];
  title: ApexTitleSubtitle;
  legend?: ApexLegend;
  fill: ApexFill;
  tooltip: ApexTooltip;
  plotOptions?: ApexPlotOptionsWithEndingShape;
  grid?: any;
  colors?: any;
  theme?: any;
};

export type ApexOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title?: ApexTitleSubtitle;
};

export type ApexPlotOptionsWithEndingShape = ApexPlotOptions & {
  bar?: {
    endingShape?: string;
  };
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit,OnChanges,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild('paginationControls', { read: ElementRef }) paginationControlsRef!: ElementRef;
  @ViewChild('fullcalendar') fullcalendar!: FullCalendarComponent;

  // @ViewChild("charts") charts?: ChartComponent;
  @ViewChild('chartsContainer', { static: false }) chartsContainer!: ElementRef;
  chartOptions?: ChartOptions;
  chartRecursos_ = false;

  cadAgendaTotal: number = 0;
  cadServicoTotal: number = 0;
  cadTipoTotal: number = 0;
  cadUsuarioTotal: number = 0;

  @ViewChild('chartsAgendamento', { static: false }) chartsAgendamento!: ElementRef;
  chartOptionsAgendamento?: ChartOptionsAgendamento;
  chartAgendamento_ = false;

  cht_cliente_total = 0;
  cht_admin_total = 0;
  cht_atendido_total = 0;
  cht_natendido_total = 0;

  @ViewChild('chartsCheckin', { static: false }) chartsCheckin!: ElementRef;
  chartOptionsCheckin?: ChartOptionsCheckin;
  chartCheckin_ = false;

  cht_checkin_aguardando_total = 0;
  cht_checkin_em_andamento_total = 0;
  cht_checkin_realizado_total = 0;

  checkinsPorHora: any[] = [];

  agendamentosPorHora: any[] = [];

  currentDate = new Date();
  currentDateAgendamentos = `${this.currentDate.getDate().toString().padStart(2, '0')}/${(this.currentDate.getMonth() + 1).toString().padStart(2, '0')}/${this.currentDate.getFullYear()}`

  chartOptionsData?: ApexOptions;

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj = new objUsuario;

  usr_arr: any[] = [];
  tps_arr: any[] = [];

  pesquisa="";
  pesquisaLength = 0;

  pag = 1;
  contador = 5;
  total = 0;

  totalizador = 0;
  maxSize = 5;

  visualizando = this.contador;

  lightMode: any;
  private subscription: Subscription;

  usr_nome: string = "";

  ip: string = '---';
  isp: string = '---';
  dns: string = '---';
  city: string = '---';
  country: string = '---';
  countryCode: string = '---';
  regionName: string = '---';
  region: string = '---';
  status: string = '---';

  icon_tempo: any;
  temp_c: any;
  temp_name: any;
  temp_condition: any;
  temp_region: any;

  public acesso = this.validarUsuarioService.getAcesso();
  public acessoRecursos: string[] = [];
  public acessoAgendas: string[] = [];

  animate = false;

  private newLabel? = 'New label';

  // Variável para armazenar a lista de eventos
  eventsList: any[] = [  ];

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth', // Define a visualização de lista semanal
    plugins: [dayGridPlugin, multiMonthPlugin, timeGridPlugin, listPlugin],
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'multiMonthYear,dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    contentHeight: 'auto',
    locale: ptbr, // Define o idioma para pt-BR
    events: this.eventsList,
    eventClick: this.handleEventClick.bind(this), // Associe um manipulador de eventos para quando um evento for clicado,
    // eventDisplay: 'block', // Exibe os eventos em blocos
    eventOverlap: false, // Não permita a sobreposição de eventos
    dayMaxEventRows: 3, // Define o número máximo de linhas de eventos por dia
    nowIndicator: true,
    now: new Date(),
    multiMonthMaxColumns: 1,
    slotLabelFormat: {
      hour: '2-digit',
      minute: '2-digit',
      omitZeroMinute: false,
      meridiem: 'short' // Adiciona AM/PM
    },
    allDaySlot: true, // exibir slot de dia inteiro
    // eventContent: this.customEventContent.bind(this),
    // datesSet: this.customDatesSet.bind(this), // Personalize o tamanho das células no modo mês
    // eventContent: function (info) {
    //   const event = info.event;
    //   const description = event.extendedProps['description'] || ''; // Obtenha a descrição do evento

    //   return {
    //     html: `
    //       <div class="event-title">${event.title}</div>
    //       <div class="event-description">${description}</div>
    //     `
    //   };
    // }
  };

  loader = this.loadingBar.useRef('http');

  menuItems: MenuItem[] = [
    { label: 'Página Inicial', iconClass: 'bi-house-door', route: '/' },
    {
      label: 'Sobre', iconClass: 'bi-info-circle', subitems: [
        { label: 'Visão Geral', iconClass: 'bi-eye', route: '/sobre/visao-geral' },
        { label: 'Missão', iconClass: 'bi-star', route: '/sobre/missao' }
      ]
    },
    { label: 'Contato', iconClass: 'bi-envelope', route: '/contato' },
    // Adicione mais itens de menu e subitens conforme necessário
  ];

  empresaSigla: any | undefined;
  usuario: any;

  constructor(
    private validarUsuarioService: ValidarUsuarioService,
    private http: HttpClient,
    private toast: ToastService,
    private router: Router,
    private usuarios: UsuariosService,
    private userService: AuthService,
    private searchUsr: SearchUsuarioPipe,
    private themeService: ThemeService,
    private feriadosService: FeriadosService,
    private changeDetectorRef: ChangeDetectorRef,
    private localeService: BsLocaleService,
    private loadingBar: LoadingBarService,
    private modalConfirmacaoService: ModalConfirmacaoService,
    private modalComponent: ModalConfirmacaoComponent,
    private localhostService: LocalhostService,
    private agendaService: AgendaService,
    private servicoService: ServicoService,
    private tipoService: TipoService,
    private agendamentoService: AgendamentoService,
    private rouletteService: RouletteService,
    private route: ActivatedRoute,
    private siglaService: SiglaService,
    private usuarioService: UsuarioService,
    private checkinService: CheckinService
  ) {
    Chart.register(Annotation);
    this.subscription = this.themeService.lightMode$.subscribe((lightMode: any) => {
      this.lightMode = lightMode;
    });
    defineLocale('pt-br', ptBrLocale);
    this.localeService.use('pt-br');

    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(async params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.agendaService.getAgendas(this.empresaSigla);
          this.servicoService.getServico(this.empresaSigla);
          this.tipoService.getTipo(this.empresaSigla);
          this.agendamentoService.getAgendamentos(this.empresaSigla);
          this.usuarioService.getUsuario(this.empresaSigla);
          this.usuario = await this.userService.carregarUsuario(this.empresaSigla);
          this.checkinService.getCheckin(this.empresaSigla);
          // console.log(this.usuario)
          if(this.usuario.displayName.valor != ""){
            this.usr_nome = this.usuario.displayName.valor;
          }else{
            this.usr_nome = this.usuario.email.valor;
          }
        });
    //   }
    // });
  }


  async ngOnInit() {
    this.organizarAcesso();
    this.cadastrosRecursosDisponiveis();

    this.agendamentosDoDia();

    this.checkinsDoDia();

    // this.getMyIPAddress();

    // console.log(this.calendarOptions)
    // const dataInicial = new Date('2023-10-09');
    // const dataFinal = new Date('2023-10-20');

    // const ret = this.gerarEventos(dataInicial,dataFinal,60,true);

    // console.log(ret)
    // this.calendarOptions.events = ret;

  }

  async organizarAcesso() {
    try {
      // Aguarda o retorno da Promise
      const acesso = await this.acesso;

      // Verifica se os dados existem e os armazena
      if (acesso) {
        //console.log(acesso.recursos);
        //console.log(acesso.agendas);
        this.acessoRecursos = acesso.recursos ?? [];
        this.acessoAgendas = acesso.agendas ?? [];
      } else {
        //console.warn("Nenhum dado encontrado em acesso");
      }
    } catch (error) {
      console.error("Erro ao acessar:", error);
    } finally {
      // console.log("Execução finalizada");
    }
  }

  teste(valor: any){
    console.log(valor, "teste")
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.loader.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }


  gerarEventos(dataInicial: Date, dataFinal: Date, duracaoAtendimentoEmMinutos: number, apenasDiasUteis: boolean): any[] {
    const umDiaEmMilissegundos = 24 * 60 * 60 * 1000; // 1 dia em milissegundos
    const eventos = [];

    while (dataInicial <= dataFinal) {
      const diaDaSemana = dataInicial.getDay(); // 0 (Domingo) a 6 (Sábado)

      // Verificar se é um dia útil (segunda a sexta) ou se todos os dias são considerados
      if (!apenasDiasUteis || (diaDaSemana >= 1 && diaDaSemana <= 5)) {
        const horaInicial = new Date(dataInicial);
        horaInicial.setHours(8, 0, 0, 0); // Hora de início (exemplo: 08:00)

        const horaFinal = new Date(dataInicial);
        horaFinal.setHours(18, 0, 0, 0); // Hora de término (exemplo: 18:00)

        let hr = 1;
        while (horaInicial.getTime() + duracaoAtendimentoEmMinutos * 60 * 1000 <= horaFinal.getTime()) {

          const evento = {
            start: new Date(horaInicial.getTime()).toISOString(),
            end: new Date(horaInicial.getTime() + duracaoAtendimentoEmMinutos * 60 * 1000).toISOString(),
            title: "Hora " + hr,
          };

          eventos.push(evento);

          horaInicial.setTime(horaInicial.getTime() + duracaoAtendimentoEmMinutos * 60 * 1000);

          hr++
        }
      }

      dataInicial.setTime(dataInicial.getTime() + umDiaEmMilissegundos);
    }

    return eventos;
  }

  ngOnChanges() {
    // Recarregar os eventos quando eventsList muda
    if (this.fullcalendar) {
      const calendarApi = this.fullcalendar.getApi();
      calendarApi.removeAllEventSources();
      calendarApi.addEventSource(this.eventsList);
      // console.log(this.calendarOptions)
    }
  }

  async getFeriados(){
    const feriados = await this.feriadosService.consultarFeriados("2024", "SP");

    // const eventosFiltrados = this.eventsList.filter(evento => evento.holiday !== true);
    this.eventsList = this.eventsList.filter(evento => evento.holiday !== true);

    for (let index = 0; index < feriados.length; index++) {
      const feriado = feriados[index];

      const newEvent = {
        title: feriado.name,
        date: feriado.date,
        holiday: true,
        backgroundColor: 'var(--cor-sucesso)',
        description: feriado.level
      };

      this.eventsList.push(newEvent);
    }
    // console.log(feriados)
    // console.log(this.eventsList)

    this.calendarOptions.events = this.eventsList

    this.reloadCalendar();
    this.changeDetectorRef.detectChanges();
    // console.log(this.calendarOptions.events = this.eventsList)


  }

  removerFeriados(){
    this.eventsList = this.eventsList.filter(evento => evento.holiday !== true);
    this.calendarOptions.events = this.eventsList

    this.reloadCalendar();
    this.changeDetectorRef.detectChanges();

  }

  // Função para atualizar a lista de eventos
  updateEvents() {
    this.calendarOptions.events = this.eventsList;
    // this.reloadCalendar();
  }

  // Manipulador de eventos quando um evento é clicado
  handleEventClick(info: any) {
    // Aqui, você pode adicionar lógica para editar ou remover o evento
    // Por exemplo, exibir um modal para editar o evento
    console.log(info.event);
  }

  // Função para adicionar um novo evento à lista
  addEvent() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hour = now.getHours().toString().padStart(2, '0');
    const minute = now.getMinutes().toString().padStart(2, '0');
    const second = now.getSeconds().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}`;


    const newEvent = {
      title: 'Novo Evento',
      start: formattedDate,
      end: formattedDate,
      backgroundColor: 'var(--cor-primaria)',
    };

    this.eventsList.push(newEvent);
    // this.updateEvents();
  }

  // Função para editar um evento existente
  editEvent(event: any) {
    // Implemente a lógica para editar o evento aqui
    // Por exemplo, exibir um modal de edição
    console.log('Editar evento:', event);
  }

  // Função para excluir um evento existente
  deleteEvent(event: any) {
    const index = this.eventsList.indexOf(event);
    if (index !== -1) {
      this.eventsList.splice(index, 1);
      this.updateEvents();
    }
  }

  // Função para recarregar o calendário
  reloadCalendar() {
    if (this.fullcalendar) {
      this.fullcalendar.getApi().refetchEvents();
    }
  }

  customEventContent(arg: EventContentArg) {
    // Defina um tamanho fixo para as caixas de eventos
    const eventElement = document.createElement('div');
    // eventElement.style.width = '100px'; // Defina o tamanho desejado em pixels
    eventElement.style.height = '30px'; // Defina o tamanho desejado em pixels
    eventElement.style.display = 'flex';
    eventElement.style.alignItems = 'center';
    eventElement.style.paddingLeft = '5px';

    // Crie o conteúdo do evento (por exemplo, o título)
    const eventTitle = document.createElement('div');
    eventTitle.innerText = arg.event.title;

    // Adicione o conteúdo ao elemento do evento
    eventElement.appendChild(eventTitle);

    return { domNodes: [eventElement] };
  }

  customDatesSet(info: DatesSetArg) {
    if (info.view.type === 'dayGridMonth') {
      // Defina o tamanho desejado para as células do dia no modo mês
      const dayCellElements = document.querySelectorAll('.fc-daygrid-day');
      dayCellElements.forEach((element:any) => {
        element.style.maxHeight = '100px'; // Defina o tamanho máximo desejado em pixels
      });
    }
  }

  async translateWord(word: any, targetLanguage: any){
    const endpoint = `https://api.mymemory.translated.net/get?q=${encodeURIComponent(word)}&langpair=en|${targetLanguage}`;

    try {
      const response = await fetch(endpoint);
      const data = await response.json();

      if (response.ok) {
        const translation = data.responseData.translatedText;
        // console.log(`Tradução: ${translation}`);
        return translation;
      } else {
        // console.log('Erro na tradução:', data.responseStatus);
      }
    } catch (error) {
      // console.log('Ocorreu um erro:', error);
    }
  };


  getMyIPAddress(): void {
    this.ip = '';
    this.isp = '';
    this.dns = '';
    this.city = '';
    this.country = '';
    this.countryCode = '';
    this.regionName = '';
    this.region = '';
    this.status = '';

    this.http.get('https://api.ipify.org?format=json')
      .subscribe((response: any) => {
        this.ip = response.ip;
        this.getAdditionalInfo(this.ip);
      }, error => {
        this.ip = '---';
        this.isp = '---';
        this.dns = '---';
        this.city = '---';
        this.country = '---';
        this.countryCode = '---';
        this.regionName = '---';
        this.region = '---';
        this.status = '---';
        const erro = 'Erro ao obter o endereço IP: ' + error.message;
        // this.showError(erro);
        // console.error('Erro ao obter o endereço IP:', error);
      });
  }

  getAdditionalInfo(ip: string): void {
    this.isp = '';
    this.dns = '';
    this.city = '';
    this.country = '';
    this.countryCode = '';
    this.regionName = '';
    this.region = '';
    this.status = '';

    this.http.get(`https://ipapi.co/${ip}/json/`)
      .subscribe((response: any) => {
        this.isp = response.org;
        this.dns = response.ip;
        this.city = response.city;
        this.country = response.country_name;
        this.countryCode = response.country_code;
        this.regionName = response.region;
        this.region = response.region_code;
        this.status = 'success';
        this.getTempo(this.city);
      }, error => {
        this.isp = '---';
        this.dns = '---';
        this.city = '---';
        this.country = '---';
        this.countryCode = '---';
        this.regionName = '---';
        this.region = '---';
        this.status = '---';
        const erro = 'Erro ao obter informações adicionais: ' + error.message;
        // this.showError(erro);
        // console.error('Erro ao obter informações adicionais:', error);
      });
  }

  getTempo(cidade:any){

    this.temp_c = "-";
    this.temp_name = "-";
    this.temp_region = "-";
    this.temp_condition = "-";
    this.http.get(`https://api.weatherapi.com/v1/current.json?key=eb7e1ccb9c6d4c4980d173740231807&q=${cidade}`)
      .subscribe(async (response: any) => {
        // console.log(response)

        this.icon_tempo = `http:${response.current.condition.icon}`;
        this.temp_c = Math.round(response.current.temp_c).toString() + "ºC";
        this.temp_name = response.location.name;
        this.temp_region = response.location.region;
        this.temp_condition = "-";

        try {
          this.temp_condition = await this.translateWord(response.current.condition.text, 'pt');
        } catch (error) {
          this.temp_condition = response.current.condition.text;
          // console.log("error ",error)

        }

        // console.log("eng ",response.current.condition.text)
        // console.log("pt ",this.temp_condition)
      }, error => {
        // console.error('Erro:', error);
      });
  }

  // public lineChartData: ChartConfiguration['data'] = {
  //   datasets: [
  //     {
  //       data: [65, 59, 80, 81, 56, 55, 40],
  //       label: 'Series A',
  //       backgroundColor: 'rgba(148,159,177,0.2)',
  //       borderColor: 'rgba(148,159,177,1)',
  //       pointBackgroundColor: 'rgba(148,159,177,1)',
  //       pointBorderColor: '#fff',
  //       pointHoverBackgroundColor: '#fff',
  //       pointHoverBorderColor: 'rgba(148,159,177,0.8)',
  //       fill: 'origin',
  //     },
  //     {
  //       data: [28, 48, 40, 19, 86, 27, 90],
  //       label: 'Series B',
  //       backgroundColor: 'rgba(77,83,96,0.2)',
  //       borderColor: 'rgba(77,83,96,1)',
  //       pointBackgroundColor: 'rgba(77,83,96,1)',
  //       pointBorderColor: '#fff',
  //       pointHoverBackgroundColor: '#fff',
  //       pointHoverBorderColor: 'rgba(77,83,96,1)',
  //       fill: 'origin',
  //     },
  //     {
  //       data: [180, 480, 770, 90, 1000, 270, 400],
  //       label: 'Series C',
  //       yAxisID: 'y1',
  //       backgroundColor: 'rgba(255,0,0,0.3)',
  //       borderColor: 'red',
  //       pointBackgroundColor: 'rgba(148,159,177,1)',
  //       pointBorderColor: '#fff',
  //       pointHoverBackgroundColor: '#fff',
  //       pointHoverBorderColor: 'rgba(148,159,177,0.8)',
  //       fill: 'origin',
  //     },
  //   ],
  //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  // };

  // public lineChartOptions: ChartConfiguration['options'] = {
  //   elements: {
  //     line: {
  //       tension: 0.5,
  //     },
  //   },
  //   scales: {
  //     // We use this empty structure as a placeholder for dynamic theming.
  //     y: {
  //       position: 'left',
  //     },
  //     y1: {
  //       position: 'right',
  //       grid: {
  //         color: 'rgba(255,0,0,0.3)',
  //       },
  //       ticks: {
  //         color: 'red',
  //       },
  //     },
  //   },

  //   plugins: {
  //     legend: { display: true },
  //     annotation: {
  //       annotations: [
  //         {
  //           type: 'line',
  //           scaleID: 'x',
  //           value: 'April',
  //           borderColor: 'orange',
  //           borderWidth: 2,
  //           label: {
  //             display: true,
  //             position: 'center',
  //             color: 'orange',
  //             content: 'Line',
  //             font: {
  //               weight: 'bold',
  //             },
  //           },
  //         },
  //       ],
  //     },
  //   },
  // };

  // public lineChartType: ChartType = 'line';

  // @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  // private static generateNumber(i: number): number {
  //   return Math.floor(Math.random() * (i < 2 ? 100 : 1000) + 1);
  // }

  // public randomize(): void {
  //   for (let i = 0; i < this.lineChartData.datasets.length; i++) {
  //     for (let j = 0; j < this.lineChartData.datasets[i].data.length; j++) {
  //       this.lineChartData.datasets[i].data[j] =
  //       HomeComponent.generateNumber(i);
  //     }
  //   }
  //   this.chart?.update();
  // }

  // // events
  // public chartClicked({
  //   event,
  //   active,
  // }: {
  //   event?: ChartEvent;
  //   active?: object[];
  // }): void {
  //   // console.log(event, active);
  // }

  // public chartHovered({
  //   event,
  //   active,
  // }: {
  //   event?: ChartEvent;
  //   active?: object[];
  // }): void {
  //   // console.log(event, active);
  // }

  // public hideOne(): void {
  //   const isHidden = this.chart?.isDatasetHidden(1);
  //   this.chart?.hideDataset(1, !isHidden);
  // }

  // public pushOne(): void {
  //   this.lineChartData.datasets.forEach((x, i) => {
  //     const num = HomeComponent.generateNumber(i);
  //     x.data.push(num);
  //   });
  //   this.lineChartData?.labels?.push(
  //     `Label ${this.lineChartData.labels.length}`
  //   );

  //   this.chart?.update();
  // }

  // public changeColor(): void {
  //   this.lineChartData.datasets[2].borderColor = 'green';
  //   this.lineChartData.datasets[2].backgroundColor = `rgba(0, 255, 0, 0.3)`;

  //   this.chart?.update();
  // }

  // public changeLabel(): void {
  //   const tmp = this.newLabel;
  //   this.newLabel = this.lineChartData.datasets[2].label;
  //   this.lineChartData.datasets[2].label = tmp;

  //   this.chart?.update();
  // }

  reload(){
    try {
      this.usr_arr = [];
      this.tps_arr = [];
      // this.getTotalTiposUsuarios();
      // this.getUsuario();
      this.showInfo("Lista de usuários atualizada com sucesso!!")
    } catch (error) {
      this.showError("Ocorreu um erro na atualização da listagem dos usuários")

    }

  }

  searchUsrReturn(){
    const ts = this.searchUsr.transform(this.usr_arr, this.pesquisa);
    if(this.pesquisa == ""){
      this.pesquisaLength = 0;
    }else{
      this.pesquisaLength = ts.length;
    }
  }

  resetSearch(){
    this.pesquisa = "";
    this.pesquisaLength = 0;
  }

  pags(pags: any) {
    const target = pags as HTMLTextAreaElement;
    this.contador = parseInt(target.value)
    const total = this.contador * this.pag
    this.visualizando = total;
    if(this.visualizando > this.totalizador && (this.visualizando > 0 && this.totalizador > 0)){
      this.visualizando = this.totalizador;
    }
  }

  handlePageChange(event: any) {
    this.pag = event;
    const total = this.contador * this.pag
    this.visualizando = total;
    if(this.visualizando > this.totalizador && (this.visualizando > 0 && this.totalizador > 0)){
      this.visualizando = this.totalizador;
    }

    this.ajusteMaxSize();
  }

  ajusteMaxSize(){
    if(this.paginationControlsRef != undefined){
      // Obtém o elemento de paginação
      const paginationElement: HTMLElement = this.paginationControlsRef.nativeElement;

      // Calcula o número máximo de itens visíveis com base no tamanho da tela
      const availableWidth: number = paginationElement.offsetWidth;
      const itemWidth: number = 55; // Largura estimada de cada item da paginação
      const maxSize: number = Math.floor(availableWidth / itemWidth) - 1;

      // Define o maxSize calculado
      this.maxSize = maxSize;
    }
  }

  cadastrosRecursosDisponiveis() {
    // cadAgendaTotal
    // this.rouletteService.reset('cadAgendaTotal');
    this.agendaService.getAllAgendas()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (agendas: Agenda[]) => {
        // this.rouletteService.startRoulette('cadAgendaTotal', agendas.length);

        // this.rouletteService.getValueObservable('cadAgendaTotal')?.subscribe(value => {
        //   this.cadAgendaTotal = value;
        // });

        this.cadAgendaTotal = agendas.length;

        this.chartRecursos(false);
        this.changeDetectorRef.detectChanges();
      },
      error => {
        // console.error('Erro ao listar as Agendas:', error);
      }
    );

    // cadServicoTotal
    // this.rouletteService.reset('cadServicoTotal');
    this.servicoService.getAllServico()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (servicos: Servico[]) => {
        // this.rouletteService.startRoulette('cadServicoTotal', servicos.length);

        // this.rouletteService.getValueObservable('cadServicoTotal')?.subscribe(value => {
        //   this.cadServicoTotal = value;
        // });
        this.cadServicoTotal = servicos.length;
        this.chartRecursos(false);
        this.changeDetectorRef.detectChanges();
      },
      error => {
        // console.error('Erro ao listar os Serviços:', error);

        this.changeDetectorRef.detectChanges();
      }
    );

    // cadTipoTotal
    // this.rouletteService.reset('cadTipoTotal');
    this.tipoService.getAllTipo()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (tipos: Tipo[]) => {
        // this.rouletteService.startRoulette('cadTipoTotal', tipos.length);

        // this.rouletteService.getValueObservable('cadTipoTotal')?.subscribe(value => {
        //   this.cadTipoTotal = value;
        // });
        this.cadTipoTotal = tipos.length;
        this.chartRecursos(false);
        this.changeDetectorRef.detectChanges();
      },
      error => {
        // console.error('Erro ao listar os Tipos:', error);
      }
    );

    this.usuarioService.getAllUsuario()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (usuarios: Usuario[]) => {
        // this.rouletteService.startRoulette('cadTipoTotal', tipos.length);

        // this.rouletteService.getValueObservable('cadTipoTotal')?.subscribe(value => {
        //   this.cadTipoTotal = value;
        // });
        this.cadUsuarioTotal = usuarios.length;
        this.chartRecursos(false);
        this.changeDetectorRef.detectChanges();
      },
      error => {
        // console.error('Erro ao listar os Usuários:', error);
      }
    );

    // cadUsuarioTotal
  }



  chartRecursos(bool: boolean = true){

    // console.log("chartRecursos")

    this.chartOptions = {
      series: [
        {
          name: "Agendas",
          data: [this.cadAgendaTotal]
        },
        {
          name: "Serviços",
          data: [this.cadServicoTotal]
        },
        {
          name: "Tipos",
          data: [this.cadTipoTotal]
        },
        {
          name: "Usuários",
          data: [this.cadUsuarioTotal]
        }
      ],
      chart: {
        type: "bar",
        height: 300,
        width: '100%',
        toolbar: {
          autoSelected: 'zoom',
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false, // Desabilita a opção de download/exportação
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            customIcons: []
          }
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "10%",
          // endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      title: {
        text: ""
      },
      xaxis: {
        categories: [
          "Cadastros"
        ]
      },
      // yaxis: {
      //   title: {
      //     text: "Cadastrados / Recursos Disponíveis"
      //   }
      // },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return "" + val + "";
          }
        }
      }
    };

    if(bool){
      this.chartRecursos_ = !this.chartRecursos_;
    }

  }

  agendamentosDoDia(){

    this.cht_cliente_total = 0;
    this.cht_admin_total = 0;
    this.cht_atendido_total = 0;
    this.cht_natendido_total = 0;

    this.agendamentoService.getAgendamentosDia()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (agendamentos: Agendamento[]) => {
        // console.log("agendamentos",agendamentos)

        this.agendamentosPorHora = [];

        if(agendamentos.length > 0){

          for (let index = 0; index < agendamentos.length; index++) {
            const agt = agendamentos[index];
            const dataDoObjetoString = agt.dataAtendimento.valor;

            // Supondo que agt.dataAtendimento.valor seja uma string no formato "dd/mm/aaaa"
            const partesData = dataDoObjetoString.split("/");

            // Crie a data atual apenas com a parte da data
            const dataAtualApenas = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());

            // Verifique se as datas são iguais
            if (
              parseInt(partesData[2]) === dataAtualApenas.getFullYear() &&
              parseInt(partesData[1]) === dataAtualApenas.getMonth() + 1 &&
              parseInt(partesData[0]) === dataAtualApenas.getDate()
            ) {
              // console.log("A data do objeto é igual à data atual.");
              this.agendamentosPorHora.push({
                timestamp: this.timestampAgendamento(`${agt.dataAtendimento.valor} ${agt.hora_final.valor}`),
                count: 1,
                status: `${agt.status.valor}`
              });
            } else {
              // console.log("A data do objeto não é igual à data atual.");
            }
          }

          this.agendamentosPorHora = this.agendamentosPorHora.sort((a, b) => a.timestamp - b.timestamp)

          // this.addAgendamento();

          const agendamentosAgrupados = this.agendamentosPorHora.reduce((acc: any, item) => {
            const timestamp = item.timestamp;
            const key = new Date(timestamp).toISOString();
            acc[key] = acc[key] || {};
            acc[key][item.status] = (acc[key][item.status] || 0) + item.count;

            // console.log(acc);
            return acc;
          }, {});

          const allowedStatus = ["Confirmado Cliente", "Confirmado Administrador", "Atendido", "Não Atendido"];

          const formattedDateLabels = Object.keys(agendamentosAgrupados).map(key => new Date(key.split('_')[0]));

          allowedStatus.map(status => {
            const data = formattedDateLabels.map(date => {
              const key = date.toISOString();
              return agendamentosAgrupados[key] ? agendamentosAgrupados[key][status] || 0 : 0;
            });
            const total = data.reduce((acc, val) => acc + val, 0);
            const isVisible = total > 0;
            // console.log("isVisible = ",isVisible)
            // console.log("total = ",total)

            switch (status) {
              case "Confirmado Cliente":
                // this.rouletteService.reset('cht_cliente_total');
                // this.rouletteService.startRoulette('cht_cliente_total', total);

                // this.rouletteService.getValueObservable('cht_cliente_total')?.subscribe(value => {
                //   this.cht_cliente_total = value;
                // });
                this.cht_cliente_total = total
                break;
              case "Confirmado Administrador":
                // this.rouletteService.reset('cht_admin_total');
                // this.rouletteService.startRoulette('cht_admin_total', total);

                // this.rouletteService.getValueObservable('cht_admin_total')?.subscribe(value => {
                //   this.cht_admin_total = value;
                // });
                this.cht_admin_total = total
                break;
              case "Atendido":
                // this.rouletteService.reset('cht_atendido_total');
                // this.rouletteService.startRoulette('cht_atendido_total', total);

                // this.rouletteService.getValueObservable('cht_atendido_total')?.subscribe(value => {
                //   this.cht_atendido_total = value;
                // });
                this.cht_atendido_total = total
                break;
              case "Não Atendido":
                // this.rouletteService.reset('cht_natendido_total');
                // this.rouletteService.startRoulette('cht_natendido_total', total);

                // this.rouletteService.getValueObservable('cht_natendido_total')?.subscribe(value => {
                //   this.cht_natendido_total = value;
                // });
                this.cht_natendido_total = total
                break;
              default:
                break;
            }

          })

          this.chartAgendamento(false);
        }
      },
      error => {
        // console.error('Erro ao listar os Agendamentos:', error);
      }
    );

  }

  checkinsDoDia(){

    this.cht_checkin_aguardando_total = 0;
    this.cht_checkin_em_andamento_total = 0;
    this.cht_checkin_realizado_total = 0;

    this.checkinService.getCheckinsDia()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (checkins: Checkin[]) => {
        // console.log("agendamentos",agendamentos)

        this.checkinsPorHora = [];

        if(checkins.length > 0){

          for (let index = 0; index < checkins.length; index++) {
            const agt = checkins[index];
            const dataDoObjeto = new Date(agt.criado);

            const dataDoObjetoApenas = new Date(dataDoObjeto.getFullYear(), dataDoObjeto.getMonth(), dataDoObjeto.getDate());

            // Crie a data atual apenas com a parte da data
            const dataAtualApenas = new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());

            const dataAtualApenasApenas = new Date(dataAtualApenas.getFullYear(), dataAtualApenas.getMonth(), dataAtualApenas.getDate());

            // Verifique se as datas são iguais
            if (dataDoObjetoApenas.getTime() === dataAtualApenasApenas.getTime()) {
              // console.log("A data do objeto é igual à data atual.");
              this.checkinsPorHora.push({
                timestamp: agt.criado,
                count: 1,
                status: `${agt.status.valor}`
              });
            } else {
              // console.log("A data do objeto não é igual à data atual.");
            }
          }

          this.checkinsPorHora = this.checkinsPorHora.sort((a, b) => a.timestamp - b.timestamp)

          // this.addAgendamento();

          const checkinsAgrupados = this.checkinsPorHora.reduce((acc: any, item) => {
            const timestamp = item.timestamp;
            const key = new Date(timestamp).toISOString();
            acc[key] = acc[key] || {};
            acc[key][item.status] = (acc[key][item.status] || 0) + item.count;

            // console.log(acc);
            return acc;
          }, {});

          const allowedStatus = ['Aguardando', 'Em andamento', 'Realizado'];

          const formattedDateLabels = Object.keys(checkinsAgrupados).map(key => new Date(key.split('_')[0]));

          allowedStatus.map(status => {
            const data = formattedDateLabels.map(date => {
              const key = date.toISOString();
              return checkinsAgrupados[key] ? checkinsAgrupados[key][status] || 0 : 0;
            });
            const total = data.reduce((acc, val) => acc + val, 0);
            const isVisible = total > 0;
            // console.log("isVisible = ",isVisible)
            // console.log("total = ",total)

            switch (status) {
              case "Aguardando":
                // this.rouletteService.reset('cht_cliente_total');
                // this.rouletteService.startRoulette('cht_cliente_total', total);

                // this.rouletteService.getValueObservable('cht_cliente_total')?.subscribe(value => {
                //   this.cht_cliente_total = value;
                // });
                this.cht_checkin_aguardando_total = total
                break;
              case "Em andamento":
                // this.rouletteService.reset('cht_admin_total');
                // this.rouletteService.startRoulette('cht_admin_total', total);

                // this.rouletteService.getValueObservable('cht_admin_total')?.subscribe(value => {
                //   this.cht_admin_total = value;
                // });
                this.cht_checkin_em_andamento_total = total
                break;
              case "Realizado":
                // this.rouletteService.reset('cht_atendido_total');
                // this.rouletteService.startRoulette('cht_atendido_total', total);

                // this.rouletteService.getValueObservable('cht_atendido_total')?.subscribe(value => {
                //   this.cht_atendido_total = value;
                // });
                this.cht_checkin_realizado_total = total
                break;
              default:
                break;
            }

          })

          this.chartCheckin(false);
        }
      },
      error => {
        // console.error('Erro ao listar os Agendamentos:', error);
      }
    );

  }

  addAgendamento(){
     // // Exemplo de agendamentos com timestamps
    this.agendamentosPorHora = [
      // { timestamp: this.timestampAgendamento(`28/12/2023 08:15`), count: 1, status: "Confirmado Cliente" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 08:15`), count: 3, status: "Confirmado Administrador" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 8:15`), count: 4, status: "Atendido" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 8:15`), count: 5, status: "Não Atendido" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 12:15`), count: 1, status: "Confirmado Cliente" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 13:30`), count: 1, status: "Confirmado Cliente" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 14:00`), count: 9, status: "Confirmado Administrador" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 15:00`), count: 2, status: "Atendido" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 16:00`), count: 2, status: "Não Atendido" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 17:05`), count: 1, status: "Não Atendido" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 18:15`), count: 1, status: "Atendido" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 19:30`), count: 1, status: "Confirmado Cliente" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 20:45`), count: 9, status: "Confirmado Administrador" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 06:50`), count: 2, status: "Atendido" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 07:00`), count: 2, status: "Não Atendido" },
      // { timestamp: this.timestampAgendamento(`28/12/2023 08:10`), count: 8, status: "Atendido" },

      // { timestamp: this.timestampAgendamento(`27/12/2023 08:15`), count: 1, status: "Confirmado Cliente" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 08:15`), count: 3, status: "Confirmado Administrador" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 8:15`), count: 4, status: "Atendido" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 8:15`), count: 5, status: "Não Atendido" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 12:15`), count: 1, status: "Confirmado Cliente" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 13:30`), count: 1, status: "Confirmado Cliente" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 14:00`), count: 9, status: "Confirmado Administrador" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 15:00`), count: 2, status: "Atendido" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 16:00`), count: 2, status: "Não Atendido" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 17:05`), count: 1, status: "Não Atendido" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 18:15`), count: 1, status: "Atendido" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 19:30`), count: 1, status: "Confirmado Cliente" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 20:45`), count: 9, status: "Confirmado Administrador" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 06:50`), count: 2, status: "Atendido" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 07:00`), count: 2, status: "Não Atendido" },
      // { timestamp: this.timestampAgendamento(`27/12/2023 08:10`), count: 8, status: "Atendido" },

      { timestamp: this.timestampAgendamento(`19/01/2024 08:15`), count: 1, status: "Confirmado Cliente" },
      { timestamp: this.timestampAgendamento(`19/01/2024 08:15`), count: 3, status: "Confirmado Administrador" },
      { timestamp: this.timestampAgendamento(`19/01/2024 8:15`), count: 4, status: "Atendido" },
      { timestamp: this.timestampAgendamento(`19/01/2024 8:15`), count: 5, status: "Não Atendido" },
      { timestamp: this.timestampAgendamento(`19/01/2024 12:15`), count: 1, status: "Confirmado Cliente" },
      { timestamp: this.timestampAgendamento(`19/01/2024 13:30`), count: 1, status: "Confirmado Cliente" },
      { timestamp: this.timestampAgendamento(`19/01/2024 14:00`), count: 9, status: "Confirmado Administrador" },
      { timestamp: this.timestampAgendamento(`19/01/2024 15:00`), count: 2, status: "Atendido" },
      { timestamp: this.timestampAgendamento(`19/01/2024 16:00`), count: 2, status: "Não Atendido" },
      { timestamp: this.timestampAgendamento(`19/01/2024 17:05`), count: 1, status: "Não Atendido" },
      { timestamp: this.timestampAgendamento(`19/01/2024 18:15`), count: 1, status: "Atendido" },
      { timestamp: this.timestampAgendamento(`19/01/2024 19:30`), count: 1, status: "Confirmado Cliente" },
      { timestamp: this.timestampAgendamento(`19/01/2024 20:45`), count: 9, status: "Confirmado Administrador" },
      { timestamp: this.timestampAgendamento(`19/01/2024 06:50`), count: 2, status: "Atendido" },
      { timestamp: this.timestampAgendamento(`19/01/2024 07:00`), count: 2, status: "Não Atendido" },
      { timestamp: this.timestampAgendamento(`19/01/2024 08:10`), count: 8, status: "Atendido" }
    ].sort((a, b) => a.timestamp - b.timestamp);

    // this.chartAgendamento();

  }

  chartAgendamento(bool: boolean = false) {

    // Crie allowedStatus com contagem zero
    const allowedStatus = ["Confirmado Cliente", "Confirmado Administrador", "Atendido", "Não Atendido"];
    let series: any[];
    let minDate: any;
    let maxDate: any;
    let formattedDateLabels: any[] = [];

    if (this.agendamentosPorHora.length === 0) {
      // console.log("this.agendamentosPorHora is empty", this.agendamentosPorHora);


      // const emptyStatusCount = Array(allowedStatus.length).fill(0);

      // Crie séries com contagem zero para cada status
      series = allowedStatus.map((status, index) => ({
        name: status,
        type: 'area',
        data: [0],
        color: `${
          status === "Confirmado Cliente" ? "var(--cor-alerta)" :
          status === "Confirmado Administrador" ? "var(--cor-info)" :
          status === "Atendido" ? "var(--cor-sucesso)" :
          status === "Não Atendido" ? "var(--cor-danger)" : ""
        }`,
      }));

      // const currentDate = new Date();
      // minDate = currentDate;
      // maxDate = currentDate;

    } else {
      // console.log("this.agendamentosPorHora",this.agendamentosPorHora)

      // Encontre a menor e a maior data no array de agendamentos
      minDate = Math.min(...this.agendamentosPorHora.map(item => item.timestamp));
      maxDate = Math.max(...this.agendamentosPorHora.map(item => item.timestamp));

      // Adicione -
      const adjustedMinDate = minDate - 2 * 60 * 60 * 1000;

      // Adicione +
      const adjustedMaxDate = maxDate + 2 * 60 * 60 * 1000;

      minDate = adjustedMinDate;
      maxDate = adjustedMaxDate;

      const agendamentosAgrupados = this.agendamentosPorHora.reduce((acc: any, item) => {
        const timestamp = item.timestamp;
        const key = new Date(timestamp).toISOString();
        acc[key] = acc[key] || {};
        acc[key][item.status] = (acc[key][item.status] || 0) + item.count;

        // console.log(acc);
        return acc;
      }, {});

      formattedDateLabels = Object.keys(agendamentosAgrupados).map(key => new Date(key.split('_')[0]));

      series = allowedStatus.map(status => {
        const data = formattedDateLabels.map(date => {
          const key = date.toISOString();
          return agendamentosAgrupados[key] ? agendamentosAgrupados[key][status] || 0 : 0;
        });
        const total = data.reduce((acc, val) => acc + val, 0);
        const isVisible = total > 0;
        // console.log("isVisible = ",isVisible)
        // console.log("total = ",total)
        if (total > 0) {
          return {
            name: status,
            type: 'area',
            data: data,
            color: `${status == "Confirmado Cliente" ? "var(--cor-alerta)" : status == "Confirmado Administrador" ? "var(--cor-info)" : status == "Atendido" ? "var(--cor-sucesso)" : status == "Não Atendido" ? "var(--cor-danger)" : ""}`,
          };
        } else {
          // return null; // Retorna null para os status com contagem zero
          return {
            name: status,
            type: 'area',
            data: data,
            color: `${status == "Confirmado Cliente" ? "var(--cor-alerta)" : status == "Confirmado Administrador" ? "var(--cor-info)" : status == "Atendido" ? "var(--cor-sucesso)" : status == "Não Atendido" ? "var(--cor-danger)" : ""}`,
          };
        }
      })
      .filter(series => series !== null) as {
        name: string;
        type: string;
        data: any[];
        color: string;
      }[];
    }

    if (formattedDateLabels.length === 0) {
      formattedDateLabels.push(new Date());
    }


    const picoData = formattedDateLabels.map(date => {
      const picoTotal = this.agendamentosPorHora
        .filter(item => new Date(item.timestamp).toISOString() === date.toISOString())
        .reduce((total, item) => total + item.count, 0);
      return picoTotal;
    });

    const picoSeries = {
      name: 'Total',
      type: 'line',
      data: picoData,
      color: 'var(--text-cor-sub)'
    };

    let maxDataValue = Math.max(
      ...series.map(serie => Math.max(...serie.data)),
      // ...picoData
    );
    // console.log("maxDataValue",maxDataValue)
    if(maxDataValue <= 1){
      maxDataValue = maxDataValue+1
    }
    // console.log("maxDataValue",maxDataValue)

    let tickAmountX = formattedDateLabels.length;
    // console.log("tickAmountX",tickAmountX)
    if(tickAmountX <= 1){
      tickAmountX = tickAmountX+1
    }
    // console.log("tickAmountX",tickAmountX)

    // console.log(series);
    // console.log(agendamentosAgrupados);
    // console.log(formattedDateLabels);
    // console.log(formattedDateLabels.map(date => date.getTime()))

    this.chartOptionsAgendamento = {
      series: [
        ...series,
        // picoSeries
      ],
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          // autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom',
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false, // Desabilita a opção de download/exportação
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            customIcons: []
          }
        },
        defaultLocale: 'br',
        locales: [{
          name: 'br',
          options: {
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            days: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
            shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
            toolbar: {
              download: 'Baixar SVG',
              selection: 'Seleção',
              selectionZoom: 'Zoom da Seleção',
              zoomIn: 'Aumentar Zoom',
              zoomOut: 'Diminuir Zoom',
              pan: 'Panning',
              reset: 'Redefinir Zoom',
            }
          }
        }]
      },
      plotOptions: {
        // bar: {
        //   horizontal: false,
        //   columnWidth: "5%",
        //   endingShape: "rounded"
        // }
      },
      stroke: {
        show: true,
        width: 3,
        // curve: 'smooth',
        // colors: ["transparent"]
        curve: 'straight',
        // colors: ['var(--cor-alerta)', 'var(--cor-info)', 'var(--cor-sucesso)', 'var(--cor-danger)', 'var(--text-cor-sub)'],
      },
      title: {
        // text: "Agendamentos por Data e Hora"
      },
      dataLabels: {
        enabled: false,
        // enabledOnSeries: [4]
      },
      xaxis: {
        type: 'datetime',
        categories: formattedDateLabels.map(date => date.getTime()),
        labels: {
          rotate: -45
        },
        // position: 'bottom', // Altere para 'bottom'
        tickAmount: tickAmountX,
        panning: {
          enabled: true,
          mode: 'x'
        },
        min: minDate,
        max: maxDate,
      },
      yaxis: [
        {
          show: true,
          showAlways: true,
          title: {
            text: "Quantidade"
          },
          max: maxDataValue,
          tickAmount: maxDataValue,
          forceNiceScale: true,
          tooltip: {
            enabled: true
          }
        },
        // {
        //   show: false,
        //   max: maxDataValue,
        //   tickAmount: maxDataValue,
        //   forceNiceScale: true
        // },
        // {
        //   show: false,
        //   max: maxDataValue,
        //   tickAmount: maxDataValue,
        //   forceNiceScale: true
        // },
        // {
        //   show: false,
        //   max: maxDataValue,
        //   tickAmount: maxDataValue,
        //   forceNiceScale: true
        // },
        // {
        //   opposite: true,
        //   title: {
        //     text: "Total"
        //   },
        //   max: maxDataValue,
        //   tickAmount: maxDataValue,
        //   forceNiceScale: true,
        //   tooltip: {
        //     enabled: true
        //   }
        // }
      ],
      fill: {
        // ... (suas configurações de preenchimento, se necessário)
        // opacity: 1,
        opacity: [0.20, 0.10, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.20,
          opacityTo: 0.0,
          stops: [0, 100, 100, 100]
        },
        // colors: ['', '', '', '', 'var(--background)']
      // colors: ['var(--cor-alerta)', 'var(--cor-info)', 'var(--cor-sucesso)', 'var(--cor-danger)', 'var(--text-cor-sub)'],

      },
      tooltip: {
        // shared: false,
        // intersect: true,
        followCursor: true,
        x: {
          format: 'dd/MM/yyyy HH:mm'
        },
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        }
      },
      markers: {
        size: 3,
        // colors: ["#000524"],
        // strokeColor: "#00BAEC",
        strokeWidth: 1
      }
    };

    if(bool){
      this.chartAgendamento_ = !this.chartAgendamento_;
    }

  }

  chartCheckin(bool: boolean = false) {

    // Crie allowedStatus com contagem zero
    const allowedStatus = ['Aguardando', 'Em andamento', 'Realizado'];
    let series: any[];
    let minDate: any;
    let maxDate: any;
    let formattedDateLabels: any[] = [];

    if (this.checkinsPorHora.length === 0) {
      // console.log("this.checkinsPorHora is empty", this.checkinsPorHora);


      // const emptyStatusCount = Array(allowedStatus.length).fill(0);

      // Crie séries com contagem zero para cada status
      series = allowedStatus.map((status, index) => ({
        name: status,
        type: 'area',
        data: [0],
        color: `${
          status === "Aguardando" ? "var(--cor-alerta)" :
          status === "Em andamento" ? "var(--cor-info)" :
          status === "Realizado" ? "var(--cor-sucesso)" : ""
        }`,
      }));

      // const currentDate = new Date();
      // minDate = currentDate;
      // maxDate = currentDate;

    } else {
      // console.log("this.checkinsPorHora",this.checkinsPorHora)

      // Encontre a menor e a maior data no array de agendamentos
      minDate = Math.min(...this.checkinsPorHora.map(item => item.timestamp));
      maxDate = Math.max(...this.checkinsPorHora.map(item => item.timestamp));

      // Adicione -
      const adjustedMinDate = minDate - 2 * 60 * 60 * 1000;

      // Adicione +
      const adjustedMaxDate = maxDate + 2 * 60 * 60 * 1000;

      minDate = adjustedMinDate;
      maxDate = adjustedMaxDate;

      const checkinsAgrupados = this.checkinsPorHora.reduce((acc: any, item) => {
        const timestamp = item.timestamp;
        const key = new Date(timestamp).toISOString();
        acc[key] = acc[key] || {};
        acc[key][item.status] = (acc[key][item.status] || 0) + item.count;

        // console.log(acc);
        return acc;
      }, {});

      formattedDateLabels = Object.keys(checkinsAgrupados).map(key => new Date(key.split('_')[0]));

      series = allowedStatus.map(status => {
        const data = formattedDateLabels.map(date => {
          const key = date.toISOString();
          return checkinsAgrupados[key] ? checkinsAgrupados[key][status] || 0 : 0;
        });
        const total = data.reduce((acc, val) => acc + val, 0);
        const isVisible = total > 0;
        // console.log("isVisible = ",isVisible)
        // console.log("total = ",total)
        if (total > 0) {
          return {
            name: status,
            type: 'area',
            data: data,
            color: `${status == "Aguardando" ? "var(--cor-alerta)" :
                      status == "Em andamento" ? "var(--cor-info)" :
                      status == "Realizado" ? "var(--cor-sucesso)" : ""}`,
          };
        } else {
          // return null; // Retorna null para os status com contagem zero
          return {
            name: status,
            type: 'area',
            data: data,
            color: `${status == "Aguardando" ? "var(--cor-alerta)" :
                      status == "Em andamento" ? "var(--cor-info)" :
                      status == "Realizado" ? "var(--cor-sucesso)" : ""}`,
          };
        }
      })
      .filter(series => series !== null) as {
        name: string;
        type: string;
        data: any[];
        color: string;
      }[];
    }

    if (formattedDateLabels.length === 0) {
      formattedDateLabels.push(new Date());
    }


    const picoData = formattedDateLabels.map(date => {
      const picoTotal = this.checkinsPorHora
        .filter(item => new Date(item.timestamp).toISOString() === date.toISOString())
        .reduce((total, item) => total + item.count, 0);
      return picoTotal;
    });

    const picoSeries = {
      name: 'Total',
      type: 'line',
      data: picoData,
      color: 'var(--text-cor-sub)'
    };

    let maxDataValue = Math.max(
      ...series.map(serie => Math.max(...serie.data)),
      // ...picoData
    );
    // console.log("maxDataValue",maxDataValue)
    if(maxDataValue <= 1){
      maxDataValue = maxDataValue+1
    }
    // console.log("maxDataValue",maxDataValue)

    let tickAmountX = formattedDateLabels.length;
    // console.log("tickAmountX",tickAmountX)
    if(tickAmountX <= 1){
      tickAmountX = tickAmountX+1
    }
    // console.log("tickAmountX",tickAmountX)

    // console.log(series);
    // console.log(agendamentosAgrupados);
    // console.log(formattedDateLabels);
    // console.log(formattedDateLabels.map(date => date.getTime()))

    this.chartOptionsCheckin = {
      series: [
        ...series,
        // picoSeries
      ],
      chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true,
          // autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom',
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false, // Desabilita a opção de download/exportação
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            customIcons: []
          }
        },
        defaultLocale: 'br',
        locales: [{
          name: 'br',
          options: {
            months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
            shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            days: ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
            shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
            toolbar: {
              download: 'Baixar SVG',
              selection: 'Seleção',
              selectionZoom: 'Zoom da Seleção',
              zoomIn: 'Aumentar Zoom',
              zoomOut: 'Diminuir Zoom',
              pan: 'Panning',
              reset: 'Redefinir Zoom',
            }
          }
        }]
      },
      plotOptions: {
        // bar: {
        //   horizontal: false,
        //   columnWidth: "5%",
        //   endingShape: "rounded"
        // }
      },
      stroke: {
        show: true,
        width: 3,
        // curve: 'smooth',
        // colors: ["transparent"]
        curve: 'straight',
        // colors: ['var(--cor-alerta)', 'var(--cor-info)', 'var(--cor-sucesso)', 'var(--cor-danger)', 'var(--text-cor-sub)'],
      },
      title: {
        // text: "Agendamentos por Data e Hora"
      },
      dataLabels: {
        enabled: false,
        // enabledOnSeries: [4]
      },
      xaxis: {
        type: 'datetime',
        categories: formattedDateLabels.map(date => date.getTime()),
        labels: {
          rotate: -45
        },
        // position: 'bottom', // Altere para 'bottom'
        tickAmount: tickAmountX,
        panning: {
          enabled: true,
          mode: 'x'
        },
        min: minDate,
        max: maxDate,
      },
      yaxis: [
        {
          show: true,
          showAlways: true,
          title: {
            text: "Quantidade"
          },
          max: maxDataValue,
          tickAmount: maxDataValue,
          forceNiceScale: true,
          tooltip: {
            enabled: true
          }
        },
        // {
        //   show: false,
        //   max: maxDataValue,
        //   tickAmount: maxDataValue,
        //   forceNiceScale: true
        // },
        // {
        //   show: false,
        //   max: maxDataValue,
        //   tickAmount: maxDataValue,
        //   forceNiceScale: true
        // },
        // {
        //   show: false,
        //   max: maxDataValue,
        //   tickAmount: maxDataValue,
        //   forceNiceScale: true
        // },
        // {
        //   opposite: true,
        //   title: {
        //     text: "Total"
        //   },
        //   max: maxDataValue,
        //   tickAmount: maxDataValue,
        //   forceNiceScale: true,
        //   tooltip: {
        //     enabled: true
        //   }
        // }
      ],
      fill: {
        // ... (suas configurações de preenchimento, se necessário)
        // opacity: 1,
        opacity: [0.20, 0.10, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.20,
          opacityTo: 0.0,
          stops: [0, 100, 100, 100]
        },
        // colors: ['', '', '', '', 'var(--background)']
      // colors: ['var(--cor-alerta)', 'var(--cor-info)', 'var(--cor-sucesso)', 'var(--cor-danger)', 'var(--text-cor-sub)'],

      },
      tooltip: {
        // shared: false,
        // intersect: true,
        followCursor: true,
        x: {
          format: 'dd/MM/yyyy HH:mm'
        },
        y: {
          formatter: function (val) {
            return "" + val + "";
          }
        }
      },
      markers: {
        size: 3,
        // colors: ["#000524"],
        // strokeColor: "#00BAEC",
        strokeWidth: 1
      }
    };

    if(bool){
      this.chartCheckin_ = !this.chartCheckin_;
    }

  }

  timestampAgendamento(data: any) {
    // const datateste = new Date();
    // const dia = datateste.getDate();
    // const mes = datateste.getMonth() + 1; // Lembre-se que os meses começam do zero
    // const ano = datateste.getFullYear();

    // // Adiciona um zero à esquerda se o dia ou mês for menor que 10
    // const diaFormatado = dia < 10 ? `0${dia}` : dia;
    // const mesFormatado = mes < 10 ? `0${mes}` : mes;

    // const dataFormatada = `${diaFormatado}/${mesFormatado}/${ano}`;

    const dateString = `${data}:00`;
    // Transforma a string em um formato aceito pelo construtor Date
    const parts = dateString.split(/[\s/:]+/);
    const dateObject = new Date(
      Date.UTC(
        parseInt(parts[2], 10),
        parseInt(parts[1], 10) - 1,
        parseInt(parts[0], 10),
        parseInt(parts[3], 10),
        parseInt(parts[4], 10),
        parseInt(parts[5], 10)
      )
    );

    // console.log(dateString);
    // console.log(dateObject);

    if (!isNaN(dateObject.getTime())) {
      const timestamp = dateObject.getTime();
      // console.log(timestamp);
      return timestamp;
    } else {
      // console.error('Data inválida');
      return new Date().getTime();
    }
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }

}
