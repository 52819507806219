import { Injectable } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import { DiasNaoAgendamento } from 'src/class/dias-nao-agendamento';
import { ToastService } from '../toast.service';
import { plainToClass } from 'class-transformer';
import { LocalhostService } from '../localhost.service';
import { ContaService } from './conta.service';
import { AuthService } from '../auth.service';
import { NotificacaoSistemaService } from './notificacao-sistema.service';

@Injectable({
  providedIn: 'root'
})
export class DiasNaoAgendamentoService {

  private dias: DiasNaoAgendamento[] = [];
  private diasSubject = new BehaviorSubject<DiasNaoAgendamento[]>(this.dias);

  constructor(
    private localhostService: LocalhostService,
    private notificacaoService: NotificacaoSistemaService,
    private toast: ToastService,
    private conta: ContaService,
    private userService: AuthService
  ) {
    this.dias = [];
  }

  async criarDiasNaoAgendamento(dia: DiasNaoAgendamento, empresaSigla: any) {
    // console.log("criarDia", dia);
    dia.descricao.status = null;
    dia.nome.status = null;

    for (let index = 0; index < dia.dias.length; index++) {
      const dia_status = dia.dias[index];
      dia_status.dia.status = null;
      dia_status.descricao.status = null;
    }

    try {
      const conta = await this.conta.p_getSiglaConta('contas', empresaSigla);

      // console.log('Dados da conta: criarDia', conta);

      const response = await this.localhostService.postDadosId(`${conta.id}_dias_nao_agendamento`, dia);
      // console.log('Dia criado com sucesso:', response);

      // Atualize a lista local de agendas
      this.dias.push(dia);
      this.diasSubject.next(this.dias);

      // Notifique o usuário sobre o sucesso
      this.showSuccess('Dias não agendamento criado com sucesso');

      const usr = await this.userService.carregarUsuario(empresaSigla)

      this.notificacaoService.criarNotificacao(
        empresaSigla,                 // 1 - empresaSigla: any,
        `${dia.nome.valor}`,          // 2 - titulo: string,
        `Dia criado.`,                // 3 - texto: string,
        'Listar Dias',                // 4 - rotaSemParamNome: string,
        'dias-nao-agendamento',       // 5 - rotaSemParamListar: string,
        'Editar Dia',                 // 6 - rotaComParamNome: string = "",
        `dias-nao-agendamento/grupo`, // 7 - rotaComParamCaminho: string = "",
        dia.id?.toString(),           // 8 - rotaComParamParam: string = "",
        '',                           // 9 - linkExternoNome: string = "",
        '',                           // 10 - linkExternoURL: string = "",
        dia.id!.toString(),           // 11 - idReferencia: string,
        `Dias não agendamento`,       // 12 - tipo: string,
        `${usr.id}`,                  // 13 - idResponsavel: string,
        `${usr.displayName.valor}`    // 14 - nomeResponsavel: string
      )

      return true;
    } catch (error) {
      // console.error('Erro ao criar dia:', error);
      // Trate o erro conforme necessário
      return false;
    }
  }

  getAllDia() {
    return this.diasSubject.asObservable().pipe(
      map(dias => dias)
    );
  }

  async getDia(empresaSigla: any) {

    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: getDia', conta);

      this.localhostService.getDados(`${conta.id}_dias_nao_agendamento`).subscribe(
        (diaFromServer: any[]) => {
          if (diaFromServer) {
            // Mapeia os dados para a estrutura desejada
            const diaArray: DiasNaoAgendamento[] = diaFromServer.map(dia => plainToClass(DiasNaoAgendamento, dia));

            // console.log(diaArray);

            this.dias = diaArray;
            this.diasSubject.next(this.dias);
            // this.updateLocalStorage();
          } else {
            this.dias = [];
          }
        },
        (error) => {
          // console.error('Erro ao obter dias:', error);
        }
      );
    },
    (error: any) => {
      // console.error('Erro ao obter dias:', error);
      // Trate o erro conforme necessário
    }).catch((error) => {
      // Trate o erro
      // console.log('Erro ao obter dados da conta:', error);
    });


  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
