
<div class="pb-4 min-width">

  <div class="container text-center p-0">

    <!-- 👇 Breadcrumb -->
    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 mb-2 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item">
              <a (click)="routerLink('home')" class="text-padrao"><i class="bi bi-grid position-relative"></i> Dashboard</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">Agendamentos</li>
          </ol>
        </nav>
      </div>
    </div>
    <!-- Breadcrumb 👆 -->

    <!-- 👇 Novo agendamento -->
    <div class="row p-0 m-0">
      <div class=" col-lg-4 mb-3" style=" cursor: pointer; ">
        <button (click)="routerLink('agendamentos/adicionar')" type="button" class="btn btn-sm p-0 m-0 btn-principal-primary">
          <span class="btn-principal-primary-nome">
            Novo agendamento
          </span>
          <span class="btn-principal-primary-icon">
            <i class="bi bi-clock"></i>
          </span>
        </button>
      </div>
    </div>
    <!-- Novo agendamento 👆 -->

    <!-- 👇 Informações / Configurações / Filtro -->
    <div class="row p-0 m-0 mb-3">
      <div class="min-width col-12">
        <div class="p-0 m-0 box-gen-card">

          <!-- Informações / Configurações -->
          <div class="row p-0 m-0">

            <!-- Informações -->
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 p-0 m-0">

              <div class="row p-0 m-0">
                <div class="custom-button-div col-lg-6 col-md-8 col-sm-8 col-8">
                  <h3 class="p-0 m-0 text-cor-padrao text-start w-100 box-gen-card-h3">
                    <span style="height: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: block;min-height: 40px;line-height: 40px;">
                      <span *ngIf="agendamentos.length == 0" title="Nenhum agendamento">
                        Nenhum agendamento
                      </span>
                      <span *ngIf="agendamentos.length > 0" [title]="agendamentos.length + ' ' + (agendamentos.length === 1 ? 'Agendamento' : 'Agendamentos')">
                        <span class="box-gen-card-h3-span">{{agendamentos.length}}</span> {{agendamentos.length == 1 ? "Agendamento" : "Agendamentos"}}
                      </span>
                    </span>
                  </h3>
                </div>
                <div class="custom-button-div col-lg-6 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" (click)="reload()" title="Atualizar">
                    Atualizar <i class="bi bi-arrow-clockwise ag-pl-10"></i>
                  </button>
                </div>
              </div>




              <!-- <div class="p-0 m-0 text-cor-padrao text-start w-100 box-gen-card-h3">
                <ng-select  id="id"
                            placeholder="AgPlaceholder"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            [(ngModel)]="statusSelecionados"
                            style=" padding: 0 !important; margin: 0 !important; ">
                  <ng-option *ngFor="let item of statusAgendamentoArray" [value]="item" style=" padding: 0 !important; margin: 0 !important; ">
                    {{item}}
                  </ng-option>
                </ng-select>
              </div> -->

            </div>

            <!-- Configurações -->
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 p-0 m-0">

              <div class="custom-button-group row">

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" mat-icon-button [matMenuTriggerFor]="dataAgendamentoButton" aria-label="order" [title]="dataAgendamento">
                    {{dataAgendamento}}
                    <i class="bi bi-calendar-date ag-pl-10" *ngIf="dataAgendamento == 'Hoje'"></i>
                    <i class="bi bi-calendar-event ag-pl-10" *ngIf="dataAgendamento == 'Futuros'"></i>
                    <i class="bi bi-calendar-event inverter ag-pl-10" *ngIf="dataAgendamento == 'Passados'"></i>
                    <i class="bi bi-calendar-week ag-pl-10" *ngIf="dataAgendamento == 'Todos'"></i>
                  </button>
                  <mat-menu #dataAgendamentoButton="matMenu">
                    <button mat-menu-item (click)="agendamentosDia()">
                      <i class="bi bi-calendar-date ag-pr-10"></i>
                      <span>Hoje</span>
                    </button>
                    <button mat-menu-item (click)="agendamentosFuturos()">
                      <i class="bi bi-calendar-event ag-pr-10"></i>
                      <span>Futuros</span>
                    </button>
                    <button mat-menu-item (click)="agendamentosPassados()">
                      <i class="bi bi-calendar-event inverter ag-pr-10"></i>
                      <span>Passados</span>
                    </button>
                    <button mat-menu-item (click)="allAgendamentos()">
                      <i class="bi bi-calendar-week ag-pr-10"></i>
                      <span>Todos</span>
                    </button>
                  </mat-menu>
                </div>

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" mat-icon-button [matMenuTriggerFor]="statusButton" aria-label="order" [title]='status == "" ? "Todos os Status" : status'>
                    <i class="bi bi-circle-fill ag-mr-5" [ngClass]="{
                      'text-cor-alerta': status === 'Confirmado Cliente',
                      'text-cor-info': status === 'Confirmado Administrador',
                      'text-cor-sucesso': status === 'Atendido',
                      'text-cor-danger': status === 'Não Atendido'
                      }" style=" color: var(--lw-cor-s); font-size: 12px; "></i> {{status == "" ? "Todos os Status" : status}}
                  </button>
                  <mat-menu #statusButton="matMenu">
                    <button mat-menu-item (click)="statusPor('')">
                      <i class="bi bi-circle-fill" style=" color: var(--lw-cor-s); font-size: 13px; "></i>
                      <span class="ag-ml-10">Todos os Status</span>
                    </button>
                    <button mat-menu-item (click)="statusPor(statusAgendamento.confirmadoCliente)">
                      <i class="bi bi-circle-fill ag-pr-10 text-cor-alerta" style="font-size: 13px; "></i>
                      <span>{{statusAgendamento.confirmadoCliente}}</span>
                    </button>
                    <button mat-menu-item (click)="statusPor(statusAgendamento.confirmadoAdm)">
                      <i class="bi bi-circle-fill ag-pr-10 text-cor-info" style="font-size: 13px; "></i>
                      <span>{{statusAgendamento.confirmadoAdm}}</span>
                    </button>
                    <button mat-menu-item (click)="statusPor(statusAgendamento.atendido)">
                      <i class="bi bi-circle-fill ag-pr-10 text-cor-sucesso" style="font-size: 13px; "></i>
                      <span>{{statusAgendamento.atendido}}</span>
                    </button>
                    <button mat-menu-item (click)="statusPor(statusAgendamento.naoAtendido)">
                      <i class="bi bi-circle-fill ag-pr-10 text-cor-danger" style="font-size: 13px; "></i>
                      <span>{{statusAgendamento.naoAtendido}}</span>
                    </button>
                  </mat-menu>
                </div>

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" mat-icon-button [matMenuTriggerFor]="orderButton" aria-label="order" [title]="classificado">
                    {{classificado}} <i class="bi bi-filter ag-pl-10"></i>
                  </button>
                  <mat-menu #orderButton="matMenu">
                    <button mat-menu-item (click)="ordenadoPor('Maior Data/Horário')">
                      <i class="bi bi-sort-up ag-pr-10"></i>
                      <span>Maior Data/Horário</span>
                    </button>
                    <button mat-menu-item (click)="ordenadoPor('Menor Data/Horário')">
                      <i class="bi bi-sort-down ag-pr-10"></i>
                      <span>Menor Data/Horário</span>
                    </button>
                  </mat-menu>
                </div>

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" mat-icon-button [matMenuTriggerFor]="modoButton" aria-label="modo" [title]="modoView == true ? 'Tabela' : 'Card'">
                    {{modoView == true ? 'Tabela' : 'Card'}}
                    <i *ngIf="modoView" class="bi bi-table ag-pl-10"></i>
                    <i *ngIf="!modoView" class="bi bi-card-text ag-pl-10"></i>
                  </button>
                  <mat-menu #modoButton="matMenu">
                    <button mat-menu-item (click)="modoTabela()">
                      <i class="bi bi-table ag-pr-10"></i>
                      <span>Tabela</span>
                    </button>
                    <button mat-menu-item (click)="modoCard()">
                      <i class="bi bi-card-text ag-pr-10"></i>
                      <span>Card</span>
                    </button>
                  </mat-menu>
                </div>

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" (click)="openCalendario()" title="Calendário">
                    Calendário <i class="bi bi-calendar-range ag-pl-10"></i>
                  </button>
                </div>

                <div class="custom-button-div col-lg-2 col-md-4 col-sm-4 col-4">
                  <button class="custom-button" #filtro [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_'" aria-expanded="false" [attr.aria-controls]="'collapse_'" title="Filtro">
                    Filtro <i class="bi bi-search ag-pl-10" title="expand" ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Filtro -->
          <div [id]="'collapse_'" class="accordion-collapse collapse col-lg-12 mt-1">
            <div class="p-0 m-0" >
              <div *ngIf="agendamentos.length > 0" style="position: relative;">
                <div class="form-floating w-100 ">
                  <input type="text" class="form-control input-text-padrao m-0 search-box-result input-text-padrao" id="filtrarAgendamentoSearch" [(ngModel)]="pesquisa" (keydown)="searchAgendamento()" (keyup)="searchAgendamento()" (keypress)="searchAgendamento()" placeholder="&nbsp;">
                  <label for="filtrarAgendamentoSearch" class="label-input-text-padrao ag-pt-18 ag-pb-18 ag-pl-12 ag-pr-12">Filtrar agendamento ...</label>
                </div>
                <i *ngIf="pesquisa != ''" class="bi bi-x-circle-fill text-secondary reset-search-usr" (click)="resetSearch()"></i>
              </div>
              <div *ngIf="pesquisa != ''" style=" display: flex; margin-top: 10px; padding-left: 10px; ">
                <span>Filtando {{filtrado}} de {{agendamentos.length}}</span>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>
    <!-- Informações / Configurações / Filtro 👆 -->

    <!-- 👇 Modo Card -->
    <div class="row p-0 m-0" *ngIf="!modoView">
      <div class="min-width col-lg-12 pb-5 pt-1">

        <!-- Loading -->
        <div class="row" *ngIf="agendamentos.length == 0 && loading && modoViewNum == 12">
          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" >
            <div class="p-0 m-0 pulsate" >
            </div>
          </div>
        </div>

        <!-- Card -->
        <div class="row" *ngIf="agendamentos.length > 0 && !loading">

          <div [class]="'col-lg-'+modoViewNum+ ' mb-2'" *ngFor="let agt of displayedAgendamentos | SearchAgendamento: pesquisa">

            <div class="card card-padrao-list p-0 m-0">

              <div class="card-body">
                <div class="row card-body-row">

                  <!-- Visivel inicialmente -->

                  <div class="col-lg-11 mb-1">
                    <div class="card-body-row-div">
                      <div class="card-padrao-list-box">

                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center" style="flex-wrap: wrap">
                          <ng-container *ngFor="let agenda of agendas">
                            <ng-container *ngIf="agenda.id == agt.agendaId">
                              <span class="m-0 p-0 ag-mr-10 ag-mb-5 text-start background-cor-secundaria" title="Agenda" style="border-radius: 10px !important;padding-left: 8px !important;padding-right: 8px !important;padding-top: 1px !important;color: var(--cor-branco);font-size: 9px !important;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row;">
                                <span style="line-height: 17px;">{{agenda.nome.valor}}</span>
                              </span>
                            </ng-container>
                          </ng-container>

                          <ng-container *ngFor="let agenda of agendas">
                            <ng-container *ngFor="let tipo of tipos">
                              <ng-container *ngIf="tipo.id == agenda.tipoId && agenda.id == agt.agendaId">
                                <span class="m-0 p-0 ag-mr-10 ag-mb-5 text-start" title="Tipo" style="background: var(--background);border-radius: 10px !important;padding-left: 8px !important;padding-right: 8px !important;padding-top: 1px !important;color: var(--text-cor-padrao);font-size: 9px !important;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row;">
                                  <span style="line-height: 17px;">{{tipo.nome.valor}}</span>
                                </span>
                              </ng-container>
                            </ng-container>
                          </ng-container>


                          <span class="m-0 p-0 ag-mr-10 ag-mb-5 text-start" title="Status" style="border-radius: 10px !important;padding-left: 8px !important;padding-right: 8px !important;padding-top: 1px !important;color: var(--cor-branco);font-size: 9px !important;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row;"
                                  [ngClass]="{
                                    'background-cor-alerta': agt.status.valor === 'Confirmado Cliente',
                                    'background-cor-info': agt.status.valor === 'Confirmado Administrador',
                                    'background-cor-sucesso': agt.status.valor === 'Atendido',
                                    'background-cor-danger': agt.status.valor === 'Não Atendido'
                                    }"
                              >
                              <span [innerHTML]="agt.status.valor | filtroDestaque: pesquisa" style="line-height: 17px;"></span>
                          </span>

                          <span class="m-0 p-0 ag-mr-10 ag-mb-5 text-start" title="Info" *ngIf="agt.statusExecucao.valor != ''" style="background: var(--background);border-radius: 10px !important;padding-left: 8px !important;padding-right: 8px !important;padding-top: 1px !important;color: var(--text-cor-padrao);font-size: 9px !important;font-weight: bold;text-transform: uppercase;letter-spacing: 1px;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;flex-direction: row;">
                              <span [innerHTML]="agt.statusExecucao.valor | filtroDestaque: pesquisa" style="line-height: 17px;"></span>
                          </span>
                          <!-- <div class="progress-container" [innerHTML]="agt.status.valor | stepProgress" style="margin-top: 35px;padding-top: 35px;box-shadow: rgba(27, 31, 35, 0.04) 0px -1px 0px, rgba(255, 255, 255, 0.25) 0px -10px 0px inset;"></div> -->
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 mb-1">
                    <div class="card-body-row-div">
                      <div class="card-padrao-list-box">

                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center ">
                          <div class="card-body-time p-0 m-0 justify-content-start" style="font-size: 13px;">
                            <span class="ag-pr-5">
                              Dia
                            </span>
                            <span>
                              <i class="bi bi-calendar-event ag-pr-5 d-none"></i>
                              <span [innerHTML]="agt.dataAtendimento.valor | filtroDestaque: pesquisa" style=" font-weight: 600; "></span>
                              <!-- {{agt.dataAtendimento.valor}} -->
                            </span>
                            <span class="ag-pl-5 ag-pr-5">
                              das
                            </span>
                            <span>
                              <i class="bi bi-clock ag-pr-5 d-none"></i>
                              <span [innerHTML]="agt.hora_inicial.valor | filtroDestaque: pesquisa" style=" font-weight: 600; "></span>
                              <!-- {{agt.hora_inicial.valor}} -->
                            </span>
                            <span class="ag-pl-5 ag-pr-5">
                              às
                            </span>
                            <span>
                              <i class="bi bi-clock-history ag-pr-5 d-none"></i>
                              <span [innerHTML]="agt.hora_final.valor | filtroDestaque: pesquisa" style=" font-weight: 600; "></span>
                              <!-- {{agt.hora_final.valor}} -->
                            </span>
                          </div>
                        </div>

                        <div class="d-flex justify-content-start flex-row align-items-center" style=" text-align: left; display: block !important; width: 100%; ">
                          <span class="m-0 p-0 ag-mr-10 text-start card-padrao-list-img-title" style=" font-weight: 500; ">
                            NF
                          </span>
                          <span class="card-padrao-list-img-title p-0 m-0" [innerHTML]="agt.notaFiscal.valor | filtroDestaque: pesquisa" style=" font-weight: 500; ">
                            <!-- {{agt.nomeMotorista.valor}} -->
                          </span>
                        </div>

                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">
                            {{agt.numeroPedido.title}}
                          </span>
                          <span class="card-padrao-list-box-nobg-value" [innerHTML]="agt.numeroPedido.valor | filtroDestaque: pesquisa">
                            <!-- {{agt.numeroPedido.valor}} -->
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 mb-1">
                    <div class="card-padrao-list-box">

                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center ">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          {{agt.nomeMotorista.title}}
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="agt.nomeMotorista.valor | filtroDestaque: pesquisa">
                          <!-- {{agt.duracaoAtendimento.valor}} -->
                        </span>
                      </div>

                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center ">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          {{agt.telefoneMotorista.title}}
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="agt.telefoneMotorista.valor | filtroDestaque: pesquisa">
                          <!-- {{agt.duracaoAtendimento.valor}} -->
                        </span>
                      </div>

                      <div class="d-flex justify-content-start flex-row align-items-center ">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          {{agt.emailMotorista.title}}
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="agt.emailMotorista.valor | filtroDestaque: pesquisa">
                          <!-- {{agt.duracaoAtendimento.valor}} -->
                        </span>
                      </div>



                    </div>
                  </div>

                  <div class="col-lg-4 mb-1">
                    <div class="card-padrao-list-box">

                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          {{agt.placaVeiculo.title}}
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="agt.placaVeiculo.valor | filtroDestaque: pesquisa">
                          <!-- {{agt.placaVeiculo.valor}} -->
                        </span>
                      </div>

                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          {{agt.fornecedor.title}}
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="agt.fornecedor.valor | filtroDestaque: pesquisa">
                          <!-- {{agt.fornecedor.valor}} -->
                        </span>
                      </div>

                      <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          {{agt.duracaoAtendimento.title}}
                        </span>
                        <span class="card-padrao-list-box-nobg-value" [innerHTML]="agt.duracaoAtendimento.valor | filtroDestaque: pesquisa">
                          <!-- {{agt.fornecedor.valor}} -->
                        </span>
                      </div>


                      <div class="mb-1 d-none justify-content-start flex-row align-items-center ">
                        <button type="button" class="btn btn-outline-secondary m-0 p-0" style="display: flex; justify-content: space-around; padding: 2px !important;" >
                          Ver agenda
                        </button>
                      </div>


                    </div>

                  </div>

                  <!-- Informações ocultas -->
                  <div [id]="'collapse_'+agt.id" class="accordion-collapse collapse col-12 mt-2">
                    <div class="row card-body-row-oculta">
                      <div class="col-lg-4 d-flex flex-column justify-content-start flex-wrap">

                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{agt.cpfMotorista.title}}</span>
                          <span class="card-padrao-list-box-nobg-value">{{agt.cpfMotorista.valor}}</span>
                        </div>

                        <!-- <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{agt.telefoneMotorista.title}}</span>
                          <span class="card-padrao-list-box-nobg-value">{{agt.telefoneMotorista.valor}}</span>
                        </div>
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{agt.emailMotorista.title}}</span>
                          <span class="card-padrao-list-box-nobg-value">{{agt.emailMotorista.valor}}</span>
                        </div> -->

                      </div>

                      <!-- <div class="col-lg-8 d-flex flex-column justify-content-start flex-wrap">
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{agt.duracaoAtendimento.title}}</span>
                          <span class="card-padrao-list-box-nobg-value">{{agt.duracaoAtendimento.valor}}</span>
                        </div>
                      </div> -->


                      <div class="col-lg-12 d-flex flex-column justify-content-start flex-wrap card-body-row-oculta mt-2" *ngIf="agt.observacaoCliente && agt.observacaoCliente.valor != ''">
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center" style="flex-direction: column !important; align-items: flex-start !important;">
                          <span class="m-0 p-0 ag-ml-1 ag-mb-5 text-start">{{agt.observacaoCliente.title}}</span>
                          <span class="card-padrao-list-box-nobg-value ag-pl-1">{{agt.observacaoCliente.valor}}</span>
                        </div>
                      </div>

                      <div class="col-lg-12 d-flex flex-column justify-content-start flex-wrap card-body-row-oculta mt-2" *ngIf="agt.observacao && agt.observacao.valor != ''">
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center" style="flex-direction: column !important; align-items: flex-start !important;">
                          <span class="m-0 p-0 ag-ml-1 ag-mb-5 text-start">{{agt.observacao.title}}</span>
                          <span class="card-padrao-list-box-nobg-value ag-pl-1">{{agt.observacao.valor}}</span>
                        </div>
                      </div>
                      <!-- <div class="col-lg-7 d-flex flex-column justify-content-start flex-wrap">
                        <div class="mb-1 d-flex justify-content-start flex-row align-items-center">
                          <span class="m-0 p-0 ag-mr-10 text-start">{{agt.duracaoAtendimento.title}}</span>
                          <span class="card-padrao-list-box-nobg-value">{{agt.duracaoAtendimento.valor}}</span>
                        </div>
                      </div> -->
                    </div>
                  </div>



                </div>

                <div class="card-padrao-footer-div-menu">
                  <i class="bi bi-three-dots" title="menu" mat-icon-button [matMenuTriggerFor]="editarAgendamento_i"></i>
                  <mat-menu #editarAgendamento_i="matMenu">
                    <button mat-menu-item (click)="routerLink('agendamentos/agendamento', agt.id)">
                      <i class="bi bi-pencil-square ag-pr-10 text-cor-primaria"></i>
                      <span>Editar</span>
                    </button>
                    <button *ngIf="agt.status.valor != statusAgendamento.atendido && agt.status.valor != statusAgendamento.naoAtendido && agt.status.valor != statusAgendamento.confirmadoAdm" mat-menu-item (click)="atualizarStatusAgendamento(agt.id, statusAgendamento.confirmadoAdm)">
                      <i class="bi bi-circle-fill ag-pr-10 text-cor-info"></i>
                      <span>{{statusAgendamento.confirmadoAdm}}</span>
                    </button>
                    <button *ngIf="agt.status.valor != statusAgendamento.atendido && agt.status.valor != statusAgendamento.naoAtendido" mat-menu-item (click)="atualizarStatusAgendamento(agt.id, statusAgendamento.atendido)">
                      <i class="bi bi-circle-fill ag-pr-10 text-cor-sucesso"></i>
                      <span>Atender</span>
                    </button>
                    <button *ngIf="agt.status.valor != statusAgendamento.atendido && agt.status.valor != statusAgendamento.naoAtendido" mat-menu-item (click)="atualizarStatusAgendamento(agt.id, statusAgendamento.naoAtendido)">
                      <i class="bi bi-circle-fill ag-pr-10 text-cor-danger"></i>
                      <span>Não Atender</span>
                    </button>
                    <button mat-menu-item (click)="deletarAgendamento(agt.id)" *ngIf="agt.status.valor != statusAgendamento.atendido">
                      <i class="bi bi-trash3 ag-pr-10 text-cor-danger"></i>
                      <span>Deletar</span>
                    </button>
                  </mat-menu>
                  <i class="bi bi-chevron-expand" title="expand" [attr.data-bs-toggle]="'collapse'" [attr.data-bs-target]="'#collapse_' + agt.id" aria-expanded="false" [attr.aria-controls]="'collapse_' + agt.id"></i>

                </div>
              </div>
            </div>

          </div>

          <!-- Mensagem do filtro caso não encontre -->
          <div *ngIf="displayedAgendamentos.length == 0 && pesquisa != ''">
            <h3 class="text-cor-padrao mt-3">Nenhum dado encontrado que corresponda ao filtro <span class="card-padrao-list-span-pesquisa">{{pesquisa}}</span></h3>
          </div>

          <!-- Páginação -->
          <div class="col-12 mt-3  ">
            <div class="box-gen-card" style=" padding: 7px 0px !important; ">
              <mat-paginator
              [length]="agendamentos.length"
              [pageSize]="maxSize"
              [pageSizeOptions]="[5, 10, 25, 50]"
              [showFirstLastButtons]="true"
              (page)="handlePage($event)"
            ></mat-paginator>
            </div>

          </div>

        </div>

      </div>
    </div>
    <!-- Modo Card -->

    <!-- 👇 Modo Tabela -->
    <div class="row p-0 m-0" *ngIf="modoView">

      <!-- Loading -->
      <div class="min-width col-lg-12 pb-5 pt-3" *ngIf="agendamentos.length == 0 && loading">
        <div class="p-0 m-0 pulsate box-gen-card">
        </div>
      </div>


      <div class="min-width col-lg-12 pb-5 pt-1" >
        <div class="p-0 m-0 box-gen-card mb-3" *ngIf="agendamentos.length > 0 && !loading">

          <!-- Tabela -->
          <div class="w-100 overflow-auto">
            <table id="excel-table" class="mb-1 mt-1 table table-responsive table-hover table-sm" [ngClass]="{'table-dark': !lightMode,'table-light': lightMode}" matSort (matSortChange)="sortData($event)">
              <thead style=" height: 40px; vertical-align: middle; " *ngIf="displayedAgendamentos.length > 0">
                  <tr>
                    <th class="text-cor-padrao ag-pl-15" mat-sort-header="id" style="min-width: 55px;border-right: 1px solid var(--border-cor);">ID</th>
                    <th class="text-cor-padrao" mat-sort-header="logradouro" style="text-align: left;min-width: 180px;border-right: 1px solid var(--border-cor);">Nome Motorista</th>
                    <th class="text-cor-padrao" mat-sort-header="cep" style="min-width: 100px;border-right: 1px solid var(--border-cor);">Placa</th>
                    <th class="text-cor-padrao" mat-sort-header="numero" style="min-width: 180px;border-right: 1px solid var(--border-cor);">Fornecedor</th>
                    <th class="text-cor-padrao" mat-sort-header="bairro" style="min-width: 100px;border-right: 1px solid var(--border-cor);">Nota Fiscal</th>
                    <th class="text-cor-padrao" mat-sort-header="localidade" style="min-width: 160px;border-right: 1px solid var(--border-cor);">Número do Pedido</th>
                    <th class="text-cor-padrao" mat-sort-header="uf" style="min-width: 100px;border-right: 1px solid var(--border-cor);">Data e Hora</th>
                    <th class="text-cor-padrao text-align-start" mat-sort-header="uf" style="min-width: 140px;">Status</th>
                    <!-- <th class="text-cor-padrao" mat-sort-header="uf" >Hora Final</th> -->
                    <th class="text-cor-padrao sticky-col" style=" text-align: center;" >&nbsp;</th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="text-cor-padrao ag-table-tr" *ngFor="let agt of displayedAgendamentos | SearchAgendamento: pesquisa">
                    <td class="ag-table-td ag-pl-15" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="agt.id | filtroDestaque: pesquisa">
                        <!-- {{agt.id | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="agt.nomeMotorista.valor | filtroDestaque: pesquisa">
                        <!-- {{agt.nomeMotorista.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="agt.placaVeiculo.valor | filtroDestaque: pesquisa">
                        <!-- {{agt.placaVeiculo.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="agt.fornecedor.valor | filtroDestaque: pesquisa">
                        <!-- {{agt.fornecedor.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="agt.notaFiscal.valor | filtroDestaque: pesquisa">
                        <!-- {{agt.notaFiscal.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div [innerHTML]="agt.numeroPedido.valor | filtroDestaque: pesquisa">
                        <!-- {{agt.numeroPedido.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div >
                        <span [innerHTML]="agt.dataAtendimento.valor | filtroDestaque: pesquisa" class="ag-pr-5">,</span>
                        <span [innerHTML]="agt.hora_inicial.valor | filtroDestaque: pesquisa" class="ag-pr-5"></span>
                        <span [innerHTML]="agt.hora_final.valor | filtroDestaque: pesquisa" class="ag-pr-5"></span>
                        <!-- {{agt.dataAtendimento.valor | filtroDestaque: pesquisa}} {{agt.hora_inicial.valor | filtroDestaque: pesquisa}} - {{agt.hora_final.valor | filtroDestaque: pesquisa}} -->
                      </div>
                    </td>
                    <td class="ag-table-td" style="border-right: 1px solid var(--border-cor);">
                      <div >
                        <span class="m-0 p-0 ag-mr-10 text-start">
                          <i class="bi bi-circle-fill ag-pr-5" [ngClass]="{
                            'text-cor-alerta': agt.status.valor === 'Confirmado Cliente',
                            'text-cor-info': agt.status.valor === 'Confirmado Administrador',
                            'text-cor-sucesso': agt.status.valor === 'Atendido',
                            'text-cor-danger': agt.status.valor === 'Não Atendido'
                            }"></i>
                          <span [innerHTML]="agt.status.valor | filtroDestaque: pesquisa"></span>
                          <!-- {{agt.status.valor | filtroDestaque: agt.status.valor}} -->
                      </span>
                      </div>
                    </td>
                    <!-- <td class="ag-table-td">
                      <div >
                        {{agt.hora_final.valor}}
                      </div>
                    </td> -->
                    <td class="ag-table-td sticky-col">
                      <button mat-icon-button [matMenuTriggerFor]="editarAgendamento" aria-label="modo" class="btn-outline-primary sticky-col-buttom">
                        <i class="bi bi-three-dots"></i>
                      </button>
                      <mat-menu #editarAgendamento="matMenu">
                        <button mat-menu-item (click)="routerLink('agendamentos/agendamento', agt.id)">
                          <i class="bi bi-pencil-square ag-pr-10 text-cor-primaria"></i>
                          <span>Editar</span>
                        </button>
                        <button *ngIf="agt.status.valor != statusAgendamento.atendido && agt.status.valor != statusAgendamento.naoAtendido && agt.status.valor != statusAgendamento.confirmadoAdm" mat-menu-item (click)="atualizarStatusAgendamento(agt.id, statusAgendamento.confirmadoAdm)">
                          <i class="bi bi-circle-fill ag-pr-10 text-cor-info"></i>
                          <span>{{statusAgendamento.confirmadoAdm}}</span>
                        </button>
                        <button *ngIf="agt.status.valor != statusAgendamento.atendido && agt.status.valor != statusAgendamento.naoAtendido" mat-menu-item (click)="atualizarStatusAgendamento(agt.id, statusAgendamento.atendido)">
                          <i class="bi bi-circle-fill ag-pr-10 text-cor-sucesso"></i>
                          <span>{{statusAgendamento.atendido}}</span>
                        </button>
                        <button *ngIf="agt.status.valor != statusAgendamento.atendido && agt.status.valor != statusAgendamento.naoAtendido" mat-menu-item (click)="atualizarStatusAgendamento(agt.id, statusAgendamento.naoAtendido)">
                          <i class="bi bi-circle-fill ag-pr-10 text-cor-danger"></i>
                          <span>{{statusAgendamento.naoAtendido}}</span>
                        </button>
                        <button mat-menu-item (click)="deletarAgendamento(agt.id)">
                          <i class="bi bi-trash3 ag-pr-10 text-cor-danger"></i>
                          <span>Deletar</span>
                        </button>
                      </mat-menu>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>

          <!-- Mensagem do filtro caso não encontre -->
          <div *ngIf="displayedAgendamentos.length == 0 && pesquisa != ''">
            <h3 class="text-cor-padrao">Nenhum dado encontrado que corresponda ao filtro <span class="card-padrao-list-span-pesquisa">{{pesquisa}}</span></h3>
          </div>

          <!-- Páginação -->
          <div class="text-right row pagination-box-result" *ngIf="agendamentos.length > 0">
            <div class="col-12 p-0 m-0">
              <mat-paginator
                [length]="agendamentos.length"
                [pageSize]="maxSize"
                [pageSizeOptions]="[5, 10, 25, 50]"
                [showFirstLastButtons]="true"
                (page)="handlePage($event)"
              ></mat-paginator>
            </div>
          </div>

        </div>
      </div>

    </div>
    <!-- Modo Tabela 👆 -->

    <!-- 👇 Links Relacionados -->
    <!-- <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-5 pb-3 text-start">
        <hr class="w-100 hr-padrao">
        <p class="p-0 m-0 text-cor-padrao d-flex align-items-center" style="font-weight: 500;letter-spacing: 0.5px;">Links Relacionados <i class="bi bi-link-45deg  text-background-clip" style=" padding-left: 5px; "></i></p>
        <hr class="w-100 hr-padrao">
        <mat-chip-listbox aria-label="Geradores">
          <div *ngFor="let card of linksAgenda">
            <mat-chip (click)="routerLink(card.rota!.toString())" *ngIf="card.tipo != 'agenda-enderecos'" class="mat-chip-val" data-bs-toggle="tooltip" [title]="card.subTitle">
              {{card.title}}
            </mat-chip>
          </div>
        </mat-chip-listbox>
      </div>
    </div> -->
    <!-- Links Relacionados 👆 -->


  </div>
</div>

<!-- Motivo Não Atendido Modal -->
<div class="modal" id="naoAtendidoModal" #naoAtendidoModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0"><i class="bi bi-calendar-event"></i> Observação</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" style="padding: 5px !important;">

        <div class="w-100 mb-0 mt-2" style="text-align: left; ">

          <div class="row p-0 m-0">
            <!-- servico.nome -->
            <div class="col-lg-12">
              <app-ag-textarea-field
                [(ngModel)]="naoAtendidoObj.observacao.valor"
                [label]="naoAtendidoObj.observacao.title"
                [tooltip]="naoAtendidoObj.observacao.info"
                [value]="naoAtendidoObj.observacao.valor"
                [isInvalid]="naoAtendidoObj.observacao.status?.invalid"
                [AgStatus]="naoAtendidoObj.observacao.status"
                [id]="'nao_atendido_observacao'"
                AgPlaceholder="{{naoAtendidoObj.observacao.placeholder}}"
                AgInputType="{{naoAtendidoObj.observacao.inputType}}"
                AgMaxlength="{{naoAtendidoObj.observacao.maxlength}}"
                AgInputMode="{{naoAtendidoObj.observacao.inputMode}}"
                (ngModelChange)="naoAtendidoObj.mascara(naoAtendidoObj.observacao.mascara, 'observacao')"
                (change)="naoAtendidoObj.validarInput('observacao')"
                >
              </app-ag-textarea-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >Fechar</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="naoAtendido(naoAtendidoObj.id)">Confirmar</button>
      </div>

    </div>
  </div>
</div>

<!-- Calendario Modal -->
<div class="modal" id="calendarioModal" #calendarioModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0"><i class="bi bi-calendar-event"></i> Calendário</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" (click)="resetEventosCalendario()" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body" style=" padding-top: 0; margin-top: 0.5rem; ">
        <div class="calendar">
          <div style="margin-bottom: 0.5rem;display: flex;flex-direction: row;align-items: center;justify-content: space-between;flex-wrap: nowrap;margin-right: 10px;">
            <app-ag-ng-select *ngIf="!loading"
              style="width: 80%;min-width: 80% !important;max-width: 80%;"
              [(ngModel)]="agendaSelecionada"
              label="Selecione uma agenda"
              [id]="'agendaDisponivel'"
              tooltip="Selecione uma agenda"
              [AgPlaceholder]="'Selecione uma agenda...'"
              [AgMultiple]="false"
              [AgCloseOnSelect]="true"
              [AgOptions]="agendas"
              AgSelectType="agenda"
              [AgAppendTo]="'#calendarioModal'"
              >
            </app-ag-ng-select>
            <button type="button" class="btn btn-primary" (click)="carregarCalendario()" style="height: 44px;position: relative;top: 7px;margin: 10px 0px 0px 10px;display: flex;align-items: center;justify-content: center;width: 20%;min-width: 20% !important;max-width: 20%;">Carregar Calendário</button>
          </div>
          <full-calendar
            #fullcalendar
            [options]="calendarOptions"
            [deepChangeDetection]="true"
          >
          </full-calendar>


      </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="resetEventosCalendario()">Fechar</button>
      </div>

    </div>
    </div>
  </div>
</div>

<!-- Informação do Agendamento Modal -->
<div class="modal" id="infoAgendamentoModal" #infoAgendamentoModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0"><i class="bi bi-clock"></i> Informações do Agendamento {{objInfoAgendamento.id}}</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" (click)="openCalendario()" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body" style=" padding-top: 0; margin-top: 0.5rem; ">

        <div>
          <div style="display: flex;flex-direction: column;align-items: flex-start;margin-bottom: 20px !important;width: 100%;">
            <p class="text-cor-padrao p-0 m-0 pb-2">Agenda</p>
            <p class="text-cor-padrao" style="font-weight: 600;text-align: left;" *ngIf="objInfoAgenda?.nome?.valor != undefined">{{objInfoAgenda.nome.valor}}</p>

            <hr class="hr-padrao w-100">

            <div class="w-100 row">
              <div class="col-lg-3" style="display: flex; flex-direction: column; align-items: flex-start;">
                <p class="text-cor-padrao p-0 m-0 pb-2">{{objInfoAgendamento.dataAtendimento.title}}</p>
                <p class="text-cor-padrao" style="font-weight: 600;text-align: left;">{{objInfoAgendamento.dataAtendimento.valor}}</p>
              </div>
              <div class="col-lg-3" style="display: flex; flex-direction: column; align-items: flex-start;">
                <p class="text-cor-padrao p-0 m-0 pb-2">{{objInfoAgendamento.hora_inicial.title}} e {{objInfoAgendamento.hora_final.title}}</p>
                <p class="text-cor-padrao" style="font-weight: 600;text-align: left;">{{objInfoAgendamento.hora_inicial.valor}} - {{objInfoAgendamento.hora_final.valor}}</p>
              </div>
              <div class="col-lg-3" style="display: flex; flex-direction: column; align-items: flex-start;">
                <p class="text-cor-padrao p-0 m-0 pb-2">{{objInfoAgendamento.status.title}}</p>
                <p class="text-cor-padrao" style="font-weight: 600;text-align: left;">{{objInfoAgendamento.status.valor}}</p>
              </div>
            </div>

            <hr class="hr-padrao w-100" *ngIf="objInfoAgendamento.observacao.valor">

            <div class="w-100 row" *ngIf="objInfoAgendamento.observacao.valor">
              <div class="col-12" style=" display: flex; flex-direction: column; align-items: flex-start; ">
                <p class="text-cor-padrao p-0 m-0 pb-2">{{objInfoAgendamento.observacao.title}}</p>
                <p class="text-cor-padrao" style="font-weight: 600;text-align: left;">{{objInfoAgendamento.observacao.valor ? objInfoAgendamento.observacao.valor : '--'}}</p>
              </div>
            </div>

            <hr class="hr-padrao w-100">

            <div class="w-100 row">

              <div class="col-lg-3" style=" display: flex; flex-direction: column; align-items: flex-start; ">
                <p class="text-cor-padrao p-0 m-0 pb-2">{{objInfoAgendamento.nomeMotorista.title}}</p>
                <p class="text-cor-padrao" style="font-weight: 600;text-align: left;">{{objInfoAgendamento.nomeMotorista.valor ? objInfoAgendamento.nomeMotorista.valor : '--'}}</p>
              </div>

              <div  class="col-lg-3" style=" display: flex; flex-direction: column; align-items: flex-start; ">
                <p class="text-cor-padrao p-0 m-0 pb-2">{{objInfoAgendamento.cpfMotorista.title}}</p>
                <p class="text-cor-padrao" style="font-weight: 600;text-align: left;">{{objInfoAgendamento.cpfMotorista.valor ? objInfoAgendamento.cpfMotorista.valor : '--'}}</p>
              </div>

              <div class="col-lg-3" style=" display: flex; flex-direction: column; align-items: flex-start; ">
                <p class="text-cor-padrao p-0 m-0 pb-2">{{objInfoAgendamento.telefoneMotorista.title}}</p>
                <p class="text-cor-padrao" style="font-weight: 600;text-align: left;">{{objInfoAgendamento.telefoneMotorista.valor ? objInfoAgendamento.telefoneMotorista.valor : '--'}}</p>
              </div>

              <div class="col-lg-3" style=" display: flex; flex-direction: column; align-items: flex-start; ">
                <p class="text-cor-padrao p-0 m-0 pb-2">{{objInfoAgendamento.emailMotorista.title}}</p>
                <p class="text-cor-padrao" style="font-weight: 600;text-align: left;">{{objInfoAgendamento.emailMotorista.valor ? objInfoAgendamento.emailMotorista.valor : '--'}}</p>
              </div>

            </div>

            <hr class="hr-padrao w-100" >

            <div class="w-100 row" >

              <div class="col-lg-3" style=" display: flex; flex-direction: column; align-items: flex-start; ">
                <p class="text-cor-padrao p-0 m-0 pb-2">{{objInfoAgendamento.placaVeiculo.title}}</p>
                <p class="text-cor-padrao" style="font-weight: 600;text-align: left;">{{objInfoAgendamento.placaVeiculo.valor ? objInfoAgendamento.placaVeiculo.valor : '--'}}</p>
              </div>

            </div>

            <hr class="hr-padrao w-100">

            <div class="w-100 row">

              <div class="col-lg-3" style=" display: flex; flex-direction: column; align-items: flex-start; ">
                <p class="text-cor-padrao p-0 m-0 pb-2">{{objInfoAgendamento.fornecedor.title}}</p>
                <p class="text-cor-padrao" style="font-weight: 600;text-align: left;">{{objInfoAgendamento.fornecedor.valor ? objInfoAgendamento.fornecedor.valor : '--'}}</p>
              </div>

              <div class="col-lg-3" style=" display: flex; flex-direction: column; align-items: flex-start; ">
                <p class="text-cor-padrao p-0 m-0 pb-2">{{objInfoAgendamento.notaFiscal.title}}</p>
                <p class="text-cor-padrao" style="font-weight: 600;text-align: left;">{{objInfoAgendamento.notaFiscal.valor ? objInfoAgendamento.notaFiscal.valor : '--'}}</p>
              </div>

              <div class="col-lg-3" style=" display: flex; flex-direction: column; align-items: flex-start; ">
                <p class="text-cor-padrao p-0 m-0 pb-2">{{objInfoAgendamento.numeroPedido.title}}</p>
                <p class="text-cor-padrao" style="font-weight: 600;text-align: left;">{{objInfoAgendamento.numeroPedido.valor ? objInfoAgendamento.numeroPedido.valor : '--'}}</p>
              </div>

            </div>

            <hr class="hr-padrao w-100" >

            <div class="w-100 row">
              <div class="col-12" style=" display: flex; flex-direction: column; align-items: flex-start; ">
                <p class="text-cor-padrao p-0 m-0 pb-2">{{objInfoAgendamento.observacaoCliente.title}}</p>
                <p class="text-cor-padrao" style="font-weight: 600;text-align: left;">{{objInfoAgendamento.observacaoCliente.valor ? objInfoAgendamento.observacaoCliente.valor : '--'}}</p>
              </div>
            </div>

          </div>
        </div>

      <!-- Modal footer -->
      <div class="modal-footer d-flex justify-content-end align-items-start">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="openCalendario()">Voltar</button>
      </div>

    </div>
  </div>
  </div>
</div>

<!-- Criar checkin Modal -->
<div class="modal" id="checkinModal" #checkinModal data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title p-0 m-0"><i class="bi bi-check2-circle text-sucesso" style="color: var(--cor-sucesso);"></i> Checkin</h4>
        <span id="btn-close-x-foto" class="text-cor-padrao" data-bs-dismiss="modal" style="padding: 0px 0px;font-size: 20px;cursor: pointer;">
          <i class="bi bi-x-circle-fill" style="cursor: pointer;"></i>
        </span>
      </div>

      <!-- Modal body -->
      <div class="modal-body p-0 m-0" style="padding: 5px !important;">

        <div class="w-100 mb-0 mt-2" style="text-align: left; ">

          <div class="row p-0 m-0 d-flex align-items-center justify-content-between">
            <div class="col-lg-12">
              <h4 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Dados do Motorista </h4>
            </div>
          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0">

            <!-- new_checkin.nomeMotorista -->
            <div class="col-lg-12">
              <app-ag-input-field
                [(ngModel)]="new_checkin.nomeMotorista.valor"
                [label]="new_checkin.nomeMotorista.title"
                [tooltip]="new_checkin.nomeMotorista.info"
                [value]="new_checkin.nomeMotorista.valor"
                [isInvalid]="new_checkin.nomeMotorista.status?.invalid"
                [AgStatus]="new_checkin.nomeMotorista.status"
                [id]="'nomeMotorista'"
                [AgStatus]="new_checkin.nomeMotorista.status"
                AgPlaceholder="{{new_checkin.nomeMotorista.placeholder}}"
                AgInputType="{{new_checkin.nomeMotorista.inputType}}"
                AgMaxlength="{{new_checkin.nomeMotorista.maxlength}}"
                AgInputMode="{{new_checkin.nomeMotorista.inputMode}}"
                (ngModelChange)="new_checkin.mascara(new_checkin.nomeMotorista.mascara, 'nomeMotorista');"
                (change)="new_checkin.validarInput('nomeMotorista');"
              ></app-ag-input-field>
            </div>

            <!-- new_checkin.cpfMotorista -->
            <div class="col-lg-6">
              <app-ag-input-field
                [(ngModel)]="new_checkin.cpfMotorista.valor"
                [label]="new_checkin.cpfMotorista.title"
                [tooltip]="new_checkin.cpfMotorista.info"
                [value]="new_checkin.cpfMotorista.valor"
                [isInvalid]="new_checkin.cpfMotorista.status?.invalid"
                [AgStatus]="new_checkin.cpfMotorista.status"
                [id]="'cpfMotorista'"
                AgPlaceholder="{{new_checkin.cpfMotorista.placeholder}}"
                AgInputType="{{new_checkin.cpfMotorista.inputType}}"
                AgMaxlength="{{new_checkin.cpfMotorista.maxlength}}"
                AgInputMode="{{new_checkin.cpfMotorista.inputMode}}"
                (ngModelChange)="new_checkin.mascara(new_checkin.cpfMotorista.mascara, 'cpfMotorista');"
                (change)="new_checkin.validarInput('cpfMotorista');"
              ></app-ag-input-field>
            </div>

            <!-- new_checkin.placaVeiculo -->
            <div class="col-lg-6">
              <app-ag-input-field
                [(ngModel)]="new_checkin.placaVeiculo.valor"
                [label]="new_checkin.placaVeiculo.title"
                [tooltip]="new_checkin.placaVeiculo.info"
                [value]="new_checkin.placaVeiculo.valor"
                [isInvalid]="new_checkin.placaVeiculo.status?.invalid"
                [AgStatus]="new_checkin.placaVeiculo.status"
                [id]="'placaVeiculo'"
                AgPlaceholder="{{new_checkin.placaVeiculo.placeholder}}"
                AgInputType="{{new_checkin.placaVeiculo.inputType}}"
                AgMaxlength="{{new_checkin.placaVeiculo.maxlength}}"
                AgInputMode="{{new_checkin.placaVeiculo.inputMode}}"
                (ngModelChange)="new_checkin.mascara(new_checkin.placaVeiculo.mascara, 'placaVeiculo');"
                (change)="new_checkin.validarInput('placaVeiculo');"
              ></app-ag-input-field>
            </div>

            <!-- new_checkin.veiculo -->
            <div class="col-lg-6">
              <app-ag-input-field
                [(ngModel)]="new_checkin.veiculo.valor"
                [label]="new_checkin.veiculo.title"
                [tooltip]="new_checkin.veiculo.info"
                [value]="new_checkin.veiculo.valor"
                [isInvalid]="new_checkin.veiculo.status?.invalid"
                [AgStatus]="new_checkin.veiculo.status"
                [id]="'veiculo'"
                AgPlaceholder="{{new_checkin.veiculo.placeholder}}"
                AgInputType="{{new_checkin.veiculo.inputType}}"
                AgMaxlength="{{new_checkin.veiculo.maxlength}}"
                AgInputMode="{{new_checkin.veiculo.inputMode}}"
                (ngModelChange)="new_checkin.mascara(new_checkin.veiculo.mascara, 'veiculo');"
                (change)="new_checkin.validarInput('veiculo');"
              ></app-ag-input-field>
            </div>

            <!-- new_checkin.peso -->
            <div class="col-lg-6">
              <app-ag-input-field
                [(ngModel)]="new_checkin.peso.valor"
                [label]="new_checkin.peso.title"
                [tooltip]="new_checkin.peso.info"
                [value]="new_checkin.peso.valor"
                [isInvalid]="new_checkin.peso.status?.invalid"
                [AgStatus]="new_checkin.peso.status"
                [id]="'peso'"
                AgPlaceholder="{{new_checkin.peso.placeholder}}"
                AgInputType="{{new_checkin.peso.inputType}}"
                AgMaxlength="{{new_checkin.peso.maxlength}}"
                AgInputMode="{{new_checkin.peso.inputMode}}"
                (ngModelChange)="new_checkin.mascara(new_checkin.peso.mascara, 'peso');"
                (change)="new_checkin.validarInput('peso');"
              ></app-ag-input-field>
            </div>

            <!-- new_checkin.telefoneMotorista -->
            <div class="col-lg-6">
              <app-ag-input-field
                [(ngModel)]="new_checkin.telefoneMotorista.valor"
                [label]="new_checkin.telefoneMotorista.title"
                [tooltip]="new_checkin.telefoneMotorista.info"
                [value]="new_checkin.telefoneMotorista.valor"
                [isInvalid]="new_checkin.telefoneMotorista.status?.invalid"
                [AgStatus]="new_checkin.telefoneMotorista.status"
                [AgDisabled]="true"
                [id]="'telefoneMotorista'"
                AgPlaceholder="{{new_checkin.telefoneMotorista.placeholder}}"
                AgInputType="{{new_checkin.telefoneMotorista.inputType}}"
                AgMaxlength="{{new_checkin.telefoneMotorista.maxlength}}"
                AgInputMode="{{new_checkin.telefoneMotorista.inputMode}}"
                (ngModelChange)="new_checkin.mascara(new_checkin.telefoneMotorista.mascara, 'telefoneMotorista');"
                (change)="new_checkin.validarInput('telefoneMotorista');"
              ></app-ag-input-field>
            </div>

            <!-- new_checkin.emailMotorista -->
            <div class="col-lg-6">
              <app-ag-input-field
                [(ngModel)]="new_checkin.emailMotorista.valor"
                [label]="new_checkin.emailMotorista.title"
                [tooltip]="new_checkin.emailMotorista.info"
                [value]="new_checkin.emailMotorista.valor"
                [isInvalid]="new_checkin.emailMotorista.status?.invalid"
                [AgStatus]="new_checkin.emailMotorista.status"
                [AgDisabled]="true"
                [id]="'emailMotorista'"
                AgPlaceholder="{{new_checkin.emailMotorista.placeholder}}"
                AgInputType="{{new_checkin.emailMotorista.inputType}}"
                AgMaxlength="{{new_checkin.emailMotorista.maxlength}}"
                AgInputMode="{{new_checkin.emailMotorista.inputMode}}"
                (ngModelChange)="new_checkin.mascara(new_checkin.emailMotorista.mascara, 'emailMotorista');"
                (change)="new_checkin.validarInput('emailMotorista');"
              ></app-ag-input-field>
            </div>

          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0 d-flex align-items-center justify-content-between">
            <div class="col-lg-12">
              <h4 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Dados complementares </h4>
            </div>
          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0">

            <!-- new_checkin.notaFiscal -->
            <div class="col-lg-6">
              <app-ag-input-field
                [(ngModel)]="new_checkin.notaFiscal.valor"
                [label]="new_checkin.notaFiscal.title"
                [tooltip]="new_checkin.notaFiscal.info"
                [value]="new_checkin.notaFiscal.valor"
                [isInvalid]="new_checkin.notaFiscal.status?.invalid"
                [AgStatus]="new_checkin.notaFiscal.status"
                [AgDisabled]="true"
                [id]="'notaFiscal'"
                AgPlaceholder="{{new_checkin.notaFiscal.placeholder}}"
                AgInputType="{{new_checkin.notaFiscal.inputType}}"
                AgMaxlength="{{new_checkin.notaFiscal.maxlength}}"
                AgInputMode="{{new_checkin.notaFiscal.inputMode}}"
                (ngModelChange)="new_checkin.mascara(new_checkin.notaFiscal.mascara, 'notaFiscal');"
                (change)="new_checkin.validarInput('notaFiscal');"
              ></app-ag-input-field>
            </div>

            <!-- new_checkin.numeroPedido -->
            <div class="col-lg-6">
              <app-ag-input-field
                [(ngModel)]="new_checkin.numeroPedido.valor"
                [label]="new_checkin.numeroPedido.title"
                [tooltip]="new_checkin.numeroPedido.info"
                [value]="new_checkin.numeroPedido.valor"
                [isInvalid]="new_checkin.numeroPedido.status?.invalid"
                [AgStatus]="new_checkin.numeroPedido.status"
                [id]="'numeroPedido'"
                AgPlaceholder="{{new_checkin.numeroPedido.placeholder}}"
                AgInputType="{{new_checkin.numeroPedido.inputType}}"
                AgMaxlength="{{new_checkin.numeroPedido.maxlength}}"
                AgInputMode="{{new_checkin.numeroPedido.inputMode}}"
                (ngModelChange)="new_checkin.mascara(new_checkin.numeroPedido.mascara, 'numeroPedido');"
                (change)="new_checkin.validarInput('numeroPedido');"
              ></app-ag-input-field>
            </div>

            <!-- new_checkin.fornecedor -->
            <div class="col-lg-12">
              <app-ag-input-field
                [(ngModel)]="new_checkin.fornecedor.valor"
                [label]="new_checkin.fornecedor.title"
                [tooltip]="new_checkin.fornecedor.info"
                [value]="new_checkin.fornecedor.valor"
                [isInvalid]="new_checkin.fornecedor.status?.invalid"
                [AgStatus]="new_checkin.fornecedor.status"
                [id]="'fornecedor'"
                AgPlaceholder="{{new_checkin.fornecedor.placeholder}}"
                AgInputType="{{new_checkin.fornecedor.inputType}}"
                AgMaxlength="{{new_checkin.fornecedor.maxlength}}"
                AgInputMode="{{new_checkin.fornecedor.inputMode}}"
                (ngModelChange)="new_checkin.mascara(new_checkin.fornecedor.mascara, 'fornecedor');"
                (change)="new_checkin.validarInput('fornecedor');"
              ></app-ag-input-field>
            </div>

          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0 d-flex align-items-center justify-content-between">
            <div class="col-lg-12">
              <h4 class="p-0 m-0 text-cor-padrao text-start w-100" style=" font-weight: 500; "> Tipos de chamadas </h4>
            </div>
          </div>

          <hr class="hr-padrao">

          <div class="row p-0 m-0">
            <div class="col-lg-12">
              <app-ag-mat-checkbox
                [(ngModel)]="new_checkin.painel.status"
                [label]="new_checkin.painel.title"
                [tooltip]="new_checkin.painel.descricao"
                [value]="new_checkin.painel.status"
                [id]="'new_checkin_painel'"
                [disabled]="true"
              ></app-ag-mat-checkbox>
            </div>

            <div class="col-lg-12">
              <div style=" display: flex; justify-content: space-between; align-items: center; ">
                <app-ag-mat-checkbox
                  [(ngModel)]="new_checkin.whatsapp.status"
                  [label]="new_checkin.whatsapp.title"
                  [tooltip]="new_checkin.whatsapp.descricao"
                  [value]="new_checkin.whatsapp.status"
                  [id]="'new_checkin_whatsapp'"
                ></app-ag-mat-checkbox>

                <div *ngIf="new_checkin.whatsapp.status" style="padding: 0px 10px;">
                  <button type="button" class="btn btn-outline-secondary m-0 p-0 mb-2" (click)="herdarNumero()" style="display: flex;justify-content: space-around;padding: .25rem .5rem !important;" >
                    Herdar Número
                  </button>
                </div>
              </div>

              <div *ngIf="new_checkin.whatsapp.status" style="padding: 0px 10px;">
                <app-ag-input-field
                  [(ngModel)]="new_checkin.whatsapp.whatsapp.telefone"
                  [label]="'Telefone'"
                  [tooltip]="'Telefone'"
                  [value]="new_checkin.whatsapp.whatsapp.telefone"
                  [isInvalid]="telefoneFormControl.invalid"
                  [AgStatus]="telefoneFormControl"
                  [id]="'chamadaWhatsapp'"
                  AgPlaceholder="Informe o Telefone com DDD"
                  AgInputType="text"
                  AgMaxlength="15"
                  AgInputMode="text"
                  (ngModelChange)="new_checkin.whatsapp.mascara('', 'chamadaWhatsapp');"
                  (change)="onTelefoneChange(new_checkin.whatsapp.whatsapp.telefone);"
                ></app-ag-input-field>
              </div>
            </div>

            <div class="col-lg-12">

              <app-ag-mat-checkbox
                [(ngModel)]="new_checkin.pager.status"
                [label]="new_checkin.pager.title"
                [tooltip]="new_checkin.pager.descricao"
                [value]="new_checkin.pager.status"
                [id]="'new_checkin_pager'"
              ></app-ag-mat-checkbox>

              <div *ngIf="new_checkin.pager.status" style="padding: 0px 10px;">
                <app-ag-input-field
                  [(ngModel)]="new_checkin.pager.pager.numeroPager"
                  [label]="'Número do Pager'"
                  [tooltip]="'Número do Pager'"
                  [value]="new_checkin.pager.pager.numeroPager"
                  [isInvalid]="pagerFormControl.invalid"
                  [AgStatus]="pagerFormControl"
                  [id]="'chamadaPager'"
                  AgPlaceholder="Informe o número do Pager"
                  AgInputType="text"
                  AgMaxlength="15"
                  AgInputMode="numeric"
                  (ngModelChange)="new_checkin.pager.mascara('', 'chamadaPager');"
                  (change)="onPagerChange(new_checkin.pager.pager.numeroPager);"
                ></app-ag-input-field>
              </div>

            </div>

          </div>

        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" style=" display: flex; justify-content: space-between; align-items: center; ">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" >Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="criarCheckin()">Criar checkin</button>
      </div>

    </div>
  </div>
</div>


