import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Agenda } from 'src/class/agenda';
import { DiasNaoAgendamento, DiaStatus } from 'src/class/dias-nao-agendamento';
import { GruposAgendas } from 'src/class/grupo-agenda';
import { newUsuario } from 'src/class/usuario';
import { AgendaService } from 'src/services/component/agenda.service';
import { DiasNaoAgendamentoService } from 'src/services/component/dias-nao-agendamento.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { ValidatorService } from 'src/services/validator.service';

declare var bootstrap: any;

@Component({
  selector: 'app-criar-dias-nao-agendamento',
  templateUrl: './criar-dias-nao-agendamento.component.html',
  styleUrls: ['./criar-dias-nao-agendamento.component.css']
})
export class CriarDiasNaoAgendamentoComponent {

  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('alertDiaModal', { static: false }) alertDiaModal!: ElementRef;
  @ViewChild('novoDiaStatus', { static: false }) novoDiaStatus!: ElementRef;
  @ViewChild('editarNovoDiaStatus', { static: false }) editarNovoDiaStatus!: ElementRef;
  @ViewChild('agendasSelecionadas', {static: true}) agendasSelecionadas!: MatSelectionList;

  usr_obj: any = [];

  usr_new = new newUsuario;

  dia: DiasNaoAgendamento = new DiasNaoAgendamento();
  empresaSigla: any | undefined;

  diasInformados: DiaStatus = new DiaStatus();

  modalsMap = new Map<ElementRef, any>();

  agendas: any[] = [];

  constructor(
    private router: Router,
    private toast: ToastService,
    private validatorService: ValidatorService,
    private contadorIndexService: ContadorIndexService,
    private diasNaoAgendamentoService: DiasNaoAgendamentoService,
    private route: ActivatedRoute,
    private siglaService: SiglaService,
    private agendaService: AgendaService
  ){
    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.agendaService.getAgendas(this.empresaSigla);
        });
      }
    });
  }

  async ngOnInit() {
    this.agendaService.getAllAgendas()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (agendas: Agenda[]) => {
        agendas.sort((a, b) => {
          const nomeA = a.nome.valor.toUpperCase();
          const nomeB = b.nome.valor.toUpperCase();
          if (nomeA < nomeB) {
            return -1;
          }
          if (nomeA > nomeB) {
            return 1;
          }
          return 0;
        });

        this.agendas = agendas;
      },
      error => {
        this.router.navigate(['/home']);
      }
    );
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  async criarDiasNaoAgendamento(){
    this.dia.nome.status = this.validatorService.criarValidacao(this.dia.nome.valor, this.dia.nome.validacoesCustomizadas);
    this.dia.descricao.status = this.validatorService.criarValidacao(this.dia.descricao.valor, this.dia.descricao.validacoesCustomizadas);

    if(this.dia.nome.status.invalid ||
      this.dia.descricao.status.invalid
    ){
      this.abrirModal(this.alertDiaModal.nativeElement);
    }else{

      this.dia.id = await this.contadorIndexService.contadorIndex();
      let new_dia  = new DiasNaoAgendamento();
      new_dia = this.dia;

      new_dia.criado = new Date();
      new_dia.atualizado = new Date();

      let agendas: GruposAgendas[] = [];

      for (let index = 0; index < this.agendasSelecionadas.selectedOptions.selected.length; index++) {
        const element = this.agendasSelecionadas.selectedOptions.selected[index];
        const rec = new GruposAgendas();

        rec.idAgenda = element.value.id
        rec.NomeAgenda = element.value.nome.valor

        agendas.push(rec)
      }

      new_dia.agendas = agendas;

      const dia = JSON.stringify(new_dia);
      const res = await this.diasNaoAgendamentoService.criarDiasNaoAgendamento(JSON.parse(dia), this.empresaSigla)

      // this.showSuccess("Dia criado com sucesso");
      this.dia = new DiasNaoAgendamento();
      this.router.navigate([`${this.empresaSigla}/dias-nao-agendamento`]);
    }
  }

  adicionarDia() {
    // console.log(this.diasInformados)
    this.validarCampo(this.diasInformados.dia);
    this.validarCampo(this.diasInformados.descricao);

    // Verifica se há campos inválidos
    if(this.temErros()) {
      this.showError("Alguns campos precisam ser preenchidos.");
    }else {

      const uniqueId = this.contadorIndexService.contadorIndex();
      let validacao = true;

      if(this.existeValores()){
        for (let index = 0; index < this.dia.dias.length; index++) {
          const dia = this.dia.dias[index];
          if(dia.dia.valor == this.diasInformados.dia.valor){
            this.showError("Essa data já existe.");
            validacao = false;
            break;
          }
        }
      }

      if(validacao){

        this.diasInformados.id = uniqueId;

        let new_dia = this.diasInformados;

        new_dia.dia.status = null;
        new_dia.descricao.status = null;

        this.dia.dias.push(new_dia)

        this.showSuccess("Dia adicionado com sucesso.");
        this.diasInformados = new DiaStatus();

        this.closeModal(this.novoDiaStatus.nativeElement);
        // console.log(this.form)
      }
    }
    // console.log(this.diasInformados)
    // console.log(this.dia)
  }

  salvarDia(){
    console.log(this.diasInformados)
    this.validarCampo(this.diasInformados.dia);
    this.validarCampo(this.diasInformados.descricao);

    // Verifica se há campos inválidos
    if(this.temErros()) {
      this.showError("Alguns campos precisam ser preenchidos.");
    }else {

      let validacao = true;

      if(this.existeValores()){
        for (let index = 0; index < this.dia.dias.length; index++) {
          const dia = this.dia.dias[index];
          if(dia.dia.valor == this.diasInformados.dia.valor && dia.id != this.diasInformados.id){
            this.showError("Essa data já existe.");
            validacao = false;
            break;
          }
        }
      }

      if(validacao){

        let new_dia = this.diasInformados;

        new_dia.dia.status = null;
        new_dia.descricao.status = null;

        // Encontra o índice
        const index = this.dia.dias.findIndex(dia => dia.id === this.diasInformados.id);

        this.dia.dias[index] = new_dia;

        this.showSuccess("Dia adicionado com sucesso.");
        this.diasInformados = new DiaStatus();

        this.closeModal(this.editarNovoDiaStatus.nativeElement);
        // console.log(this.form)
      }
    }
  }

  async openEditarDia(obj: DiaStatus){
    // console.log(obj)

    this.diasInformados = Object.assign(new DiaStatus(), JSON.parse(JSON.stringify(obj)));

    this.abrirModal(this.editarNovoDiaStatus.nativeElement);
  }

  deletarDia(id: any, valor: any) {

    // console.log(this.dia)

    // Encontra o índice
    const indiceParaExcluir = this.dia.dias.findIndex(dia => dia.id === id);

    if (indiceParaExcluir !== -1) {

      // Remove o item do array
      this.dia.dias.splice(indiceParaExcluir, 1);

      // Exibe uma mensagem ou executa qualquer outra lógica necessária
      this.showError(`Dia ${valor} deletado com sucesso!`);

      // console.log(this.dia)

    } else {
        this.showError(`Dia ${valor} não encontrado!`);
    }

  }

  abrirModal(event: any) {
    const modalElement = event;
    if (modalElement) {
        const modalInstance = new bootstrap.Modal(modalElement);
        this.modalsMap.set(event, modalInstance); // Armazenar a instância do modal no mapa
        modalInstance.show();
    } else {
        // console.log("Elemento do modal não encontrado");
    }
  }

  closeModal(event: any){
    const modalInstance = this.modalsMap.get(event);
    if (modalInstance) {
        modalInstance.hide(); // Fechar o modal
    } else {
        // console.log("Instância do modal não encontrada");
    }
  }

  openNovoDiaStatus(){
    this.diasInformados = new DiaStatus();
    this.abrirModal(this.novoDiaStatus.nativeElement);
  }

  existeValores(): boolean {
    return Object.keys(this.dia.dias).length > 0;
  }

  private validarCampo(campo: any){
    if(campo){
      campo.status = this.validatorService.criarValidacao(campo.valor, campo.validacoesCustomizadas);
    }
  }

  private temErros(): boolean {
    return [
      this.diasInformados.dia,
      this.diasInformados.descricao
    ].some(campo => campo?.status?.invalid);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
