import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Agenda } from 'src/class/agenda';
import { DiasNaoAgendamento, DiaStatus } from 'src/class/dias-nao-agendamento';
import { GruposAgendas } from 'src/class/grupo-agenda';
import { newUsuario } from 'src/class/usuario';
import { AuthService } from 'src/services/auth.service';
import { AgendaService } from 'src/services/component/agenda.service';
import { ContaService } from 'src/services/component/conta.service';
import { DiasNaoAgendamentoService } from 'src/services/component/dias-nao-agendamento.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { LocalhostService } from 'src/services/localhost.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ToastService } from 'src/services/toast.service';
import { ValidatorService } from 'src/services/validator.service';

declare var bootstrap: any;

@Component({
  selector: 'app-editar-dias-nao-agendamento',
  templateUrl: './editar-dias-nao-agendamento.component.html',
  styleUrls: ['./editar-dias-nao-agendamento.component.css']
})
export class EditarDiasNaoAgendamentoComponent {
  @ViewChild('alertDiaModal', { static: false }) alertDiaModal!: ElementRef;
  @ViewChild('novoDiaStatus', { static: false }) novoDiaStatus!: ElementRef;
  @ViewChild('editarNovoDiaStatus', { static: false }) editarNovoDiaStatus!: ElementRef;

  private destroy$: Subject<void> = new Subject<void>()

  @ViewChild('agendasSelecionadas', {static: true}) agendasSelecionadas!: MatSelectionList;

  id: any;
  usr_obj: any = [];
  usr_new = new newUsuario;

  dia: DiasNaoAgendamento = new DiasNaoAgendamento();
  dias: DiasNaoAgendamento[] = [];
  empresaSigla: any | undefined;

  diasInformados: DiaStatus = new DiaStatus();

  modalsMap = new Map<ElementRef, any>();

  agendas: any[] = [];

  constructor(
    private router: Router,
    private toast: ToastService,
    private validatorService: ValidatorService,
    private contadorIndexService: ContadorIndexService,
    private diasNaoAgendamentoService: DiasNaoAgendamentoService,
    private route: ActivatedRoute,
    private conta: ContaService,
    private localhostService: LocalhostService,
    private userService: AuthService,
    private notificacaoService: NotificacaoSistemaService,
    private siglaService: SiglaService,
    private agendaService: AgendaService,
  ){
    // Alimenta o serviço com a empresaSigla
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.route.params.subscribe(params => {
          this.empresaSigla = params['empresaSigla'];
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.agendaService.getAgendas(this.empresaSigla);
        });
      }
    });
  }

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    // console.log(this.id)
    // Agora você pode usar o ID como quiser

    this.diasNaoAgendamentoService.getAllDia()
    .pipe(takeUntil(this.destroy$))
    .subscribe(dia => {
      this.dias = dia;

      for (let index = 0; index < this.dias.length; index++) {
        const d = this.dias[index];
        if(d.id == this.id){
          // Object.assign(this.dia, d);
          this.dia = Object.assign(new DiasNaoAgendamento(), JSON.parse(JSON.stringify(d)));

          this.agendaService.getAllAgendas()
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (agendas: Agenda[]) => {

              if(agendas.length > 0){
                agendas.sort((a, b) => {
                  const nomeA = a.nome.valor.toUpperCase();
                  const nomeB = b.nome.valor.toUpperCase();
                  if (nomeA < nomeB) {
                    return -1;
                  }
                  if (nomeA > nomeB) {
                    return 1;
                  }
                  return 0;
                });

                this.agendas = agendas;

                // setTimeout(() => {
                  let agendaArray: any[] = [];

                  for (let b = 0; b < this.agendas.length; b++) {
                    const element = this.agendas[b];

                    for (let c = 0; c < this.dia.agendas.length; c++) {
                      const ag = this.dia.agendas[c];

                      if(element.id == ag.idAgenda){
                        agendaArray.push(element);
                        break;
                      }
                    }
                  }

                  this.selectAllAgendas(agendaArray);
              }

            },
            error => {
              // console.error('Erro ao listar os Tipos:', error);
              this.router.navigate(['/home']);
            }
          );
        }
      }

      if(this.dia.id == null){
        this.router.navigate([`${this.empresaSigla}/dias-nao-agendamento`]);
      }
    });
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  async salvarDiasNaoAgendamento(){
    this.dia.nome.status = this.validatorService.criarValidacao(this.dia.nome.valor, this.dia.nome.validacoesCustomizadas);
    this.dia.descricao.status = this.validatorService.criarValidacao(this.dia.descricao.valor, this.dia.descricao.validacoesCustomizadas);

    if(this.dia.nome.status.invalid ||
      this.dia.descricao.status.invalid
    ){
      this.abrirModal(this.alertDiaModal.nativeElement);
    }else{

      // Obtenha o array existente do DataService
      let d = this.dias;

      // Encontre o índice do item correspondente em dia com base no ID
      const index = d.findIndex((item: DiasNaoAgendamento) => item.id === this.dia.id);

      if (index !== -1) {
        this.dia.atualizado = new Date();
        // Atualize o item existente com as alterações de this.dia
        d[index] = this.dia;

        const docId = d[index].id != null ? d[index].id?.toString() : "";

        let agendas: GruposAgendas[] = [];

        // console.log(this.resources.selectedOptions)
        for (let index = 0; index < this.agendasSelecionadas.selectedOptions.selected.length; index++) {
          const element = this.agendasSelecionadas.selectedOptions.selected[index];
          // console.log(element.value)
          const rec = new GruposAgendas();

          rec.idAgenda = element.value.id
          rec.NomeAgenda = element.value.nome.valor

          agendas.push(rec)
        }

        d[index].agendas = agendas;

        const plainObject = JSON.stringify(d[index])
        const plainString = JSON.parse(plainObject)

        plainString.descricao.status = null;
        plainString.nome.status = null;

        for (let index = 0; index < plainString.dias.length; index++) {
          const dia_status = plainString.dias[index];
          dia_status.dia.status = null;
          dia_status.descricao.status = null;
        }

        await this.conta.p_getSiglaConta('contas', this.empresaSigla)
        .then((conta) => {
          // Faça algo com os dados obtidos
          // console.log('Dados da conta: atualizarStatusServico', conta);
          this.localhostService.putDados(`${conta.id}_dias_nao_agendamento`, docId!, plainString)
          .then(async (response: any) => {
            // console.log('Atualização bem-sucedida:', response);
            this.showSuccess("Dia atualizado com sucesso");

            const usr = await this.userService.carregarUsuario(this.empresaSigla)

            this.notificacaoService.criarNotificacao(
              this.empresaSigla,            // 1 - empresaSigla: any,
              `${plainString.nome.valor}`,  // 2 - titulo: string,
              `O dia foi atualizado.`,      // 3 - texto: string,
              'Listar Dias',                // 4 - rotaSemParamNome: string,
              'dias-nao-agendamento',       // 5 - rotaSemParamListar: string,
              'Editar Dia',                 // 6 - rotaComParamNome: string = "",
              `dias-nao-agendamento/grupo`, // 7 - rotaComParamCaminho: string = "",
              plainString.id?.toString(),   // 8 - rotaComParamParam: string = "",
              '',                           // 9 - linkExternoNome: string = "",
              '',                           // 10 - linkExternoURL: string = "",
              plainString.id!.toString(),   // 11 - idReferencia: string,
              `Dias não agendamento`,       // 12 - tipo: string,
              `${usr.id}`,                  // 13 - idResponsavel: string,
              `${usr.displayName.valor}`    // 14 - nomeResponsavel: string
            )

            this.router.navigate([`${this.empresaSigla}/dias-nao-agendamento`]);
          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            this.showError("Dia não encontrado para atualização \n" + error);
          });

        },
        (error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          // Trate o erro conforme necessário
        }).catch((error) => {
          // Trate o erro
          // console.log('Erro ao obter dados da conta:', error);
        });

        // Salve o array de volta no DataService
        // DataService.setItem("dia", dia);
        // localStorage.setItem("dia", JSON.stringify(dia))
        // this.showSuccess("dia atualizado com sucesso");
        // this.router.navigate([`${this.empresaSigla}/dias`]);

      } else {
        // O item não foi encontrado no array, você pode tratar isso conforme necessário
        this.showError("dia não encontrado para atualização");
      }
    }
  }

  adicionarDia() {
    // console.log(this.diasInformados)
    this.validarCampo(this.diasInformados.dia);
    this.validarCampo(this.diasInformados.descricao);

    // Verifica se há campos inválidos
    if(this.temErros()) {
      this.showError("Alguns campos precisam ser preenchidos.");
    }else {

      const uniqueId = this.contadorIndexService.contadorIndex();
      let validacao = true;

      if(this.existeValores()){
        for (let index = 0; index < this.dia.dias.length; index++) {
          const dia = this.dia.dias[index];
          if(dia.dia.valor == this.diasInformados.dia.valor){
            this.showError("Essa data já existe.");
            validacao = false;
            break;
          }
        }
      }

      if(validacao){

        this.diasInformados.id = uniqueId;

        let new_dia = this.diasInformados;

        new_dia.dia.status = null;
        new_dia.descricao.status = null;

        this.dia.dias.push(new_dia)

        this.showSuccess("Dia adicionado com sucesso.");
        this.diasInformados = new DiaStatus();

        this.closeModal(this.novoDiaStatus.nativeElement);
        // console.log(this.form)
      }
    }
    // console.log(this.diasInformados)
    // console.log(this.dia)
  }

  salvarDia(){
    console.log(this.diasInformados)
    this.validarCampo(this.diasInformados.dia);
    this.validarCampo(this.diasInformados.descricao);

    // Verifica se há campos inválidos
    if(this.temErros()) {
      this.showError("Alguns campos precisam ser preenchidos.");
    }else {

      let validacao = true;

      if(this.existeValores()){
        for (let index = 0; index < this.dia.dias.length; index++) {
          const dia = this.dia.dias[index];
          if(dia.dia.valor == this.diasInformados.dia.valor && dia.id != this.diasInformados.id){
            this.showError("Essa data já existe.");
            validacao = false;
            break;
          }
        }
      }

      if(validacao){

        let new_dia = this.diasInformados;

        new_dia.dia.status = null;
        new_dia.descricao.status = null;

        // Encontra o índice
        const index = this.dia.dias.findIndex(dia => dia.id === this.diasInformados.id);

        this.dia.dias[index] = new_dia;

        this.showSuccess("Dia adicionado com sucesso.");
        this.diasInformados = new DiaStatus();

        this.closeModal(this.editarNovoDiaStatus.nativeElement);
        // console.log(this.form)
      }
    }
  }

  async openEditarDia(obj: DiaStatus){
    // console.log(obj)

    this.diasInformados = Object.assign(new DiaStatus(), JSON.parse(JSON.stringify(obj)));

    this.abrirModal(this.editarNovoDiaStatus.nativeElement);
  }

  deletarDia(id: any, valor: any) {

    // console.log(this.dia)

    // Encontra o índice
    const indiceParaExcluir = this.dia.dias.findIndex(dia => dia.id === id);

    if (indiceParaExcluir !== -1) {

      // Remove o item do array
      this.dia.dias.splice(indiceParaExcluir, 1);

      // Exibe uma mensagem ou executa qualquer outra lógica necessária
      this.showError(`Dia ${valor} deletado com sucesso!`);

      // console.log(this.dia)

    } else {
        this.showError(`Dia ${valor} não encontrado!`);
    }

  }

  abrirModal(event: any) {
    const modalElement = event;
    if (modalElement) {
        const modalInstance = new bootstrap.Modal(modalElement);
        this.modalsMap.set(event, modalInstance); // Armazenar a instância do modal no mapa
        modalInstance.show();
    } else {
        // console.log("Elemento do modal não encontrado");
    }
  }

  closeModal(event: any){
    const modalInstance = this.modalsMap.get(event);
    if (modalInstance) {
        modalInstance.hide(); // Fechar o modal
    } else {
        // console.log("Instância do modal não encontrada");
    }
  }

  openNovoDiaStatus(){
    this.diasInformados = new DiaStatus();
    this.abrirModal(this.novoDiaStatus.nativeElement);
  }

  existeValores(): boolean {
    return Object.keys(this.dia.dias).length > 0;
  }

  private validarCampo(campo: any){
    if(campo){
      campo.status = this.validatorService.criarValidacao(campo.valor, campo.validacoesCustomizadas);
    }
  }

  private temErros(): boolean {
    return [
      this.diasInformados.dia,
      this.diasInformados.descricao
    ].some(campo => campo?.status?.invalid);
  }

  selectAllAgendas(agendas: any[]): void {
    const time = setInterval(()=>{
      if(this.agendasSelecionadas && this.agendasSelecionadas.options){
        clearInterval(time);

        agendas.forEach((rec: any) => {
            const resourceId = rec.id; // Obtém o ID do recurso
            const option = this.agendasSelecionadas.options.toArray().find((opt: any) => opt.value.id === resourceId);
            if(option){
              option.selected = true; // Seleciona a opção com o ID correspondente
            }
        });

      }
    })
  }

  getSelectedAgendas() {
    for (let index = 0; index < this.agendasSelecionadas.selectedOptions.selected.length; index++) {
      const element = this.agendasSelecionadas.selectedOptions.selected[index];
      // console.log(element.value)
    }
    return this.agendasSelecionadas.selectedOptions.selected.map(option => option.value);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
