import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, map, Observable } from 'rxjs';
import { Agenda } from 'src/class/agenda';
import { DataService } from '../data.service';
import { ToastService } from '../toast.service';
import { Router } from '@angular/router';
import { plainToClass } from 'class-transformer';
import { LocalhostService } from '../localhost.service';
import { ContaService } from './conta.service';
import { AuthService } from '../auth.service';
import { NotificacaoSistemaService } from './notificacao-sistema.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})

export class AgendaService {
  private agendas: Agenda[] = [];
  private agendasSubject = new BehaviorSubject<Agenda[]>(this.agendas);

  constructor(
    private router: Router,
    private localhostService: LocalhostService,
    private notificacaoService: NotificacaoSistemaService,
    private toast: ToastService,
    private conta: ContaService,
    private userService: AuthService,
    private storage: AngularFireStorage
  ) {
    this.agendas = [];
    // this.getAgendas();
  }

  async criarAgenda(agenda: Agenda, empresaSigla: any) {

    // console.log(agenda)

    agenda.agenda_fim.status = null;
    agenda.agenda_inicio.status = null;
    agenda.antecedenciaMaximaDias.status = null;
    agenda.antecedenciaMinimaHoras.status = null;
    agenda.duracaoAtendimento.status = null;
    agenda.instrucoes.status = null;
    agenda.link.status = null;
    agenda.linkImagem.status = null;
    agenda.nome.status = null;
    agenda.subNome.status = null;

    for (let index = 0; index < agenda.horarios.length; index++) {
      const obj_hr = agenda.horarios[index];
      obj_hr.dia.status = null;

      for (let index = 0; index < obj_hr.horariosDia.length; index++) {
        const hr = obj_hr.horariosDia[index];
        hr.hora_final.status = null
      }

    }

    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: criarAgenda', conta);

      // Salve a nova agenda no Firestore do Firebase
      this.localhostService.postDadosId(`${conta.id}_agendas`, agenda).then(
        async (response: any) => {
          // console.log('Agenda criada com sucesso:', response);

          // Atualize a lista local de agendas
          this.agendas.push(agenda);
          this.agendasSubject.next(this.agendas);

          // Atualize o localStorage (opcional, dependendo dos requisitos)
          // this.updateLocalStorage();

          // Notifique o usuário sobre o sucesso
          this.showSuccess('Agenda criada com sucesso');

          // Nova notificação
          // 1 - empresaSigla: any,
          // 2 - titulo: string,
          // 3 - texto: string,
          // 4 - rotaSemParamNome: string,
          // 5 - rotaSemParamListar: string,
          // 6 - rotaComParamNome: string = "",
          // 7 - rotaComParamCaminho: string = "",
          // 8 - rotaComParamParam: string = "",
          // 9 - linkExternoNome: string = "",
          // 10 - linkExternoURL: string = "",
          // 11 - idReferencia: string,
          // 12 - tipo: string,
          // 13 - idResponsavel: string,
          // 14 - nomeResponsavel: string

          const usr = await this.userService.carregarUsuario(empresaSigla)

          this.notificacaoService.criarNotificacao(
            empresaSigla,
            `${agenda.nome.valor}`,
            `Agenda criada.`,
            'Listar Agendas',
            'agendas',
            'Editar Agenda',
            `agendas/agenda`,
            agenda.id,
            '',
            '',
            agenda.id,
            `Agenda`,
            `${usr.id}`,
            `${usr.displayName.valor}`
          )

          // Navegue para a página de agendas
          this.router.navigate([`${empresaSigla}/agendas`]);
        },
        (error: any) => {
          // console.error('Erro ao criar agenda:', error);
          // Trate o erro conforme necessário
        }
      );

    })
    .catch((error) => {
      // Trate o erro
      // console.log('Erro ao obter dados da conta:', error);
    });



  }

  getAllAgendas() {
    return this.agendasSubject.asObservable().pipe(
      map(agendas => agendas)
    );
  }

  async getAgendas(empresaSigla: any) {

    await this.conta.p_getSiglaConta('contas', empresaSigla)
    .then((conta) => {
      // Faça algo com os dados obtidos
      // console.log('Dados da conta: getAgendas', conta);
      //
      this.localhostService.getDados(`${conta.id}_agendas`).subscribe(
        (agendasFromServer: any[]) => {
          if (agendasFromServer) {
            // Mapeia os dados para a estrutura desejada
            const agendasArray: Agenda[] = agendasFromServer.map(agenda => plainToClass(Agenda, agenda));

            // console.log(agendasArray);

            this.agendas = agendasArray;
            this.agendasSubject.next(this.agendas);
            // this.updateLocalStorage();
          } else {
            this.agendas = [];
          }
        },
        (error) => {
          // console.error('Erro ao obter agendas:', error);
        }
      );

    })
    .catch((error) => {
      // Trate o erro
      // console.log('Erro ao obter dados da conta:', error);
    });

  }

  // Upload de imagem com verificação de acesso
  uploadImage(empresaSigla: string, file: File): Observable<string> {
    return new Observable<string>((observer) => {
      this.conta.p_getSiglaConta('contas', empresaSigla).then(conta => {
        if (!conta || !conta.id) {
          observer.error('Conta não encontrada');
          return;
        }

        this.localhostService.checkAccess(conta.id).subscribe(
          (response) => {
            if (response.authorized) {
              const filePath = `${conta.id}/agendas_imagens/${file.name}`;
              const fileRef = this.storage.ref(filePath);
              const task = this.storage.upload(filePath, file);

              task.snapshotChanges().pipe(
                finalize(async () => {
                  const downloadURL = await fileRef.getDownloadURL().toPromise();
                  observer.next(downloadURL);
                  observer.complete();
                })
              ).subscribe();
            } else {
              observer.error('Usuário não autorizado a acessar essa conta');
            }
          },
          (error) => observer.error('Erro ao verificar acesso: ' + error)
        );
      }).catch(error => observer.error('Erro ao buscar conta: ' + error));
    });
  }

  // Listar imagens com verificação de acesso
  listImages(empresaSigla: string): Observable<{ nome: string; tamanho: number; tipo: string; criado: string; atualizado: string; url: string }[]> {
    return new Observable(observer => {
      this.conta.p_getSiglaConta('contas', empresaSigla).then(conta => {
        if (!conta || !conta.id) {
          observer.error('Conta não encontrada');
          return;
        }

        this.localhostService.checkAccess(conta.id).subscribe(response => {
          if (response.authorized) {
            const storageRef = this.storage.ref(`${conta.id}/agendas_imagens`);
            storageRef.listAll().toPromise().then(async (result) => {
              if (result && result.items) {
                const fileData = await Promise.all(result.items.map(async (item) => {
                  const metadata = await item.getMetadata();
                  const url = await item.getDownloadURL(); // Gera a URL pública

                  return {
                    nome: metadata.name,
                    tamanho: metadata.size,
                    tipo: metadata.contentType ?? 'desconhecido',
                    criado: metadata.timeCreated,
                    atualizado: metadata.updated,
                    url: url // Sempre atualizado
                  };
                }));
                observer.next(fileData);
              } else {
                observer.next([]);
              }
              observer.complete();
            }).catch(error => observer.error(error));
          } else {
            observer.error('Usuário não autorizado a acessar essa conta');
          }
        }, error => observer.error('Erro ao verificar acesso: ' + error));
      }).catch(error => observer.error('Erro ao buscar conta: ' + error));
    });
  }




  // Deletar imagem com verificação de acesso
  deleteImage(empresaSigla: string, fileName: string): Observable<void> {
    return new Observable<void>((observer) => {
      // Primeiro, busca a conta
      this.conta.p_getSiglaConta('contas', empresaSigla).then(conta => {
        if (!conta || !conta.id) {
          observer.error('Conta não encontrada');
          return;
        }

        // Agora, chama a verificação de acesso passando o ID da conta
        this.localhostService.checkAccess(conta.id).subscribe(
          (response) => {
            if (response.authorized) {
              // Se autorizado, deleta a imagem
              const filePath = `${conta.id}/agendas_imagens/${fileName}`;
              const fileRef = this.storage.ref(filePath);

              fileRef.delete().toPromise()
                .then(() => {
                  observer.next();
                  observer.complete();
                })
                .catch((error) => observer.error(error));
            } else {
              observer.error('Usuário não autorizado a acessar essa conta');
            }
          },
          (error) => observer.error('Erro ao verificar acesso: ' + error)
        );
      }).catch(error => observer.error('Erro ao buscar conta: ' + error));
    });
  }


  private updateLocalStorage() {
    localStorage.setItem("Agendas", JSON.stringify(this.agendas));
    DataService.set("Agendas", this.agendas);
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }
}
