import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { Links } from 'src/class/links';
import { objUsuario } from 'src/class/usuario';
import { SearchCheckinPipe } from 'src/pipes/search-checkin.pipe';
import { LinksService } from 'src/services/links.service';
import { ThemeService } from 'src/services/theme.service';
import { ToastService } from 'src/services/toast.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ModalConfirmacaoService } from 'src/services/modal-confirmacao.service';
import { ModalDocaService } from 'src/services/modal-doca.service';
import { LocalhostService } from 'src/services/localhost.service';
import { WhatsappService } from 'src/services/whatsapp.service';
import { SiglaService } from 'src/services/siglaService.service';
import { ContaService } from 'src/services/component/conta.service';
import { NotificacaoSistemaService } from 'src/services/component/notificacao-sistema.service';
import { AuthService } from 'src/services/auth.service';
import { Checkin, Comentario, DocaStatus } from 'src/class/checkin';
import { CheckinService } from 'src/services/component/checkin.service';
import { StatusKanban } from 'src/enum/statusKanban.enum';
import { ValidatorService } from 'src/services/validator.service';
import { ContadorIndexService } from 'src/services/contador-index.service';
import { Agenda } from 'src/class/agenda';
import { Agendamento } from 'src/class/agendamento';
import { Tipo } from 'src/class/tipo';
import { AgendaService } from 'src/services/component/agenda.service';
import { AgendamentoService } from 'src/services/component/agendamento.service';
import { TipoService } from 'src/services/component/tipo.service';
import { DocaService } from 'src/services/component/doca.service';
import { Doca } from 'src/class/doca';

declare var bootstrap: any;
@Component({
  selector: 'app-checkin-aguardando',
  templateUrl: './checkin-aguardando.component.html',
  styleUrls: ['./checkin-aguardando.component.css']
})
export class CheckinAguardandoComponent implements OnInit,OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('filtro', { static: false }) filtro!: ElementRef;
  @ViewChild('naoRealizadoModal', { static: false }) naoRealizadoModal!: ElementRef;

  modalsMap = new Map<ElementRef, any>();

  usr_id: string = "";
  usr_tipo: string = "";
  usr_token: string = "";

  usr_obj = new objUsuario;

  agenda_arr: any [] = [];

  pesquisa="";
  pag = 1;
  contador = 5;
  pageSize = 10;
  pageIndex = 0;
  totalizador = 0;
  maxSize = 10;
  visualizando = this.contador;
  lightMode: any;
  modoView = false;
  modoViewNum = 12;

  private subscription: Subscription;

  linksAgenda: Links[] = [];

  statusCheckin = StatusKanban;

  checkins: Checkin[] = [];
  displayedCheckins: Checkin[] = [];
  loading: boolean = true;
  sortedData: Checkin[] = [];

  loader = this.loadingBar.useRef('http');

  status = '';
  classificado = '';
  dataCheckin = '';
  empresaSigla: any | undefined;

  filtrado: any;

  naoRealizadoObj: Checkin = new Checkin();
  comentario: Comentario = new Comentario();

  agendas: Agenda[] = [];
  tipos: Tipo[] = [];
  agendamentos: Agendamento[] = [];
  docas: Doca[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private themeService: ThemeService,
    private linksService: LinksService,
    private router: Router,
    private route: ActivatedRoute,
    private toast: ToastService,
    private paginatorIntl: MatPaginatorIntl,
    private searchCheckinPipe: SearchCheckinPipe,
    private loadingBar: LoadingBarService,
    private modalConfirmacaoService: ModalConfirmacaoService,
    private modalDocaService: ModalDocaService,
    private notificacaoService: NotificacaoSistemaService,
    private localhostService: LocalhostService,
    private whatsapp: WhatsappService,
    private siglaService: SiglaService,
    private conta: ContaService,
    private userService: AuthService,
    private checkinService: CheckinService,
    private validatorService: ValidatorService,
    private contadorIndexService: ContadorIndexService,
    private agendamentoService: AgendamentoService,
    private agendaService: AgendaService,
    private tipoService: TipoService,
    private docaService: DocaService,
  ){
    this.subscription = this.themeService.lightMode$.subscribe(lightMode => {
      this.lightMode = lightMode;
    });

    this.paginatorIntl.itemsPerPageLabel = 'Itens por página:';
    this.paginatorIntl.nextPageLabel = 'Próxima página';
    this.paginatorIntl.previousPageLabel = 'Página anterior';
    this.paginatorIntl.firstPageLabel = 'Primeira página';
    this.paginatorIntl.lastPageLabel = 'Última página';

    // Personalize a indexação para começar em 1
    this.paginatorIntl.getRangeLabel = this.getPortugueseRangeLabel;

    // Alimenta o serviço com a empresaSigla
    // this.router.events.subscribe((event) => {
      // if (event instanceof NavigationEnd) {
        this.route.params
        .pipe(takeUntil(this.destroy$))
        .subscribe(async params => {
          this.empresaSigla = params['empresaSigla'];
          await this.checkinService.organizarAcesso(); // O organizarAcesso tem que ficar dentro do componente pois no serviço ele acaba não carregando os dados de primeira, somente quando muda e volta para a rota, se apenas pressionar F5 ele não carrega
          this.siglaService.setEmpresaSigla(this.empresaSigla?.toString());
          this.checkinService.getCheckin(this.empresaSigla);
          this.agendaService.getAgendas(this.empresaSigla);
          this.tipoService.getTipo(this.empresaSigla);
          this.docaService.getDoca(this.empresaSigla);
          this.agendamentoService.getAgendamentos(this.empresaSigla);
        });
      // }
    // });

  }

  ngOnInit() {

    this.getCache();

    this.linksAgenda = this.linksService.getLinksAgenda();

    this.changeDetectorRef.detectChanges();

    this.route.queryParams.subscribe((params: any) => {
      // O parâmetro de consulta 'search' estará em params.search
      this.pesquisa = params.search ? params.search : '';
      // console.log(params)
      // Agora você pode usar o valor da pesquisa em sua função searchAgenda
      if(this.pesquisa != ''){
        // setTimeout(() => {
          this.searchCheckin();
          // if (this.filtro && this.filtro.nativeElement) {
          //   console.log(this.filtro)
          //   this.renderer.selectRootElement(this.filtro.nativeElement).click();
          // }
        // }, 500);
      }
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  routerLink(rota: string, id: any = null){
    if(id != null){
      this.router.navigate([`${this.empresaSigla}/${rota}`, id]);
    }else{
      this.router.navigate([`${this.empresaSigla}/${rota}`]);
    }
  }

  getCache(){

    let modoView = localStorage.getItem("modoView");

    let modoViewNum = localStorage.getItem("modoViewNum");

    if(modoView){
      this.modoView = modoView == 'true' ? true : false
    }

    if(modoViewNum){
      this.modoViewNum = parseInt(modoViewNum);
    }

    try{
      const usr = JSON.parse(localStorage.getItem('user')!);
      // this.usr_id = usr.Id;
      // this.usr_tipo = usr.Tipo;
      // this.usr_token = usr.Token;
      this.usr_id = "1";
      this.usr_tipo = "M";
      this.usr_token = "usr.Token";
      if(this.usr_tipo != "M" && this.usr_tipo != "A"){
        this.router.navigate(['/home']);
      }
    }catch{
      this.router.navigate(['/home']);
    }

    this.dataCheckin = 'Todos';
    this.status = this.statusCheckin.aguardando;

    this.checkinService.getStatusCheckin(this.status)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (checkins: Checkin[]) => {
        // console.log(checkins)

        // Faça algo com as notificações atualizadas
        // if(checkins.length > 0){
          this.checkins = checkins;
          this.displayedCheckins = this.checkins.slice(
            this.pageIndex * this.pageSize,
            (this.pageIndex + 1) * this.pageSize
          );

          this.ordenadoPor('Maior Data/Horário');

          this.loading = false;
          if(this.pesquisa != ''){
            setTimeout(() => {
              this.searchCheckin();
              // if (this.filtro && this.filtro.nativeElement) {
              //   console.log(this.filtro)
              //   this.renderer.selectRootElement(this.filtro.nativeElement).click();
              // }
            }, 500);
          }

        // }
      },
      error => {
        // console.error('Erro ao listar os Checkins:', error);
      }
    );

    this.agendaService.getAllAgendas()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (agendas: Agenda[]) => {
        // console.log(agendas)
        // Faça algo com as notificações atualizadas
        if(agendas.length > 0){
          this.agendas = agendas;
        }
      },
      error => {
      }
    );

    this.tipoService.getAllTipo()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (tipos: Tipo[]) => {
        if(tipos.length > 0){
          this.tipos = tipos;
        }
      },
      error => {
      }
    );

    this.agendamentoService.getAllAgendamentos()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (agendamentos: Agendamento[]) => {
        if(agendamentos.length > 0){
          this.agendamentos = agendamentos;
        }
      },
      error => {
      }
    );

    this.docaService.getAllDoca()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (docas: Doca[]) => {
        if(docas.length > 0){
          this.docas = docas;
        }
      },
      error => {
      }
    );

  }

  allCheckins(){
    this.dataCheckin = 'Todos';
    if(this.status == ""){
      this.checkinService.getAllCheckin()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (checkins: Checkin[]) => {
          // console.log(checkins)

          // Faça algo com as notificações atualizadas
          // if(checkins.length > 0){
            this.checkins = checkins;
            this.displayedCheckins = this.checkins.slice(
              this.pageIndex * this.pageSize,
              (this.pageIndex + 1) * this.pageSize
            );

            this.ordenadoPor(this.classificado);

            this.loading = false;
            if(this.pesquisa != ''){
              setTimeout(() => {
                this.searchCheckin();
                // if (this.filtro && this.filtro.nativeElement) {
                //   console.log(this.filtro)
                //   this.renderer.selectRootElement(this.filtro.nativeElement).click();
                // }
              }, 500);
            }
          // }
        },
        error => {
          // console.error('Erro ao listar os Checkins:', error);
        }
      );
    }else{
      this.checkinService.getStatusCheckin(this.status)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (checkins: Checkin[]) => {
          // console.log(checkins)

          // Faça algo com as notificações atualizadas
          // if(checkins.length > 0){
            this.checkins = checkins;
            this.displayedCheckins = this.checkins.slice(
              this.pageIndex * this.pageSize,
              (this.pageIndex + 1) * this.pageSize
            );

            this.ordenadoPor(this.classificado);

            this.loading = false;
            if(this.pesquisa != ''){
              setTimeout(() => {
                this.searchCheckin();
                // if (this.filtro && this.filtro.nativeElement) {
                //   console.log(this.filtro)
                //   this.renderer.selectRootElement(this.filtro.nativeElement).click();
                // }
              }, 500);
            }
          // }
        },
        error => {
          // console.error('Erro ao listar os Checkins:', error);
        }
      );
    }

  }

  checkinsDia(){
    this.dataCheckin = 'Hoje';
    if(this.status == ""){
      this.checkinService.getCheckinsDia()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (checkins: Checkin[]) => {
          // console.log(checkins)

          // Faça algo com as notificações atualizadas
          // if(checkins.length > 0){
            this.checkins = checkins;
            this.displayedCheckins = this.checkins.slice(
              this.pageIndex * this.pageSize,
              (this.pageIndex + 1) * this.pageSize
            );

            this.ordenadoPor(this.classificado);

            this.loading = false;
            if(this.pesquisa != ''){
              setTimeout(() => {
                this.searchCheckin();
                // if (this.filtro && this.filtro.nativeElement) {
                //   console.log(this.filtro)
                //   this.renderer.selectRootElement(this.filtro.nativeElement).click();
                // }
              }, 500);
            }

          // }
        },
        error => {
          // console.error('Erro ao listar os Checkins:', error);
        }
      );
    }else{
      this.checkinService.getStatusCheckinDia(this.status)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (checkins: Checkin[]) => {
          // console.log(checkins)

          // Faça algo com as notificações atualizadas
          // if(checkins.length > 0){
            this.checkins = checkins;
            this.displayedCheckins = this.checkins.slice(
              this.pageIndex * this.pageSize,
              (this.pageIndex + 1) * this.pageSize
            );

            this.ordenadoPor(this.classificado);

            this.loading = false;
            if(this.pesquisa != ''){
              setTimeout(() => {
                this.searchCheckin();
                // if (this.filtro && this.filtro.nativeElement) {
                //   console.log(this.filtro)
                //   this.renderer.selectRootElement(this.filtro.nativeElement).click();
                // }
              }, 500);
            }

          // }
        },
        error => {
          // console.error('Erro ao listar os Checkins:', error);
        }
      );
    }
  }

  checkinsFuturos(){
    this.dataCheckin = 'Futuros';
    if(this.status == ""){
      this.checkinService.getCheckinsFuturos()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (checkins: Checkin[]) => {
          // console.log(checkins)

          // Faça algo com as notificações atualizadas
          // if(checkins.length > 0){
            this.checkins = checkins;
            this.displayedCheckins = this.checkins.slice(
              this.pageIndex * this.pageSize,
              (this.pageIndex + 1) * this.pageSize
            );

            this.ordenadoPor(this.classificado);

            this.loading = false;
            if(this.pesquisa != ''){
              setTimeout(() => {
                this.searchCheckin();
                // if (this.filtro && this.filtro.nativeElement) {
                //   console.log(this.filtro)
                //   this.renderer.selectRootElement(this.filtro.nativeElement).click();
                // }
              }, 500);
            }

          // }
        },
        error => {
          // console.error('Erro ao listar os Checkins:', error);
        }
      );
    }else{
      this.checkinService.getStatusCheckinsFuturos(this.status)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (checkins: Checkin[]) => {
          // console.log(checkins)

          // Faça algo com as notificações atualizadas
          // if(checkins.length > 0){
            this.checkins = checkins;
            this.displayedCheckins = this.checkins.slice(
              this.pageIndex * this.pageSize,
              (this.pageIndex + 1) * this.pageSize
            );

            this.ordenadoPor(this.classificado);

            this.loading = false;
            if(this.pesquisa != ''){
              setTimeout(() => {
                this.searchCheckin();
                // if (this.filtro && this.filtro.nativeElement) {
                //   console.log(this.filtro)
                //   this.renderer.selectRootElement(this.filtro.nativeElement).click();
                // }
              }, 500);
            }

          // }
        },
        error => {
          // console.error('Erro ao listar os Checkins:', error);
        }
      );
    }
  }

  checkinsPassados(){
    this.dataCheckin = 'Passados';
    if(this.status == ""){
      this.checkinService.getCheckinsPassados()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (checkins: Checkin[]) => {
          // console.log(checkins)

          // Faça algo com as notificações atualizadas
          // if(checkins.length > 0){
            this.checkins = checkins;
            this.displayedCheckins = this.checkins.slice(
              this.pageIndex * this.pageSize,
              (this.pageIndex + 1) * this.pageSize
            );

            this.ordenadoPor(this.classificado);

            this.loading = false;
            if(this.pesquisa != ''){
              setTimeout(() => {
                this.searchCheckin();
                // if (this.filtro && this.filtro.nativeElement) {
                //   console.log(this.filtro)
                //   this.renderer.selectRootElement(this.filtro.nativeElement).click();
                // }
              }, 500);
            }

          // }
        },
        error => {
          // console.error('Erro ao listar os Checkins:', error);
        }
      );
    }else{
      this.checkinService.getStatusCheckinsPassados(this.status)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (checkins: Checkin[]) => {
          // console.log(checkins)

          // Faça algo com as notificações atualizadas
          // if(checkins.length > 0){
            this.checkins = checkins;
            this.displayedCheckins = this.checkins.slice(
              this.pageIndex * this.pageSize,
              (this.pageIndex + 1) * this.pageSize
            );

            this.ordenadoPor(this.classificado);

            this.loading = false;
            if(this.pesquisa != ''){
              setTimeout(() => {
                this.searchCheckin();
                // if (this.filtro && this.filtro.nativeElement) {
                //   console.log(this.filtro)
                //   this.renderer.selectRootElement(this.filtro.nativeElement).click();
                // }
              }, 500);
            }

          // }
        },
        error => {
          // console.error('Erro ao listar os Checkins:', error);
        }
      );
    }
  }

  searchCheckin(){
    const retorno = this.searchCheckinPipe.transform(this.checkins, this.pesquisa);

    if (this.paginator) {
      this.paginator.firstPage();
    }

    this.displayedCheckins = retorno;

    if(this.pesquisa == ""){
      this.displayedCheckins = this.checkins.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      this.paginator.length = this.checkins.length;
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { search: null },
        queryParamsHandling: 'merge',
      });

    }else{

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { ['search']: this.pesquisa },
        queryParamsHandling: 'merge',
      });

      this.displayedCheckins = retorno.slice(
        this.pageIndex * this.pageSize,
        (this.pageIndex + 1) * this.pageSize
      );
      if (this.paginator) {
        this.paginator.length = retorno.length;
      }
      this.filtrado = retorno.length;
    }
  }

  handlePage(event: any) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.maxSize = event.pageSize
    // console.log(event)

    // Calcule o índice de início e fim dos itens a serem exibidos na página atual
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;

    // Atualize os itens a serem exibidos na tabela
    this.displayedCheckins = this.checkins.slice(startIndex, endIndex);

    // console.log(this.displayedCheckins)
  }

  sortData(sort: Sort) {
    // const data = this.checkins.slice();
    // if (!sort.active || sort.direction === '') {
    //   this.sortedData = data;
    //   this.checkins = this.sortedData
    //   return;
    // }

    // this.sortedData = data.sort((a, b) => {
    //   const isAsc = sort.direction === 'asc';

    //   switch (sort.active) {
    //     case 'id':
    //       return this.compare(a.id!, b.id!, isAsc);
    //     case 'cep':
    //       return this.compare(a.cep.valor, b.cep.valor, isAsc);
    //     case 'logradouro':
    //       return this.compare(a.logradouro.valor, b.logradouro.valor, isAsc);
    //     case 'numero':
    //       return this.compare(a.numero.valor, b.numero.valor, isAsc);
    //     case 'bairro':
    //       return this.compare(a.bairro.valor, b.bairro.valor, isAsc);
    //     case 'localidade':
    //       return this.compare(a.localidade.valor, b.localidade.valor, isAsc);
    //     case 'uf':
    //       return this.compare(a.uf.valor, b.uf.valor, isAsc);
    //     default:
    //       return 0;
    //   }
    // });

    // console.log(this.sortedData)
    // this.checkins = this.sortedData

    // this.displayedCheckins = this.checkins.slice(
    //   this.pageIndex * this.pageSize,
    //   (this.pageIndex + 1) * this.pageSize
    // );
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  // Personalize a função getRangeLabel para começar em 1
  getPortugueseRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`;
  }

  reload(){
    try {
      this.checkins = [];
      this.loading = true;
      // this.getTotalTiposUsuarios();
      // this.getUsuario();


    this.loader.start()

      setTimeout(() => {


        this.statusPor(this.status)
        this.ordenadoPor(this.classificado);

        this.showInfo("Checkins atualizados com sucesso!!")
        this.loading = false;
        this.loader.complete();
        if(this.pesquisa != ''){
          setTimeout(() => {
            this.searchCheckin();
            // if (this.filtro && this.filtro.nativeElement) {
            //   console.log(this.filtro)
            //   this.renderer.selectRootElement(this.filtro.nativeElement).click();
            // }
          }, 500);
        }

      }, 1000);


    } catch (error) {
      this.showError("Ocorreu um erro na atualização da agenda")
      this.loader.complete()
    }

  }

  resetSearch(){
    this.pesquisa = "";
    this.filtrado = 0;
    this.displayedCheckins = this.checkins.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
    this.paginator.length = this.checkins.length;

    // Use o serviço Router para navegar para a mesma rota sem o parâmetro de consulta
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { search: null },
      queryParamsHandling: 'merge',
    });
  }

  pags(pags: any) {
    const target = pags as HTMLTextAreaElement;
    this.contador = parseInt(target.value)
    const total = this.contador * this.pag
    this.visualizando = total;
    if(this.visualizando > this.totalizador && (this.visualizando > 0 && this.totalizador > 0)){
      this.visualizando = this.totalizador;
    }
  }

  modoTabela(){
    this.modoView = true;
    localStorage.setItem("modoView", "true");
  }


  modoCard(){
    this.modoView = false;
    this.modoViewNum = 12;
    localStorage.setItem("modoViewNum", "12");
    localStorage.setItem("modoView", "false");
  }

  deletarCheckin(id: any){
    const index = this.checkins.findIndex(agenda => agenda.id === id);

    if (index !== -1) {

      let titulo = 'Tem certeza que deseja excluir o agendamento?';
      let mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
                        <p class="ag-mb-5">
                          <span>NF ${this.checkins[index].notaFiscal.valor}</span>
                        </p>
                        <p class="ag-mb-5">
                          <span>
                            ${this.checkins[index].status.valor === 'Confirmado cliente' || this.checkins[index].status.valor === 'Confirmado administrador' ? "<i class='bi bi-circle-fill text-cor-info ag-pr-5'></i>" : this.checkins[index].status.valor === 'Atendido' ? "<i class='bi bi-circle-fill text-cor-sucesso ag-pr-5'></i>" : this.checkins[index].status.valor === 'Não atendido' ? "<i class='bi bi-circle-fill text-cor-danger ag-pr-5'></i>" : "<i class='bi bi-circle-fill ag-pr-5'></i>"}
                            ${this.checkins[index].status.valor}
                          </span>
                        </p>
                      </div>`;

          this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
            if (confirmation) {
              // console.log('SIM!');
              // Encontrou um item com o ID correspondente, exclua-o

              const docId = this.checkins[index].id != null ? this.checkins[index].id?.toString() : "";

              await this.conta.p_getSiglaConta('contas', this.empresaSigla)
              .then((conta) => {
                // Faça algo com os dados obtidos
                // console.log('Dados da conta: criarAgenda', conta);

                this.localhostService.deleteDados(`${conta.id}_checkins`, { id: docId })
                .then(async (response: any) => {
                  // console.log('Atualização bem-sucedida:', response);
                  // Salve o array de volta no DataService
                  // DataService.set("Agenda", this.agendas)
                  // localStorage.setItem("Agenda", JSON.stringify(this.agendas))
                  this.showSuccess(`Checkin deletado com sucesso`);

                  // Nova notificação
                  // 1 - empresaSigla: any,
                  // 2 - titulo: string,
                  // 3 - texto: string,
                  // 4 - rotaSemParamNome: string,
                  // 5 - rotaSemParamListar: string,
                  // 6 - rotaComParamNome: string = "",
                  // 7 - rotaComParamCaminho: string = "",
                  // 8 - rotaComParamParam: string = "",
                  // 9 - linkExternoNome: string = "",
                  // 10 - linkExternoURL: string = "",
                  // 11 - idReferencia: string,
                  // 12 - tipo: string,
                  // 13 - idResponsavel: string,
                  // 14 - nomeResponsavel: string

                  const usr = await this.userService.carregarUsuario(this.empresaSigla)

                  this.notificacaoService.criarNotificacao(
                    this.empresaSigla,
                    `NF ${this.checkins[index].notaFiscal.valor}`,
                    `O checkin`,
                    'Listar Checkins',
                    'checkins',
                    '',
                    ``,
                    ``,
                    '',
                    '',
                    this.checkins[index].atendimentoId!.toString(),
                    `Checkin`,
                    `${usr.id}`,
                    `${usr.displayName.valor}`
                  )

                  this.displayedCheckins = this.checkins.slice(
                    this.pageIndex * this.pageSize,
                    (this.pageIndex + 1) * this.pageSize
                  );

                  this.reload();
                })
                .catch((error: any) => {
                  // console.error('Erro ao deletar dados:', error);
                  this.showError("Checkin não encontrado para exclusão \n" + error);
                });
              },
              (error: any) => {
                // console.error('Erro ao atualizar dados:', error);
                // Trate o erro conforme necessário
              }).catch((error) => {
                // Trate o erro
                // this.authService.fazerLogout(this.empresaSigla);
                // console.log('Erro ao obter dados da conta:', error);
              });

            } else {
              // console.log('NÃO');
            }
          });

    } else {
      // Não encontrou um item com o ID correspondente
      this.showError("Checkin não encontrado para exclusão");
    }
  }

  async atualizarStatusCheckinEmAndamento(id: any, status: string){

    const index = this.checkins.findIndex((item: Checkin) => item.id === id);

    if (index !== -1 && status != '') {
      let titulo = "";
      let mensagem = "";
      titulo = 'Tem certeza que deseja alterar o status?';
      mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
                    <p class="ag-mb-5">
                      <span>NF ${this.checkins[index].notaFiscal.valor}</span>
                    </p>
                    <p class="ag-mb-5">
                      <span class="text-cor-danger">Status atual: </span>
                      <span>${this.checkins[index].status.valor}</span>
                    </p>
                    <p class="ag-mb-5">
                      <span class="text-cor-sucesso">Novo status: </span>
                      <span>${this.statusCheckin.emAndamento}</span>
                    </p>
                  </div>`;

      const confirmation = await this.modalConfirmacaoService.openModal(titulo, mensagem);
      if (confirmation) {

        await this.setDoca(index, status);

      } else {
        // console.log('NÃO');
      }
    }
  }

  async setDoca(index: any, status: string){
    let titulo_doca = "";
    let mensagem_doca = "";

    const confirmation_doca = await this.modalDocaService.openModal(titulo_doca, mensagem_doca);

    if(confirmation_doca){

      const doca_id = this.docas.find(at => at.id === confirmation_doca);

      if(doca_id){
        let doca = new DocaStatus();

        doca.IdDoca = doca_id.id;
        doca.NomeDoca = doca_id.nome.valor;

        this.checkins[index].doca = doca;

        // Alimenta o painel
        this.checkins[index].painel.painel.idDoca = doca_id.id;

      }


      // console.log('SIM!');
      // Atualize o item existente
      this.checkins[index].status.valor = status;
      this.checkins[index].statusAguardando.fim = new Date();
      this.checkins[index].statusEmAndamento.inicio = new Date();
      this.checkins[index].atualizado = new Date();

      const docId = this.checkins[index].id != null ? this.checkins[index].id?.toString() : "";
      const plainObject = JSON.stringify(this.checkins[index])
      const plainString = JSON.parse(plainObject)

      plainString.nomeMotorista.status = null;
      plainString.cpfMotorista.status = null;
      plainString.placaVeiculo.status = null;
      plainString.veiculo.status = null;
      plainString.peso.status = null;
      plainString.telefoneMotorista.status = null;
      plainString.emailMotorista.status = null;
      plainString.notaFiscal.status = null;
      plainString.numeroPedido.status = null;
      plainString.fornecedor.status = null;
      plainString.status.status = null;

      await this.conta.p_getSiglaConta('contas', this.empresaSigla)
      .then((conta) => {
        // Faça algo com os dados obtidos
        // console.log('Dados da conta: atualizarStatusAgendamento', conta);

        this.localhostService.putDados(`${conta.id}_checkins`, docId! , plainString)
        .then(async (response: any) => {
          // console.log('Atualização bem-sucedida:', response);
          // Salve o array de volta no DataService
          // DataService.set("Agenda", this.agendas)
          // localStorage.setItem("Agenda", JSON.stringify(this.agendas))
          this.showSuccess(`Status do checkin NF ${plainString.notaFiscal.valor} alterado para ${plainString.status.valor}`);

          const usr = await this.userService.carregarUsuario(this.empresaSigla)

          this.notificacaoService.criarNotificacao(
            this.empresaSigla,                      // 1 - empresaSigla: any,
            `NF ${plainString.notaFiscal.valor}`,   // 2 - titulo: string,
            `O status do checkin, foi alterado para ${plainString.status.valor}.`,  // 3 - texto: string,
            'Listar Checkins',                      // 4 - rotaSemParamNome: string,
            'checkin/andamento',                    // 5 - rotaSemParamListar: string,
            'Editar Checkin',                       // 6 - rotaComParamNome: string = "",
            `checkin`,                              // 7 - rotaComParamCaminho: string = "",
            plainString.id!.toString(),             // 8 - rotaComParamParam: string = "",
            '',                                     // 9 - linkExternoNome: string = "",
            '',                                     // 10 - linkExternoURL: string = "",
            plainString.atendimentoId!.toString(),  // 11 - idReferencia: string,
            `Checkin`,                              // 12 - tipo: string,
            `${usr.id}`,                            // 13 - idResponsavel: string,
            `${usr.displayName.valor}`              // 14 - nomeResponsavel: string
          )
        })
        .catch((error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          this.showError("Checkin não encontrado para atualização \n" + error);
        });
      },
      (error: any) => {
        // console.error('Erro ao atualizar dados:', error);
        // Trate o erro conforme necessário
      }).catch((error) => {
        // Trate o erro
        // this.authService.fazerLogout(this.empresaSigla);
        // console.log('Erro ao obter dados da conta:', error);
      });



      // localStorage.setItem("Agendamento", JSON.stringify(this.agendamentos))
      // this.showSuccess(`Agendamento alterado para ${this.agendamentos[index].status.valor}`);
    }else{

    }
    // });
  }

  abrirModal(event: any) {
    // Ativar o modal usando a biblioteca do Bootstrap
    // const modal = new bootstrap.Modal(event);
    // modal.show();
    const modalElement = event;
    if (modalElement) {
        const modalInstance = new bootstrap.Modal(modalElement);
        this.modalsMap.set(event, modalInstance); // Armazenar a instância do modal no mapa
        modalInstance.show();
    } else {
        // console.error("Elemento do modal não encontrado");
    }
  }

  fecharModal(event: any){
    // console.log(event)
    // const modal = new bootstrap.Modal(event);
    // modal.hide();
    const modalInstance = this.modalsMap.get(event);
    if (modalInstance) {
        modalInstance.hide(); // Fechar o modal
    } else {
        // console.error("Instância do modal não encontrada");
    }
  }

  ordenadoPor(param: string): void {
    let sortedCheckins: Checkin[] = [];
    this.classificado = param;

    switch (param) {
      case "Maior Data/Horário":
        sortedCheckins = this.checkins.sort((a, b) => this.compareData(b, a));
        break;
      case "Menor Data/Horário":
        sortedCheckins = this.checkins.sort((a, b) => this.compareData(a, b));
        break;
      default:
        break;
    }

    this.checkins = sortedCheckins

    this.displayedCheckins = sortedCheckins.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
  }

  statusPor(param: string): void {
    // console.log("PARAM", param);
    // console.log("dataCheckin", this.dataCheckin);
    this.status = param;
    this.dataCheckin = param;

    // let statusCheckins: Checkin[] = [];

    switch (param) {
      case "":
        // console.log("switch PARAM", param);
        if(this.dataCheckin == 'Hoje'){
          this.checkinsDia();
        }else if(this.dataCheckin == 'Futuros'){
          this.checkinsFuturos();
        }else if(this.dataCheckin == 'Passados'){
          this.checkinsPassados();
        }else{
          this.allCheckins();
        }
        break;
      default:
        // console.log("switch PARAM", param);
        if(this.dataCheckin == 'Hoje'){
          this.checkinService.getStatusCheckinDia(this.statusCheckin.aguardando)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (checkins: Checkin[]) => {
              // Atualiza a propriedade checkins com os resultados do observable
              this.checkins = checkins;
              this.ordenadoPor(this.classificado);
            },
            error => {
              // console.error('Erro ao obter checkins por status:', error);
            }
          );
        }else if(this.dataCheckin == 'Futuros'){
          this.checkinService.getStatusCheckinsFuturos(this.statusCheckin.aguardando)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (checkins: Checkin[]) => {
              // Atualiza a propriedade checkins com os resultados do observable
              this.checkins = checkins;
              this.ordenadoPor(this.classificado);
            },
            error => {
              // console.error('Erro ao obter checkins por status:', error);
            }
          );
        }else if(this.dataCheckin == 'Passados'){
          this.checkinService.getStatusCheckinsPassados(this.statusCheckin.aguardando)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (checkins: Checkin[]) => {
              // Atualiza a propriedade checkins com os resultados do observable
              this.checkins = checkins;
              this.ordenadoPor(this.classificado);
            },
            error => {
              // console.error('Erro ao obter checkins por status:', error);
            }
          );
        }else{
          this.checkinService.getStatusCheckin(this.statusCheckin.aguardando)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (checkins: Checkin[]) => {
              // Atualiza a propriedade checkins com os resultados do observable
              this.checkins = checkins;
              this.ordenadoPor(this.classificado);
            },
            error => {
              // console.error('Erro ao obter checkins por status:', error);
            }
          );
        }

        break;
    }

    this.displayedCheckins = this.checkins.slice(
      this.pageIndex * this.pageSize,
      (this.pageIndex + 1) * this.pageSize
    );
  }

  private compareData(agendamentoA: Checkin, agendamentoB: Checkin): number {
    const dataA = new Date(agendamentoA.criado);
    const dataB = new Date(agendamentoB.criado);

    return dataA.getTime() - dataB.getTime();
  }

  atualizarStatusCheckinNaoRealizado(id: any){

    const index = this.checkins.findIndex((item: Checkin) => item.id === id);
    // console.log(index)

    if (index !== -1) {
      let titulo = "";
      let mensagem = "";
      titulo = 'Tem certeza que deseja alterar o status?';
      mensagem = `<div class="text-cor-padrao box-gen-card-list ag-mt-0">
                    <p class="ag-mb-5">
                      <span>NF ${this.checkins[index].notaFiscal.valor}</span>
                    </p>
                    <p class="ag-mb-5">
                      <span class="text-cor-danger">Status atual: </span>
                      <span>${this.checkins[index].status.valor}</span>
                    </p>
                    <p class="ag-mb-5">
                      <span class="text-cor-sucesso">Novo status: </span>
                      <span>${this.statusCheckin.naoRealizado}</span>
                    </p>
                  </div>`;

      this.modalConfirmacaoService.openModal(titulo, mensagem).then(async (confirmation: boolean) => {
        if (confirmation) {
          // console.log('SIM!');
          // Atualize o item existente
          this.naoRealizadoObj = Object.assign(new Checkin(), this.checkins[index]);
          this.abrirModal(this.naoRealizadoModal.nativeElement);
        } else {
          // console.log('NÃO');
          this.resetarNaoRealizado();
        }
      });

    }
  }

  async naoRealizado(id: any){

    const index = this.checkins.findIndex((item: Checkin) => item.id === id);

    this.comentario.observacao.status = this.validatorService.criarValidacao(this.comentario.observacao.valor, this.comentario.observacao.validacoesCustomizadas);

    if(this.comentario.observacao.status.invalid){
      this.showError("Alguns campos precisam ser preenchidos.");
    }else{

      if (index !== -1) {

        // console.log(this.checkins[index].id)
        const usr = await this.userService.carregarUsuario(this.empresaSigla)

        this.checkins[index].status.valor = this.statusCheckin.naoRealizado;
        this.checkins[index].statusNaoRealizado.fim = new Date();
        this.checkins[index].statusNaoRealizado.inicio = new Date();
        this.checkins[index].atualizado = new Date();

        this.comentario.observacao.status = null;
        this.comentario.responsavel.idUsuario = usr.id;
        this.comentario.responsavel.nomeUsuario = usr.displayName.valor;
        this.comentario.id = this.contadorIndexService.contadorIndex();

        this.checkins[index].statusNaoRealizado.comentarios.push(this.comentario);

        const docId = this.checkins[index].id != null ? this.checkins[index].id?.toString() : "";
        const plainObject = JSON.stringify(this.checkins[index])
        const plainString = JSON.parse(plainObject)

        const fieldsToReset = [
          'nomeMotorista',
          'cpfMotorista',
          'placaVeiculo',
          'veiculo',
          'peso',
          'telefoneMotorista',
          'emailMotorista',
          'notaFiscal',
          'numeroPedido',
          'fornecedor',
          'status'
        ];

        fieldsToReset.forEach(field => {
          plainString[field].status = null;
        });

        await this.conta.p_getSiglaConta('contas', this.empresaSigla)
        .then((conta) => {
          // Faça algo com os dados obtidos
          // console.log('Dados da conta: atualizarStatusAgendamento', conta);

          this.localhostService.putDados(`${conta.id}_checkins`, docId! , plainString)
          .then(async (response: any) => {
            // console.log('Atualização bem-sucedida:', response);
            // Salve o array de volta no DataService
            // DataService.set("Agenda", this.agendas)
            // localStorage.setItem("Agenda", JSON.stringify(this.agendas))
            this.showSuccess(`Status do checkin NF ${plainString.notaFiscal.valor} alterado para ${plainString.status.valor}`);

            this.notificacaoService.criarNotificacao(
              this.empresaSigla, // 1 - empresaSigla: any,
              `NF ${plainString.notaFiscal.valor}`, // 2 - titulo: string,
              `O status do checkin, foi alterado para ${plainString.status.valor}.`, // 3 - texto: string,
              'Listar Checkins', // 4 - rotaSemParamNome: string,
              'checkin/andamento', // 5 - rotaSemParamListar: string,
              'Editar Checkin', // 6 - rotaComParamNome: string = "",
              `checkin`, // 7 - rotaComParamCaminho: string = "",
              plainString.id!.toString(), // 8 - rotaComParamParam: string = "",
              '', // 9 - linkExternoNome: string = "",
              '', // 10 - linkExternoURL: string = "",
              plainString.atendimentoId!.toString(), // 11 - idReferencia: string,
              `Checkin`, // 12 - tipo: string,
              `${usr.id}`, // 13 - idResponsavel: string,
              `${usr.displayName.valor}` // 14 - nomeResponsavel: string
            )

          })
          .catch((error: any) => {
            // console.error('Erro ao atualizar dados:', error);
            this.showError("Checkin não encontrado para atualização \n" + error);
          });
        },
        (error: any) => {
          // console.error('Erro ao atualizar dados:', error);
          // Trate o erro conforme necessário
        }).catch((error) => {
          // Trate o erro
          // this.authService.fazerLogout(this.empresaSigla);
          // console.log('Erro ao obter dados da conta:', error);
        });

        this.fecharModal(this.naoRealizadoModal.nativeElement);
        this.resetarNaoRealizado();
      }
    }
  }

  resetarNaoRealizado(){
    this.naoRealizadoObj = new Checkin();
    this.comentario = new Comentario();
  }

  getAgendaTipoNome(agendamentoID: string): string | undefined {
    // Encontre o atendimento pelo ID
    const agendamento = this.agendamentos.find(at => at.id === agendamentoID);

    if (agendamento) {
      // Encontre a agenda pelo ID no atendimento
      const agenda = this.agendas.find(ag => ag.id === agendamento.agendaId);

      if (agenda) {
        // Encontre o tipo pelo ID da agenda
        const tipo = this.tipos.find(tp => tp.id === agenda.tipoId);
        return tipo?.nome.valor; // Retorna o nome do tipo (ou undefined se não encontrar)
      }
    }

    return undefined; // Retorna undefined caso algo não seja encontrado
  }

  private showSuccess(msg: string){
    this.toast.showToast('<i class="bi bi-check-circle-fill " style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'success');
  }

  private showError(msg: string){
    this.toast.showToast('<i class="bi bi-x-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'danger');
  }

  private showInfo(msg: string) {
    this.toast.showToast('<i class="bi bi-info-circle-fill" style="font-size: 25px;padding-right: 10px;"></i> ' + msg, 'info');
  }

}
