
<div class="notificacao-badge-content">

  <div class="btn-group">
    <i class="bi bi-bell" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false" style="font-size: 23px;cursor: pointer;" title="notificacoes">
      <span *ngIf="notificacoes.length > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill background-cor-danger text-cor-branco" style="font-size: 10px;" >
        {{notificacoes.length}}
      </span>
    </i>
    <div class="dropdown-menu text-cor-padrao notificacao-badge-content-dropdown-menu" >

      <div>
        <span *ngIf="notificacoes.length > 0" class="notificacao-badge-content-title-nao-lidas">
          <span *ngIf="notificacoes.length > 0" class="start-100 badge rounded-pill background-cor-danger text-cor-branco" >
            Não Lidas
          </span>
          <span *ngIf="notificacoes.length > 0" class="start-100 badge rounded-pill background-cor-danger text-cor-branco" >
            {{notificacoes.length}}
          </span>
        </span>

        <div style="overflow: auto;max-height: 50vh;width: 50vh;">
          <div class="notification-item-nao-lido" *ngFor="let notificacao of notificacoes; let last = last" (click)="marcarComoLido(notificacao)"  title="Marcado como lido">
            <!-- (click)="markAsRead(notificacao)" -->
            <p style="font-size: 13px;padding: 0px 5px;margin: 0;font-weight: 500;text-transform: uppercase;overflow-wrap: break-word;">{{ notificacao.titulo }}</p>
            <p style="font-size: 13px;padding: 0px 5px;margin: 0;">{{ notificacao.texto }}</p>
            <span class="notification-item-nao-lido-span">
              <span>
                <i class="bi bi-envelope"></i> {{notificacao.dataCriacao | date:'dd/MM/yyyy, HH:mm'}}
              </span>
            </span>
            <hr *ngIf="!last" class="hr-padrao" style=" padding: 0; margin: 10px 0px 0px; ">
          </div>
        </div>

        <!-- <span *ngIf="all_notificacoes.length > 0" class="notificacao-badge-content-title-lidas d-none">
          <span *ngIf="all_notificacoes.length > 0" class="start-100 badge rounded-pill background-cor-sucesso text-cor-branco">
            Lidas
          </span>
          <span *ngIf="all_notificacoes.length > 0" class="start-100 badge rounded-pill background-cor-sucesso text-cor-branco">
            {{all_notificacoes.length}}
          </span>
        </span>
        <div style="overflow: auto;max-height: 25vh; display: none;">

          <div class="notificacao-item-lido" *ngFor="let notificacao of all_notificacoes; let last = last">
            <p style="font-size: 13px;padding: 0px 5px;margin: 0;font-weight: 500;">{{ notificacao.titulo }}</p>
            <span class="notificacao-item-lido-span">
              <span>
                <i class="bi bi-envelope"></i> {{notificacao.dataCriacao | date:'dd/MM/yyyy, HH:mm'}}
              </span>
              <span>
                <i class="bi bi-envelope-open"></i> {{(notificacao.readDate == null ? '' : (notificacao.readDate | date:'dd/MM/yyyy, HH:mm') || '')}}
              </span>
            </span>
            <hr *ngIf="!last" class="hr-padrao" style=" padding: 0; margin: 10px 0px 0px; ">
          </div>
        </div> -->
      </div>

      <div *ngIf="notificacoes.length > 0" style=" width: 100%; display: flex; align-items: center; justify-content: center; margin-bottom: -8px; padding-top: 5px; font-size: 13px; border-top: 1px solid var(--border-cor); ">
        <!-- <a class="link_name" [routerLink]="['/notificacoes']" [href]="['/notificacoes']" style="color: var(--text-cor-padrao);text-decoration: none;">Ver tudo</a> -->
        <button type="button" class="btn btn-outline-secondary m-0 p-0" (click)="routerLink('notificacoes')"  style="display: flex;justify-content: space-around;padding: 2px !important;">
          Ver tudo
          <!-- <i class="bi bi-eye"></i> -->
        </button>
      </div>



      <!-- <div *ngIf="notificacoes.length == 0 && all_notificacoes.length == 0">
        Sem notificações
      </div> -->
      <div *ngIf="notificacoes.length == 0" (click)="routerLink('notificacoes')" style=" display: flex; justify-content: space-between; align-items: center; cursor: pointer; ">
        Sem notificações <i class="bi bi-box-arrow-up-right"></i>
      </div>
    </div>
  </div>
</div>

