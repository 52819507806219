<div class="pb-4">
  <div class="container text-center min-width p-0">

    <div class="row p-0 m-0">
      <div class="col-lg-12 col-md-12 col-sm-12 mb-2 text-start">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb" style="margin-bottom: 0.5rem;">
            <li class="breadcrumb-item active" aria-current="page"><i class="bi bi-grid position-relative"></i> Dashboard</li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row p-0 m-0">

      <div class="min-width col-12 pb-3" *ngIf="usr_nome != ''">
        <div class="row p-0 m-0 box-gen-card" style=" flex-direction: row; ">
          <div class="col-6 p-0 m-0" style="display: flex;align-items: center;flex-direction: column;">
            <p class="p-0 m-0 text-cor-padrao text-start w-100">
              Olá,
            </p>
            <h3 class="p-0 m-0 text-cor-padrao text-start w-100 text-truncate">
              {{usr_nome}}
            </h3>

          </div>

          <div class="col-6 p-0 m-0" *ngIf="icon_tempo" style="display: flex;flex-direction: row;flex-wrap: nowrap;align-items: center;position: relative;">

            <div style="display: flex;flex-direction: column;align-items: flex-end;width: 100%;justify-content: center;flex-wrap: wrap;">
              <div>

              </div>
              <div (mouseenter)="animate = true" (mouseleave)="animate = false" title="{{temp_name}} - {{temp_region}} - {{temp_c}} {{temp_condition}}" style="border: var(--border-padrao);border-radius: 10px;height: 50px;cursor: pointer;overflow: hidden;padding: 0px 10px 0px 10px;background: #00000005 !important;" (click)="getTempo(temp_name)">
                <p class="p-0 m-0 text-cor-padrao" style="font-size: 10px;text-align: center;">
                  {{temp_name}} - {{temp_region}} <!--- {{temp_condition}} -->
                </p>
                <h3 class="p-0 m-0 text-cor-padrao" style="font-size: 20px;text-align: start;">
                  {{temp_c}}
                </h3>
                <div class="imagem-animada" style="background-image: url('{{icon_tempo}}');" [ngClass]="animate ? 'active-animacao' : ''">

                </div>
              </div>
            </div>



          </div>
        </div>
      </div>

      <!-- Cards após Loading -->


      <!-- <div class="row p-0 m-0"> -->

        <div class="min-width col-12 pb-3 ">
          <div class="row p-0 m-0 box-gen-card" style="flex-direction: row;padding: 15px 10px !important;">

            <div class="col-12 mb-3">
              <p class="p-0 m-0 text-cor-padrao text-start w-100" style="font-weight: 500;display: flex;justify-content: space-between;align-items: center;">
                <span>
                  <i class="bi bi-collection-fill ag-mr-5"></i>
                  Cadastros <!--/ Recursos Disponíveis-->
                </span>
                <!-- *ngIf="cadAgendaTotal != 0 || cadServicoTotal != 0 || cadTipoTotal != 0" -->
                <button type="button" class="btn btn-outline-secondary" (click)="chartRecursos()" style="display: flex;justify-content: space-around;padding: 5px !important;">
                  Gráfico
                  <i class="bi bi-bar-chart-line-fill ag-ml-5"></i>
                </button>

              </p>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-6 mb-2">
              <div class="p-0 m-0 box-gen-card-min" style=" box-shadow: none; cursor: pointer;border-bottom: 3px solid #3a91f7 !important;background: #00000005 !important;" (click)="routerLink('agendas')">
                <span class="box-gen-card-min-info" innerHTML="<span class='roulette-number'>{{cadAgendaTotal}}</span>"></span>
                <span class="box-gen-card-min-title">Agendas</span>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-6 mb-2">
              <div class="p-0 m-0 box-gen-card-min" style=" box-shadow: none; cursor: pointer;border-bottom: 3px solid #4be398 !important;background: #00000005 !important;" (click)="routerLink('agendas/servicos')">
                <!-- <span class="box-gen-card-min-info">{{cadServicoTotal}}</span> -->
                <span class="box-gen-card-min-info" innerHTML="<span class='roulette-number'>{{cadServicoTotal}}</span>"></span>

                <span class="box-gen-card-min-title">Serviços</span>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-6 mb-2">
              <div class="p-0 m-0 box-gen-card-min" style=" box-shadow: none; cursor: pointer;border-bottom: 3px solid #f6ae33 !important;background: #00000005 !important;" (click)="routerLink('tipos')">
                <!-- <span class="box-gen-card-min-info">{{cadTipoTotal}}</span> -->
                <span class="box-gen-card-min-info" innerHTML="<span class='roulette-number'>{{cadTipoTotal}}</span>"></span>
                <span class="box-gen-card-min-title">Tipos</span>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-6 mb-2">
              <div class="p-0 m-0 box-gen-card-min" style=" box-shadow: none; cursor: pointer;border-bottom: 3px solid #f44263 !important;background: #00000005 !important;" (click)="routerLink('usuarios')">
                <!-- <span class="box-gen-card-min-info">{{cadUsuarioTotal}}</span> -->
                <span class="box-gen-card-min-info" innerHTML="<span class='roulette-number'>{{cadUsuarioTotal}}</span>"></span>
                <span class="box-gen-card-min-title">Usuários</span>
              </div>
            </div>

            <div class="col-12 mt-3 mb-2"  id="chartsContainer" #chartsContainer *ngIf="chartRecursos_" style="overflow: auto;">
              <apx-chart
                  [series]="chartOptions!.series"
                  [chart]="chartOptions!.chart"
                  [dataLabels]="chartOptions!.dataLabels"
                  [plotOptions]="chartOptions!.plotOptions"
                  [yaxis]="chartOptions!.yaxis!"
                  [fill]="chartOptions!.fill"
                  [stroke]="chartOptions!.stroke"
                  [tooltip]="chartOptions!.tooltip"
                  [xaxis]="chartOptions!.xaxis"
                  [title]="chartOptions!.title"
                ></apx-chart>
            </div>

          </div>
        </div>

      <!-- </div> -->

      <!-- <div class="row p-0 m-0"> -->

        <div class="min-width col-12 pb-3 ">
          <div class="row p-0 m-0 box-gen-card" style="flex-direction: row;padding: 15px 10px !important;">

            <div class="col-12 mb-3">
              <p class="p-0 m-0 text-cor-padrao text-start w-100" style="font-weight: 500;display: flex;justify-content: space-between;align-items: center;">
                <span>
                  <i class="bi bi-clock ag-mr-5"></i>
                  Agendamentos {{currentDateAgendamentos}}
                </span>

                <button type="button" class="btn btn-outline-secondary" (click)="chartAgendamento(true)" style="display: flex;justify-content: space-around;padding: 5px !important;">
                  Gráfico
                  <i class="bi bi-bar-chart-line-fill ag-ml-5"></i>
                </button>

              </p>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-6 mb-2">
              <div class="p-0 m-0 box-gen-card-min" style="box-shadow: none;cursor: pointer;border-bottom: 3px solid var(--cor-alerta) !important;background: #00000005 !important;min-height: 75px;position: relative;" (click)="routerLink('agendamentos')" >
                <!-- <span class="box-gen-card-min-info">{{cht_cliente_total}}</span> -->

                <span class="box-gen-card-min-info" innerHTML="<span class='roulette-number'>{{cht_cliente_total}}</span>"></span>
                <span class="box-gen-card-min-title">Confirmado Cliente</span>

              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-6 mb-2">
              <div class="p-0 m-0 box-gen-card-min" style="box-shadow: none;cursor: pointer;border-bottom: 3px solid var(--cor-info) !important;background: #00000005 !important;min-height: 75px;position: relative;" (click)="routerLink('agendamentos')">
                <!-- <span class="box-gen-card-min-info">{{cht_admin_total}}</span> -->

                <span class="box-gen-card-min-info" innerHTML="<span class='roulette-number'>{{cht_admin_total}}</span>"></span>
                <span class="box-gen-card-min-title">Confirmado Administrador</span>

              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-6 mb-2">
              <div class="p-0 m-0 box-gen-card-min" style="box-shadow: none;cursor: pointer;border-bottom: 3px solid var(--cor-sucesso) !important;background: #00000005 !important;min-height: 75px;" (click)="routerLink('agendamentos')">
                <!-- <span class="box-gen-card-min-info">{{cht_atendido_total}}</span> -->

                <span class="box-gen-card-min-info" innerHTML="<span class='roulette-number'>{{cht_atendido_total}}</span>"></span>
                <span class="box-gen-card-min-title">Atendido</span>

              </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6 col-6 mb-2">
              <div class="p-0 m-0 box-gen-card-min" style="box-shadow: none;cursor: pointer;border-bottom: 3px solid var(--cor-danger) !important;background: #00000005 !important;min-height: 75px;" (click)="routerLink('agendamentos')">
                <!-- <span class="box-gen-card-min-info">{{cht_natendido_total}}</span> -->

                <span class="box-gen-card-min-info" innerHTML="<span class='roulette-number'>{{cht_natendido_total}}</span>"></span>
                <span class="box-gen-card-min-title">Não Atendido</span>

              </div>
            </div>

            <div class="col-12 mt-3 mb-2" id="chartsAgendamento" #chartsAgendamento *ngIf="chartAgendamento_" style="overflow: auto;">
              <apx-chart
                [series]="chartOptionsAgendamento!.series!"
                [chart]="chartOptionsAgendamento!.chart!"
                [xaxis]="chartOptionsAgendamento!.xaxis!"
                [markers]="chartOptionsAgendamento!.markers!"
                [stroke]="chartOptionsAgendamento!.stroke!"
                [yaxis]="chartOptionsAgendamento!.yaxis!"
                [labels]="chartOptionsAgendamento!.labels!"
                [dataLabels]="chartOptionsAgendamento!.dataLabels!"
                [title]="chartOptionsAgendamento!.title!"
                [fill]="chartOptionsAgendamento!.fill!"
                [tooltip]="chartOptionsAgendamento!.tooltip!"
                [legend]="chartOptionsAgendamento!.legend!"
                [plotOptions]="chartOptionsAgendamento!.plotOptions!"
                [colors]="chartOptionsAgendamento!.colors!"
                [theme]="chartOptionsAgendamento!.theme!"
              ></apx-chart>
            </div>

          </div>
        </div>

        <!-- <div class="row p-0 m-0"> -->

          <div class="min-width col-12 pb-3 ">
            <div class="row p-0 m-0 box-gen-card" style="flex-direction: row;padding: 15px 10px !important;">

              <div class="col-12 mb-3">
                <p class="p-0 m-0 text-cor-padrao text-start w-100" style="font-weight: 500;display: flex;justify-content: space-between;align-items: center;">
                  <span>
                    <i class="bi bi-kanban-fill ag-mr-5"></i>
                    Checkin/Kanban {{currentDateAgendamentos}}
                  </span>

                  <button type="button" class="btn btn-outline-secondary" (click)="chartCheckin(true)" style="display: flex;justify-content: space-around;padding: 5px !important;">
                    Gráfico
                    <i class="bi bi-bar-chart-line-fill ag-ml-5"></i>
                  </button>

                </p>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-4 col-4 mb-2">
                <div class="p-0 m-0 box-gen-card-min" style="box-shadow: none;cursor: pointer;border-bottom: 3px solid var(--cor-alerta) !important;background: #00000005 !important;min-height: 75px;" (click)="routerLink('checkin/aguardando')" >

                  <span class="box-gen-card-min-info" innerHTML="<span class='roulette-number'>{{cht_checkin_aguardando_total}}</span>"></span>
                  <span class="box-gen-card-min-title">Aguardando</span>

                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-4 col-4 mb-2">
                <div class="p-0 m-0 box-gen-card-min" style="box-shadow: none;cursor: pointer;border-bottom: 3px solid var(--cor-info) !important;background: #00000005 !important;min-height: 75px;" (click)="routerLink('checkin/andamento')" >
                  <span class="box-gen-card-min-info" innerHTML="<span class='roulette-number'>{{cht_checkin_em_andamento_total}}</span>"></span>
                  <span class="box-gen-card-min-title">Em andamento</span>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-4 col-4 mb-2">
                <div class="p-0 m-0 box-gen-card-min" style="box-shadow: none;cursor: pointer;border-bottom: 3px solid var(--cor-sucesso) !important;background: #00000005 !important;min-height: 75px;" (click)="routerLink('checkin/realizados')" >
                  <span class="box-gen-card-min-info" innerHTML="<span class='roulette-number'>{{cht_checkin_realizado_total}}</span>"></span>
                  <span class="box-gen-card-min-title">Realizados</span>
                </div>
              </div>

              <div class="col-12 mt-3 mb-2" id="chartsCheckin" #chartsCheckin *ngIf="chartCheckin_" style="overflow: auto;">
                <apx-chart
                  [series]="chartOptionsCheckin!.series!"
                  [chart]="chartOptionsCheckin!.chart!"
                  [xaxis]="chartOptionsCheckin!.xaxis!"
                  [markers]="chartOptionsCheckin!.markers!"
                  [stroke]="chartOptionsCheckin!.stroke!"
                  [yaxis]="chartOptionsCheckin!.yaxis!"
                  [labels]="chartOptionsCheckin!.labels!"
                  [dataLabels]="chartOptionsCheckin!.dataLabels!"
                  [title]="chartOptionsCheckin!.title!"
                  [fill]="chartOptionsCheckin!.fill!"
                  [tooltip]="chartOptionsCheckin!.tooltip!"
                  [legend]="chartOptionsCheckin!.legend!"
                  [plotOptions]="chartOptionsCheckin!.plotOptions!"
                  [colors]="chartOptionsCheckin!.colors!"
                  [theme]="chartOptionsCheckin!.theme!"
                ></apx-chart>
              </div>

            </div>
          </div>

        <!-- </div> -->

        <div class="min-width col-lg-12 pb-5">
          <div class="p-0 m-0 box-gen-card" style="background: var(--linear-gradient-0deg);border: var(--border-padrao);border-radius: 5px;padding: 25px !important;box-shadow: var(--box-shadow_p);width: 100%;display: flex;overflow: auto;flex-direction: column;">

            <div class="calendar" >
              <full-calendar
                #fullcalendar
                [options]="calendarOptions"
                [deepChangeDetection]="true"
              ></full-calendar>
            </div>

            <!-- Botão para adicionar um novo evento -->
            <div style="display: none">
              <button (click)="getFeriados()">Feriados</button>
              <button (click)="removerFeriados()">Remover Feriados</button>
              <button (click)="addEvent()">Adicionar Evento</button>
            </div>

            <!-- Tabela ou formulário para exibir e editar eventos existentes -->
            <!-- <table>
              <tr *ngFor="let event of eventsList">
                <td>{{ event.title }} / {{event.description}}</td>
                <td>{{ event.date }}</td>
                <td>{{ event.start }}</td>
                <td>{{ event.end }}</td>
                <td><button (click)="editEvent(event)">Editar</button></td>
                <td><button (click)="deleteEvent(event)">Excluir</button></td>
              </tr>
            </table> -->

          </div>
        </div>

      <!-- </div> -->



      <!-- <div class="row p-0 m-0"> -->

        <!-- <div class="min-width col-lg-6 pb-3 d-none">
          <div class="p-0 m-0 box-gen-card" style="background: var(--linear-gradient-0deg);border: var(--border-padrao);border-radius: 15px;padding: 25px !important;box-shadow: var(--box-shadow_p);width: 100%;min-height: 345px;display: flex;overflow: auto;flex-direction: column;justify-content: center;align-items: center;">
            <canvas
            baseChart
            class="chart"
            [data]="lineChartData"
            [options]="lineChartOptions"
            [type]="lineChartType"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"
          ></canvas>
          </div>
        </div>

        <div class="min-width col-lg-6 pb-3 d-none">
          <div class="p-0 m-0 box-gen-card" style="background: var(--linear-gradient-0deg);border: var(--border-padrao);border-radius: 15px;padding: 25px !important;box-shadow: var(--box-shadow_p);width: 100%;min-height: 345px;display: flex;overflow: auto;flex-direction: column;justify-content: center;align-items: center;">
            <canvas
            baseChart
            class="chart"
            [data]="lineChartData"
            [options]="lineChartOptions"
            [type]="lineChartType"
            (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)"
          ></canvas>
          </div>
        </div> -->

      <!-- </div> -->

      <!-- <div class="row p-0 m-0"> -->



      <!-- </div> -->

      <!-- <div class="row p-0 m-0"> -->

        <div class="min-width col-lg-12 pb-5 d-none" *ngIf="usr_arr.length == 0">
          <div class="p-0 m-0 pulsate box-gen-card" style="min-height: 50vh; border: var(--border-padrao);border-radius: 15px;padding: 25px !important;box-shadow: var(--box-shadow_p);width: 100%;display: flex;overflow: auto;flex-direction: column;">
          </div>
        </div>


        <div class="min-width col-lg-12 pb-5 d-none" *ngIf="usr_arr.length > 0">
          <div class="p-0 m-0 box-gen-card" style="background: var(--linear-gradient-0deg);border: var(--border-padrao);border-radius: 15px;padding: 25px !important;box-shadow: var(--box-shadow_p);width: 100%;display: flex;overflow: auto;flex-direction: column;">

            <div *ngIf="usr_arr.length > 0" style="position: relative;">

              <div class="form-floating mb-3 w-100 ">
                <input type="text" class="form-control input-text-padrao m-0 search-box-result input-text-padrao" id="cpfInputSearch" [(ngModel)]="pesquisa" (keydown)="searchUsrReturn()" (keyup)="searchUsrReturn()" (keypress)="searchUsrReturn()" placeholder="&nbsp;">
                <label for="cpfInputSearch" class="label-input-text-padrao ag-pt-18 ag-pb-18 ag-pl-12 ag-pr-12">Pesquisar ...</label>
              </div>

              <i *ngIf="pesquisa != ''" class="bi bi-x-circle-fill text-secondary reset-search-usr" (click)="resetSearch()"></i>

            </div>

            <div *ngIf="usr_arr.length > 0" class="row mb-3 d-flex align-items-center text-cor-padrao p-0 m-0" style=" margin-bottom: 1rem !important; ">
              <div class="col-6 p-0 m-0">
                <select class="form-select form-select-sm select-box-result" (change)="pags($event.target)">
                  <option value="5" selected>5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
              </div>
              <div class="col-6 pagination-info-box-result">
                <span *ngIf="pesquisa == ''"> {{visualizando > usr_arr.length ? usr_arr.length : visualizando }} de {{usr_arr.length}} </span>
                <span *ngIf="pesquisa != ''"> {{pesquisaLength}} de {{usr_arr.length}}</span>
              </div>
            </div>

            <div class="w-100 overflow-auto">
              <table id="excel-table" class="table table-responsive table-hover table-sm" [ngClass]="{'table-dark': !lightMode,'table-light': lightMode}">
                <thead style=" height: 40px; vertical-align: middle; ">
                    <tr>
                      <th class="text-cor-padrao">&nbsp;</th>
                      <th class="text-cor-padrao">ID</th>
                      <th class="text-cor-padrao">Usuário</th>
                      <th class="text-cor-padrao">Nome</th>
                      <th class="text-cor-padrao">E-mail</th>
                      <th class="text-cor-padrao">Tipo</th>
                      <th class="text-cor-padrao">Criado</th>
                      <th class="text-cor-padrao">Atualizado</th>
                      <th class="text-cor-padrao">Editar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-cor-padrao ag-table-tr" *ngFor="let usr of usr_arr | searchUsuario: pesquisa | paginate: { itemsPerPage: contador, currentPage: pag }">
                      <td style="font-weight: 300;font-size: 15px;">
                        <div style=" display: flex; justify-content: center; ">
                          <div style="min-height: 30px; width: 30px;border-radius: 15px;border: none; background-color: var(--background);background-attachment: inherit; background-origin: initial; background-clip: inherit; background-position: center center; background-size: cover; background-repeat: no-repeat;" [ngStyle]="{'background-image': usr.Foto != '' ? 'url('+usr.Foto+')' : ''}">
                          </div>
                        </div>
                      </td>
                      <td style="font-weight: 300;font-size: 15px;">
                        <div >
                          {{usr.Id}}
                        </div>
                      </td>
                      <td style="font-weight: 300;font-size: 15px;">
                        <div >
                          {{usr.Usuario}}
                        </div>
                      </td>
                      <td style="font-weight: 300;font-size: 15px;">
                        <div >
                          {{usr.Nome}}
                        </div>
                      </td>
                      <td style="font-weight: 300;font-size: 15px;">
                        <div >
                          {{usr.Email}}
                        </div>
                      </td>
                      <td style="font-weight: 300;font-size: 15px;">
                        <div >
                          <span >{{usr.Tipo == 'A' ? 'Administrador' : usr.Tipo == 'M' ? 'Master' : usr.Tipo == 'S' ? 'Simples' : usr.Tipo == 'D' ? 'Desativado' : usr.Tipo }}</span>
                        </div>
                      </td>
                      <td style="font-weight: 300;font-size: 15px;">
                        <div >
                          {{usr.Criado}}
                        </div>
                      </td>
                      <td style="font-weight: 300;font-size: 15px;">
                        <div >
                          {{usr.Atualizado}}
                        </div>
                      </td>
                      <td style="font-weight: 300;font-size: 15px;">
                        <button type="button" class="btn btn-outline-primary" (click)="routerLink('usuario', usr.Id)" style="border: none !important;font-size: 16px !important;padding: 4px 4px !important;margin: 0 !important;">
                          <i class="bi bi-pencil-square"></i>
                        </button>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>

            <div class="text-right row pagination-box-result" *ngIf="usr_arr.length > 0">
              <div class="col-12 p-0 m-0">
                <pagination-controls class="info-pagination-box-result" #paginationControls (pageChange)="handlePageChange($event)" previousLabel="" nextLabel="" [maxSize]="maxSize"></pagination-controls>
              </div>
            </div>

          </div>
        </div>

      <!-- </div> -->

  </div>
</div>
