import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ModalDocaComponent } from 'src/app/element-library/modal-doca/modal-doca.component';

@Injectable({
  providedIn: 'root'
})
export class ModalDocaService {
  modalRef!: BsModalRef;
  private confirmationSubject = new Subject<boolean>();

  constructor(private modalService: BsModalService) {}


  openModal(titulo: string,mensagem: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const initialState = {
        titulo: titulo,
        mensagem: mensagem,
        confirmationSubject: this.confirmationSubject
      };
      this.modalRef = this.modalService.show(
        ModalDocaComponent,
        {
          initialState,
          ignoreBackdropClick: true,
          animated: false,
          class: 'modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md'
        }
      );

      this.verificarOverlay();

      this.confirmationSubject.subscribe((confirmation: boolean) => {
        resolve(confirmation);
      });

    });
  }

  confirm(mensagem: string) {
    return this.confirmationSubject.asObservable();
  }

  setConfirmation(value: boolean) {
    this.confirmationSubject.next(value);
  }

  verificarOverlay() {
    const observer = new MutationObserver((mutationsList) => {
      const existingBackdrop = document.querySelector('.modal-backdrop.show');

      if (!existingBackdrop) {
        // Cria um novo backdrop caso não exista
        const backdrop = document.createElement('div');
        backdrop.classList.add('modal-backdrop', 'show');
        document.body.appendChild(backdrop);

        // Para de observar quando o overlay é criado
        observer.disconnect();
      }
    });

    // Inicia a observação do body para alterações no DOM
    observer.observe(document.body, { childList: true, subtree: true });

    // Remove o backdrop ao fechar o modal
    this.modalRef.onHidden?.subscribe(() => {
      const backdropToRemove = document.querySelector('.modal-backdrop.show');
      if (backdropToRemove) {
        document.body.removeChild(backdropToRemove);
      }
    });
  }

}
