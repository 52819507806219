import { Component, Input, AfterViewInit, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import { Checkin } from 'src/class/checkin';
import { Doca } from 'src/class/doca';
import { StatusKanban } from 'src/enum/statusKanban.enum';
import { CheckinService } from 'src/services/component/checkin.service';
import { DocaService } from 'src/services/component/doca.service';
import { ModalDocaService } from 'src/services/modal-doca.service';

@Component({
  selector: 'app-modal-doca',
  templateUrl: './modal-doca.component.html',
  styleUrls: ['./modal-doca.component.css']
})
export class ModalDocaComponent implements OnInit, AfterViewInit {
  private destroy$: Subject<void> = new Subject<void>()

  @Input() titulo!: string;
  @Input() mensagem!: string;

  loading_id: boolean = true;
  loading: boolean = true;
  docas: Doca[] = [];
  docaSelecionado: any = null;
  statusCheckin = StatusKanban;
  checkins_em_andamento: Checkin[] = [];

  statusByDoca: { [key: string]: boolean } = {};

  constructor(
    public modalRef: BsModalRef,
    private modalDocaService: ModalDocaService,
    private docaService: DocaService,
    private checkinService: CheckinService
  ) {
    this.docaService.getAllDoca()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (docas: Doca[]) => {
        // console.log(docas)
        // Faça algo com as notificações atualizadas
        if(docas.length > 0){
          docas.sort((a, b) => {
            const nomeA = a.nome.valor.toUpperCase();
            const nomeB = b.nome.valor.toUpperCase();
            if (nomeA < nomeB) {
              return -1;
            }
            if (nomeA > nomeB) {
              return 1;
            }
            return 0;
          });
          this.docas = docas;
          this.checkinService.getStatusCheckin(this.statusCheckin.emAndamento)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            (checkins: Checkin[]) => {
              // console.log(checkins)
              if(checkins.length > 0){
                this.checkins_em_andamento = checkins;
                this.loading = false;
                for (let index = 0; index < this.docas.length; index++) {
                  const doca = this.docas[index];
                  const checkin = this.checkins_em_andamento.find((a: any) => a.doca && a.doca.IdDoca.trim() !== '' && a.doca.IdDoca === doca.id);

                  if (doca.id == this.docaSelecionado) {
                    this.docaSelecionado = null;
                  }

                  if(this.docaSelecionado && this.statusByDoca[this.docaSelecionado]){
                    this.docaSelecionado = null;
                  }

                  if (checkin) {
                    this.statusByDoca[doca.id] = !(checkin.status.valor === 'Em andamento' && checkin.statusEmAndamento.fim.trim() == '');
                    if(this.docaSelecionado && this.statusByDoca[this.docaSelecionado]){
                      this.docaSelecionado = null;
                    }
                  }else{
                    this.statusByDoca[doca.id] = true;
                  }
                }


                // console.log("statusByDoca", this.statusByDoca)
              }else{
                setTimeout(() => {
                  for (let index = 0; index < this.docas.length; index++) {
                    const doca = this.docas[index];
                    if (doca.id == this.docaSelecionado) {
                      this.docaSelecionado = null;
                    }
                  }
                  this.loading = false;
                  // console.log("statusByDoca", this.statusByDoca)
                }, 500);
              }
            },
            error => {
              // console.error('Erro ao listar os Agendamentos:', error);
            }
          );
        }
      },
      error => {
        // console.error('Erro ao listar as Agenda:', error);
      }
    );



  }

  ngOnInit(){
  }

  ngAfterViewInit() {
    // console.log('ngAfterViewInit');
    // Garante que o elemento esteja no DOM
    // console.log(document.querySelector('#honorato'));
    this.setId();
  }

  confirmAction(confirmed: boolean) {
    return new Promise<void>((resolve) => {
      this.modalRef.hide();
      this.modalDocaService.setConfirmation(confirmed); // Informa o resultado para o serviço
      // if (confirmed) {
      //   console.log('Ação confirmada!');
      // } else {
      //   console.log('Ação cancelada.');
      // }
      resolve();
    });
  }

  docaValor(val: any) {
    return new Promise<void>((resolve) => {
      this.modalRef.hide();
      this.modalDocaService.setConfirmation(val); // Informa o resultado para o serviço
      resolve();
    });
  }

  setId(){
    const observer = new MutationObserver((mutationsList) => {
      const modalElement = document.querySelector('.modal.show');
      if(modalElement){
        // Adicionar um ID único ao modal
        const modalId = `honorato`;
        // console.log("modalElement", modalElement)
        if (modalElement) {
          modalElement.setAttribute('id', modalId);
          setTimeout(() => {
            this.loading_id = false;
          }, 500);
        }
      }
    })

    // Inicia a observação do body para alterações no DOM
    observer.observe(document.body, { childList: true, subtree: true });

  }


}
